import React from 'react'
import AdminTemplate from '../template/AdminTemplate'
import Toolbar from '../components/Toolbar'
import { Link } from 'react-router-dom'
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import BarChartIcon from '@mui/icons-material/BarChart';
import EmailIcon from '@mui/icons-material/Email';
import AvTimerIcon from '@mui/icons-material/AvTimer';
import StyleIcon from '@mui/icons-material/Style';
import WidgetsIcon from '@mui/icons-material/Widgets'
import PersonAddAltRoundedIcon from '@mui/icons-material/PersonAddAltRounded'
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload'

const Configuracion = (props) => {
    const render = () => {
        return (
            <AdminTemplate >
                <Toolbar title="Configuración">
                </Toolbar>
                <div className="main">
                    <div className="layout-compact">
                        <div className="grid grid-cols-1 gap-8">
                            <Link to={{ pathname: "/mi-cuenta", state: { backUrl: "/configuracion" } }} className="no-underline flex items-center ">
                                <AccountCircleIcon fontSize="large" className="text-yellow mr-2" />
                                <span className="hover:text-yellow text-lg transition duration-700 ease-in-out">Mi Cuenta</span>
                            </Link>

                            <Link to={{ pathname: "/usuarios", state: { backUrl: "/configuracion" } }} className="no-underline flex items-center ">
                                <PersonAddAltRoundedIcon fontSize="large" className="text-yellow mr-2" />
                                <span className="hover:text-yellow text-lg transition duration-700 ease-in-out">Agregar Usuarios</span>
                            </Link>

                            <Link to={{ pathname: "/claves", state: { backUrl: "/configuracion" } }} className="no-underline flex items-center ">
                                <AssignmentIndIcon fontSize="large" className="text-yellow mr-2" />
                                <span className="hover:text-yellow text-lg transition duration-700 ease-in-out">Claves de Agente</span>
                            </Link>
                            <Link to={{ pathname: "/vencimientos", state: { backUrl: "/configuracion" } }} className="no-underline flex items-center ">
                                <EventAvailableIcon fontSize="large" className="text-yellow mr-2" />
                                <span className="hover:text-yellow text-lg transition duration-700 ease-in-out">Fechas de Vencimiento</span>
                            </Link>
                            <Link to={{ pathname: "/metas", state: { backUrl: "/configuracion" } }} className="no-underline flex items-center ">
                                <BarChartIcon fontSize="large" className="text-yellow mr-2" />
                                <span className="hover:text-yellow text-lg transition duration-700 ease-in-out">Metas</span>
                            </Link>
                            <Link to={{ pathname: "/modelos", state: { backUrl: "/configuracion" } }} className="no-underline flex items-center ">
                                <EmailIcon fontSize="large" className="text-yellow mr-2" />
                                <span className="hover:text-yellow text-lg transition duration-700 ease-in-out">Correos Electrónicos</span>
                            </Link>
                            <Link to={{ pathname: "/automatizaciones", state: { backUrl: "/configuracion" } }} className="no-underline flex items-center ">
                                <AvTimerIcon fontSize="large" className="text-yellow mr-2" />
                                <span className="hover:text-yellow text-lg transition duration-700 ease-in-out">Automatizaciones</span>
                            </Link>
                            <Link to={{ pathname: "/integraciones", state: { backUrl: "/configuracion" } }} className="no-underline flex items-center ">
                                <WidgetsIcon fontSize="large" className="text-yellow mr-2" />
                                <span className="hover:text-yellow text-lg transition duration-700 ease-in-out">Integraciones</span>
                            </Link>
                            <Link to={{ pathname: "/mi-plan", state: { backUrl: "/configuracion" } }} className="no-underline flex items-center ">
                                <StyleIcon fontSize="large" className="text-yellow mr-2" />
                                <span className="hover:text-yellow text-lg transition duration-700 ease-in-out">Plan Actual</span>
                            </Link>
                        </div>
                    </div>
                </div>
            </AdminTemplate>
        )
    }

    return render()
}

export default Configuracion
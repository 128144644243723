import React from 'react'
import { Link } from 'react-router-dom'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'


const AvisoProvacidad = (props) => {

    const render = () => {
        return (
            <div>
                <Navbar showBackButton={false} />
                <div className="mt-8 container m-auto grid grid-cols-1 lg:grid-cols-3 lg:mt-32">
                    <div className="lg:col-start-2 px-8 lg:px-0">
                        <p className='text-yellow font-bold pb-5'>Aviso de Privacidad</p>
                        <p className='text-gray-100 pb-5 text-justify'>Con fundamento en los artículos 15 y 16 de la Ley Federal de Protección de Datos Personales en Posesión de Particulares hacemos de su conocimiento que Dalala S.A. de C.V. es responsable de recabar sus datos personales, del uso que se le dé a los mismos y de su protección.</p>
                        <p className='text-gray-100 pb-5 text-justify'>Su información personal será utilizada para proveer los servicios que ha solicitado, almacenamiento y posterior gestión personal realizada por usted mismo. Para las finalidades antes mencionadas, requerimos obtener los siguientes datos personales: Razón social, Nombre completo, RFC, Fecha de nacimiento, Estado civil, Sexo, Teléfono, Correo electrónico, Facebook, Twitter, Linkedin, localización geográfica.</p>
                        <p className='text-gray-100 pb-5 text-justify'>Usted tiene derecho de acceder, rectificar y cancelar sus datos personales, así como de oponerse al tratamiento de los mismos o revocar el consentimiento que para tal fin nos haya otorgado, a través de la comunicación explícita al correo privacidad@dalala.mx . Los datos relativos a las pólizas y seguros contratados con los terceros, proveedores de esos servicios y colaboradores serán tratados directamente con ellos, por lo que su creación o modificación implica que usted se lo notifique mediante sus propios canales de comunicación.</p>
                        <p className='text-gray-100 pb-5 text-justify'>Para las finalidades señaladas en el presente aviso de privacidad, podemos recabar sus datos personales de distintas formas: cuando usted nos los proporciona directamente o cuando los obtenemos de usted de otras fuentes permitidas, bajo acuerdo tácito con los distintos terceros, proveedores de dichos servicios, que participan de manera activa en la generación de información.</p>
                        <p className='text-gray-100 pb-5 text-justify'>Nos reservamos el derecho de efectuar en cualquier momento modificaciones o actualizaciones al presente aviso de privacidad, para la atención de novedades legislativas o jurisprudenciales, políticas internas, nuevos requerimientos para la prestación u ofrecimiento de nuestros servicios o productos y prácticas de mercado. Estas modificaciones estarán disponibles al público a través de distintos medios: notificaciones de la propia aplicación si es necesario en el momento de una actualización, en la página web (dalala.app), o se las haremos llegar al último correo electrónico que nos haya proporcionado.</p>
                        <p className='text-gray-100 pb-5 text-justify'>La fecha de la última actualización al presente aviso de privacidad: 04/05/2022.</p>
                        <p className='text-gray-100 pb-5 text-justify'>Nos comprometemos a que los datos personales sensibles serán tratados bajo medidas de seguridad, siempre garantizando su confidencialidad.</p>
                        <p className='text-gray-100 pb-5 text-justify'>Al utilizar nuestras aplicaciones, usted se compromete como usuario a hacer uso lícito y dentro de las buenas costumbres y la moral, de todos los recursos proporcionados en ellas. No está permitido que los usuarios modifiquen, eliminen, agreguen o impidan el uso de servicios, imágenes o archivos de cualquier tipo, presentes en las páginas del sitio, ni que lo utilicen para fines ajenos a los intereses de Dalala S.A. de C.V.</p>
                        <p className='text-gray-100 pb-5 text-justify'>Con la aceptación del documento, consiente que sus datos personales sensibles sean tratados conforme a los <Link to={"/terminos-condiciones"}>términos y condiciones</Link> del presente aviso de privacidad.</p>
                    </div>
                </div>

                <Footer />
            </div>
        )
    }

    return render()

}

export default AvisoProvacidad
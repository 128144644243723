import React from 'react'
import Button from '@material-ui/core/Button';
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import IconButton from '@material-ui/core/IconButton';

const Navbar = (props) => {

    const onDefaultBack = () => {
        window.history.back();
    }

    const render = () => {

        const onBack = props.onBack || onDefaultBack
        const showBackButton = props.showBackButton === true ? true : false
        const title = props.title || ""

        return (
            <nav className="bg-dark-400 p-4 grid grid-cols-1 lg:grid-cols-3 rounded-b-3xl lg:rounded-none">
                <div className=" flex items-center">
                    <span className="absolute lg:hidden text-center left-0 right-0 text-gray-400  text-lg ml-4 ">{title}</span>
                    {showBackButton && <IconButton
                        onClick={onBack}
                        className="rounded-full  inline-block"
                        color="primary"
                    >
                        <ArrowBackIcon className="text-white " />
                    </IconButton>}
                    <span className="text-left hidden lg:inline-block text-gray-400 text-lg ml-4 ">{title}</span>
                </div>
                <div className="flex justify-center items-center text-center ">
                    <img src="/brand/dalala-logo-sm.svg" alt="dalala" className="inline-block lg:hidden mt-2 mb-5 " />
                    <img src="/brand/dalala-logo-lg.svg" alt="dalala" className="hidden lg:inline-block" />
                </div>
            </nav>
        )
    }

    return render()
}
export default Navbar
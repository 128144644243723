import { createTheme } from '@material-ui/core/styles';

// Create a theme instance.
const theme = createTheme({
    
    palette: {
        primary: {
            main: '#F4D144',
            contrastText: '#fff',
        },
        secondary: {
            main: '#c20083',
        },
        error: {
            main: "#FF2020",
        },
        background: {
            default: '#fff',
        },
    },
});

export default theme;
import { React, useEffect, useState } from 'react'
import APIInvoke from '../utils/APIInvoke'
import AdminTemplate from '../template/AdminTemplate'
import Pagination from '../components/Pagination'
import { Link } from 'react-router-dom'
import { Button, IconButton, InputAdornment, TextField } from '@material-ui/core'
import AddBoxIcon from '@material-ui/icons/AddBox';
import Toolbar from '../components/Toolbar'
import SearchIcon from '@mui/icons-material/Search';
import ConsultaCliente from './ConsultaCliente';
import DalalaDialog from '../components/DalalaDialog';
import { useDispatch } from 'react-redux'
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import Tooltip from '@mui/material/Tooltip';


const Clientes = (props) => {

    const [loading, setLoading] = useState(true)
    const dispatch = useDispatch()

    const [state, setState] = useState({
        clientes: [],
        totalItems: 0,
        currentPage: 1,
        totalPages: 0,
        pageSize: 0,
        nombre: ""
    })

    const [openDetalleDialog, setOpenDetalleDialog] = useState({ show: false, id: null })

    useEffect(async () => {
        setLoading(true)
        try {
            ///clientes?size=9&page=1&order=id desc, nombres asc
            const nombresParam = state.nombre.length >= 3 ? `&nombre=${state.nombre}` : ""
            const response = await (await APIInvoke.invokeGET(`/clientes?page=${state.currentPage}${nombresParam}&order=nombres asc`)).json()
            setState({
                ...state,
                clientes: response.body.items,
                totalItems: response.body.totalItems,
                currentPage: response.body.currentPage,
                totalPages: response.body.totalPages,
                pageSize: response.body.pageSize
            })
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }, [state.currentPage, state.nombre])


    const render = () => {
        return (
            <AdminTemplate loading={loading}>
                <DalalaDialog
                    title={"Detalle de Cliente"}
                    open={openDetalleDialog.show}
                    handleClose={() => setOpenDetalleDialog({ show: false, id: null })}
                >
                    <ConsultaCliente modal={true} clienteId={openDetalleDialog.id} onCompleted={() => setOpenDetalleDialog({ show: false, id: null })} />
                </DalalaDialog>
                <Toolbar title="Clientes">
                    <div className="layout">
                        <div className="flex flex-row gap-4">
                            <Link to={{ pathname: "/clientes/", state: { backUrl: "/clientes" } }} className="btn btn-yellow px-4 py-0" size="small" ><AddBoxIcon /> Añadir</Link>
                            <div className="flex flex-row flex-1 justify-end">
                                <TextField
                                    label="Buscar..."
                                    id="search"
                                    type='text'
                                    size="small"
                                    variant="outlined"
                                    onKeyPress={e => {
                                        if (e.key == "Enter") setState({ ...state, nombre: e.target.value, totalItems: 0, totalPages: 1, currentPage: 1 })
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment
                                                position="end"
                                                className="h-full"
                                                onClick={e => {
                                                    setState({ ...state, nombre: document.getElementById("search").value })
                                                }}
                                            >
                                                <IconButton>
                                                    <SearchIcon className="text-gray-400" />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </Toolbar>
                <div className="main">
                    <div className="layout">
                        <div className="grid grid-cols-1 gap-2 bg-dark-400 p-2 rounded-md">
                            {
                                state.clientes && state.clientes.map((cliente, i) => (
                                    <Link className="no-underline" key={i} to="#" onClick={e => setOpenDetalleDialog({ show: true, id: cliente.id })}>
                                        <article className="bg-dark-300 rounded-md p-2 lg:p-4 ">
                                            <div className="grid gap-2 lg:gap-4 grid-cols-1 lg:grid-cols-2">
                                                <p className="text-sm text-gray-400">{`${cliente.nombres} ${cliente.apPat} ${cliente.apMat}`}</p>
                                                <div className='relative'>
                                                    <span className="text-sm text-gray-400">RFC: {cliente.rfc}</span>
                                                    {cliente?.esRobot && (
                                                        <Tooltip title="Cliente creado automáticamente con la tecnología de sincronización dalala" disableInteractive>
                                                            <div className='absolute right-0 top-0'> <WarningRoundedIcon /></div>
                                                        </Tooltip>
                                                    )}
                                                </div>
                                            </div>
                                        </article>
                                    </Link>
                                ))
                            }

                            <div className="">
                                <Pagination totalItems={state.totalItems} pages={state.totalPages} page={state.currentPage} onPageChange={newPage => setState({ ...state, currentPage: newPage })} />
                            </div>

                            {state.clientes.length === 0 && (
                                <p className="text-yellow text-center mb-8">Sin resultados</p>
                            )}
                        </div>


                    </div>
                </div>
            </AdminTemplate>
        )
    }

    return render()

}
export default Clientes
import AddBoxIcon from '@material-ui/icons/AddBox';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import Button from '@material-ui/core/Button';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import HomeIcon from '@material-ui/icons/Home';
import { IconButton, InputLabel, MenuItem, Select } from '@mui/material';
import { Link } from 'react-router-dom';
import PersonIcon from '@material-ui/icons/Person';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import React, { useEffect, useState } from 'react'
import SearchIcon from '@mui/icons-material/Search';
import { TextField } from '@material-ui/core'
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import AdminTemplate from '../template/AdminTemplate';
import APIInvoke from '../utils/APIInvoke';
import { lastDayOfMonth, format } from '../utils/DateUtils'
import PolizaItem from '../components/PolizaItem';
import Pagination from '../components/Pagination';
import Toolbar from '../components/Toolbar'
import { onlySimpleCharacters } from '../utils/FormUtils';
import DalalaDialog from '../components/DalalaDialog';
import ConsultaPoliza from './ConsultaPoliza';
import { useDispatch } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';

const Polizas = (props) => {

    /* States */
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(true);

    const [state, setState] = useState({
        vigencia: false,
        anio: 'Todos',
        mes: new Date().getMonth() + 1,
        newType: [],
        isSearch: false,
        poliza: [],
        totalItems: 0,
        currentPage: 1,
        totalPages: 0,
        pageSize: 0,
    });

    const [tabs, setTabs] = useState({
        newValue: 0
    });

    const [radio, setRadio] = useState({
        newRadio: "fechaVigenciaDe"
    })

    const [type, setType] = useState({
        newType: ['Vida', 'Auto', 'Salud', 'Diversos']
    });

    const [filtersListado, setFiltersListado] = useState({
        field: "cliente",
        value: "",
        orderBy: "desc"
    })

    const fieldList = [
        { id: "cliente", value: "Cliente", type: "text", show: true },
        { id: "noPoliza", value: "Número de Póliza", type: "text", show: true },
        { id: "concepto", value: "Concepto", type: "text", show: true },
        { id: "abreviacion", value: "Aseguradora", type: "text", show: true }
    ]

    const [openPolizaDialog, setOpenPolizaDialog] = useState({ show: false, id: null })

    /* APInvoker */

    useEffect(async () => {
        try {
            setLoading(true)
            const field = fieldList.find(x => x.id === filtersListado.field)

            let query = ""
            let orderBy = ""
            if ((field.type === "text") && filtersListado.value.trim().length !== 0) {
                query = `&${filtersListado.field}=${filtersListado.value}`

                if (field.id === "cliente") {
                    orderBy = `&order=CONCAT(cliente.nombres, ' ', cliente.apPat, ' ', cliente.apMat) ${filtersListado.orderBy}`
                } else if (field.id === "noPoliza") {
                    orderBy = `&order=noPoliza ${filtersListado.orderBy}`
                }
            } else if (field.type === "date" || field.type === "numeric") {
                if (field.id === "vigencia") {
                    orderBy = `&order=fecVence ${filtersListado.orderBy}`
                } else if (field.id === "total") {
                    orderBy = `&order=total ${filtersListado.orderBy}`
                }
            }

            let fechaInicial = new Date();
            let fechaFinal = new Date();
            let dates = null;
            if (state.anio != "Todos") {
                if (tabs.newValue != 0) {
                    fechaInicial = new Date(state.anio, tabs.newValue - 1, 1);
                    fechaFinal = lastDayOfMonth(fechaInicial);
                } else {
                    fechaInicial = new Date(state.anio, 0, 1);
                    fechaFinal = new Date(state.anio, 11, 31);
                }
                dates = `${format(fechaInicial, "yyyy-MM-dd")},${format(fechaFinal, "yyyy-MM-dd")}`;
            }

            const order = radio.newRadio == 'fechaVigenciaDe' ? 'fechaVigenciaDe' : 'fechaVigenciaA';
            if (!state.isSearch) {
                let response = null;
                if (state.anio != "Todos") {
                    const tipos = type.newType.length > 0 ? `&tipo=${type.newType.join(',')}` : '';
                    response = await (await APIInvoke.invokeGET(`/polizas/listar?${order}(bw)=${dates}${tipos}${query}&page=${state.currentPage}&order=${order} ASC`)).json();
                } else {
                    const tipos = type.newType.length > 0 ? `tipo=${type.newType.join(',')}` : '';
                    response = await (await APIInvoke.invokeGET(`/polizas/listar?${tipos}${query}&page=${state.currentPage}&order=${order} ASC`)).json();
                }

                setState({
                    ...state,
                    poliza: response.body.items,
                    totalItems: response.body.totalItems,
                    currentPage: response.body.currentPage,
                    totalPages: response.body.totalPages,
                    pageSize: response.body.pageSize
                });
            }
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false)
        }

    }, [radio.newRadio, state.anio, state.mes, type.newType, state.currentPage])

    /* funciones/metodos */
    const handleChangeDate = (year) => {
        setState({
            ...state,
            anio: year
        })
    }
    const handleChangeRadio = (e, newRadio) => {
        let value = e.target.value
        setRadio({
            ...radio,
            newRadio: value
        });
    };
    const handleChangeTabs = (e, newValue) => {
        setTabs({
            ...tabs,
            newValue: newValue
        });
        setState({
            ...state,
            mes: newValue,
            isSearch: false,
        })
    };
    const handleChangeTipos = (e, newType) => {
        if (newType.length) {
            setType({
                ...type,
                newType: newType
            });
        }
    };

    const handlefiltersListado = (e) => {
        setFiltersListado({
            ...filtersListado,
            field: e.target.value,
            value: "",
            orderBy: "desc"
        });
    }

    const handlefiltersBuscar = (e) => {
        setFiltersListado({
            ...filtersListado,
            value: e.target.value
        });
    }

    const handlefilters = (e) => {
        setFiltersListado({
            ...filtersListado,
            orderBy: filtersListado.orderBy === "desc" ? "asc" : "desc"
        });
    }

    const anio = (year) => {
        const anio = parseInt(new Date().getFullYear())
        const max = anio + 1
        const min = anio - 10
        var list = ['Todos'];
        for (var year = max; year > min; year--) {
            list.push(year);
        }
        return list
    }

    const prefetchListado = async (e, orderList) => {
        filtersListado.value = "";
        setFiltersListado({
            ...filtersListado
        });
        fetchListado(e, orderList);
    }

    const fetchListado = async (e, orderList) => {
        try {
            setLoading(true)
            const field = fieldList.find(x => x.id === filtersListado.field)

            let query = ""
            let orderBy = ""
            if ((field.type === "text") /*&& filtersListado.value.trim().length !== 0*/) {
                query = `&${filtersListado.field}=${filtersListado.value}`
                switch (field.id) {
                    case "cliente":
                        orderBy = `&order=CONCAT(cliente.nombres,' ',cliente.apPat,' ', cliente.apMat) ${filtersListado.orderBy}`
                        break;
                    case "noPoliza":
                        orderBy = `&order=e.noPoliza ${filtersListado.orderBy}`
                        break;
                    case "concepto":
                        orderBy = `&order=e.noPoliza ${filtersListado.orderBy}`
                        break;
                    case "abreviacion":
                        orderBy = `&order=e.agenteClave.aseguradora.abreviacion ${filtersListado.orderBy}`
                        break;
                }
            } else if (field.type === "date" || field.type === "numeric") {
                if (field.id === "vigencia") {
                    orderBy = `&order=fecVence ${filtersListado.orderBy}`
                } else if (field.id === "total") {
                    orderBy = `&order=total ${filtersListado.orderBy}`
                }
            }

            let fechaInicial = new Date();
            let fechaFinal = new Date();

            let dates = null;
            if (state.anio != "Todos") {
                if (tabs.newValue != 0) {
                    fechaInicial = new Date(state.anio, tabs.newValue - 1, 1);
                    fechaFinal = lastDayOfMonth(fechaInicial);
                } else {
                    fechaInicial = new Date(state.anio, 0, 1);
                    fechaFinal = new Date(state.anio, 11, 31);
                }
                dates = `${format(fechaInicial, "yyyy-MM-dd")},${format(fechaFinal, "yyyy-MM-dd")}`;
            }
            const order = radio.newRadio == 'fechaVigenciaDe' ? 'fechaVigenciaDe' : 'fechaVigenciaA';
            let response = null;
            if (orderList == true) {
                const tipos = type.newType.length > 0 ? `tipo=${type.newType.join(',')}` : '';
                response = await (await APIInvoke.invokeGET(`/polizas/listar?${tipos}${query}&page=${state.currentPage}${orderBy}`)).json();
            } else {
                if (state.anio != "Todos") {
                    const tipos = type.newType.length > 0 ? `&tipo=${type.newType.join(',')}` : '';
                    response = await (await APIInvoke.invokeGET(`/polizas/listar?${order}(bw)=${dates}${tipos}${query}&page=${state.currentPage}&order=${order} ASC`)).json();
                } else {
                    const tipos = type.newType.length > 0 ? `tipo=${type.newType.join(',')}` : '';
                    response = await (await APIInvoke.invokeGET(`/polizas/listar?${tipos}${query}&page=${state.currentPage}&order=${order} ASC`)).json();
                }
            }
            setState({
                anio: state.anio,
                isSearch: true,
                poliza: response.body.items,
                totalItems: response.body.totalItems,
                currentPage: response.body.currentPage,
                totalPages: response.body.totalPages,
                pageSize: response.body.pageSize
            });
            if (orderList == true) {
                setFiltersListado({
                    ...filtersListado,
                    orderBy: filtersListado.orderBy === "desc" ? "asc" : "desc"
                });
            }
        } catch (e) {
            console.log(e)
        } finally {
            setLoading(false)
        }
    }

    const renderTypeField = () => {

        const field = fieldList.find(x => x.id === filtersListado.field)

        if (!field || field.show === false) return null

        if (field.type === "text") {
            return (
                <TextField
                    className="flex-1"
                    label="Buscar..."
                    type='text'
                    size="medium"
                    variant="outlined"
                    onInput={onlySimpleCharacters}
                    value={filtersListado.value}
                    onChange={handlefiltersBuscar}
                    InputProps={{
                        endAdornment: filtersListado.value && (
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={e => { prefetchListado(e, true) }}
                            ><CloseIcon /></IconButton>
                        )
                    }}
                />
            )
        } else if (field.type === "numeric") {
            return (
                <TextField
                    className="flex-1"
                    label="Buscar..."
                    type='number'
                    size="medium"
                    variant="outlined"
                    value={filtersListado.value}
                    onChange={handlefiltersBuscar}
                />
            )
        }
        return null;
    }

    const render = () => {
        return (
            <AdminTemplate loading={loading}>
                <DalalaDialog
                    title={"Detalle de Póliza"}
                    open={openPolizaDialog.show}
                    handleClose={() => setOpenPolizaDialog({ show: false, id: null })}
                >
                    <ConsultaPoliza modal={true} polizaId={openPolizaDialog.id} onCompleted={() => setOpenPolizaDialog({ show: false, id: null })} />
                </DalalaDialog>
                {state.poliza && (
                    <>
                        <Toolbar title="Polizas" >
                            <div className="layout py-4">
                                <div className="text-left">
                                    <Link className="no-underline" to={{ pathname: "/polizas/nuevo", state: { backUrl: "/polizas" } }} >
                                        <Button className="bg-yellow"><AddBoxIcon className="text-dark-600"></AddBoxIcon><p className="text-dark-600 text-base capitalize">Añadir</p></Button> </Link>
                                    <div className="grid grid-4 grid-cols-1 mt-8">

                                        <ToggleButtonGroup value={type.newType} onChange={handleChangeTipos} aria-label="device" className="flex justify-items-center">
                                            <ToggleButton value="Vida" aria-label="Vida" className="pill pill-border border mr-1 lg:mr-4 md:mr-4 sm:mr-4 xs:mr-2 rounded-md w-20">
                                                <div className="grid grid-cols-1 justify-items-center gap-1 text-center p-2">
                                                    <PersonIcon fontSize="large" />
                                                    <p className="text-inherit">Vida</p>
                                                </div>
                                            </ToggleButton>
                                            <ToggleButton value="Auto" aria-label="Autos" className="pill pill-border border mr-1 lg:mr-4 md:mr-4 sm:mr-4 rounded-md w-20">
                                                <div>
                                                    <DriveEtaIcon fontSize="large" />
                                                    Autos
                                                </div>
                                            </ToggleButton>
                                            <ToggleButton value="Salud" aria-label="Salud" className="pill pill-border border mr-1 lg:mr-4 md:mr-4 sm:mr-4 rounded-md w-20">
                                                <div>
                                                    <FavoriteIcon fontSize="large" />
                                                    Salud
                                                </div>
                                            </ToggleButton>
                                            <ToggleButton value="Diversos" aria-label="Daños" className="pill pill-border border mr-1 lg:mr-4 md:mr-4  sm:mr-4 rounded-md w-20">
                                                <div>
                                                    <HomeIcon fontSize="large" />
                                                    Daños
                                                </div>
                                            </ToggleButton>
                                        </ToggleButtonGroup>
                                    </div>
                                </div>
                            </div>
                        </Toolbar>

                        <div className="main">
                            <div className="layout mb-8">
                                <div className="grid grid-cols-2 gap-4 mb-4 items-center lg:items-end">
                                    <FormControl>
                                        <FormLabel className="text-sm">Ordenar por Vigencia:</FormLabel>
                                        <RadioGroup row aria-label="vigencia" name="row-radio-buttons-group" value={radio.newRadio} onChange={handleChangeRadio}>
                                            <FormControlLabel value="fechaVigenciaDe" control={<Radio />} className="text-sm" label="Inicial" />
                                            <FormControlLabel value="fechaVigenciaA" control={<Radio />} label=" Final" />
                                        </RadioGroup>
                                    </FormControl>

                                    <div className="grid grid-col-1 gap-2 align-items-end">
                                        <FormControl variant="outlined" size="large" >
                                            <InputLabel id="anio" required>Año</InputLabel>
                                            <Select labelId="anio" label="Año" value={state.anio} onChange={(e) => {
                                                setState({ ...state, anio: e.target.value, isSearch: false })
                                            }}>
                                                <MenuItem ></MenuItem>
                                                {anio().map(year => <MenuItem key={year} value={year}>{year}</MenuItem>)}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>

                                <div className="p-0">
                                    {/* <p className="text-justify mb-4 text-gray-400 text-sm">Seleccione un mes</p>
                                    <div className="grid gap-8 mb-4">
                                        <Tabs
                                            value={tabs.newValue}
                                            indicatorColor="primary"
                                            onChange={handleChangeTabs}
                                            variant="scrollable"
                                            scrollButtons="auto"
                                            className="text-yellow"
                                        >
                                            <Tab label="Todos" className="text-gray-400 text-sm rounded-3xl lg:mr-4 capitalize" />
                                            <Tab label="Enero" className="text-gray-400 text-sm rounded-3xl lg:mr-4 capitalize" />
                                            <Tab label="Febrero" className="text-gray-400 text-sm rounded-3xl lg:mr-4 capitalize" />
                                            <Tab label="Marzo" className="text-gray-400 text-sm rounded-3xl lg:mr-4 capitalize" />
                                            <Tab label="Abril" className="text-gray-400 text-sm rounded-3xl lg:mr-4 capitalize" />
                                            <Tab label="Mayo" className="text-gray-400 text-sm rounded-3xl lg:mr-4 capitalize" />
                                            <Tab label="Junio" className="text-gray-400 text-sm rounded-3xl lg:mr-4 capitalize" />
                                            <Tab label="Julio" className="text-gray-400 text-sm rounded-3xl lg:mr-4 capitalize" />
                                            <Tab label="Agosto" className="text-gray-400 text-sm rounded-3xl lg:mr-4 capitalize" />
                                            <Tab label="Septiembre" className="text-gray-400 text-sm rounded-3xl lg:mr-4 capitalize" />
                                            <Tab label="Octubre" className="text-gray-400 text-sm rounded-3xl lg:mr-4 capitalize" />
                                            <Tab label="Noviembre" className="text-gray-400 text-sm rounded-3xl lg:mr-4 capitalize" />
                                            <Tab label="Diciembre" className="text-gray-400 text-sm rounded-3xl lg:mr-4 capitalize" />
                                        </Tabs>
                                    </div> */}

                                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-4">
                                        <div className="flex gap-2">
                                            <FormControl variant="outlined" size="medium" required className="flex-1">
                                                <InputLabel id="field" required>Buscar por</InputLabel>
                                                <Select
                                                    onChange={e => handlefiltersListado(e)}
                                                    value={filtersListado.field}
                                                    labelId="field"
                                                    label="Buscar por"
                                                    size="medium"
                                                    name="field"
                                                    required
                                                >
                                                    {fieldList.map((item, index) => <MenuItem key={index} value={item.id}>{item.value}</MenuItem>)}
                                                </Select>
                                            </FormControl>
                                            <Button
                                                className=" p-0 m-0 border-2 border-yellow border-solid"
                                                onClick={e => fetchListado(e, true)}>
                                                {filtersListado.orderBy === "desc" ? <ArrowDownwardIcon className="text-yellow" /> : <ArrowUpwardIcon className="text-yellow" />}
                                            </Button>
                                        </div>


                                        <div className="flex gap-2">
                                            {renderTypeField()}
                                            <Button value={state.currentPage} className=" p-0 btn btn-yellow m-0" onClick={e => fetchListado(e, true)}>
                                                <SearchIcon className="text-dark-600 "></SearchIcon>
                                            </Button>
                                        </div>
                                    </div>


                                </div>

                                <div className="grid gap-2 lg:gap-4 grid-cols-1 bg-dark-400 p-2 lg:p-4 rounded-md">
                                    {
                                        state.poliza && state.poliza.map((data, i) => (
                                            <Link className="no-underline" key={i} to="#" onClick={e => setOpenPolizaDialog({ show: true, id: data.polizaId })}>
                                                <PolizaItem
                                                    contratante={data.contratante}
                                                    tipo={data.tipo}
                                                    noPoliza={data.noPoliza}
                                                    concepto={data.concepto}
                                                    esRobot={data.esRobot}
                                                    aseguradoraAbre={data.aseguradoraAbre}
                                                    total={data.total}
                                                    status={data.status}
                                                    finVigenciaA={data.finVigenciaA}
                                                    finVigenciaDe={data.finVigenciaDe}
                                                ></PolizaItem>
                                            </Link>
                                        ))
                                    }

                                    <div className="" >
                                        <Pagination
                                            totalItems={state.totalItems}
                                            pages={state.totalPages}
                                            page={state.currentPage}
                                            onPageChange={newPage => setState({
                                                ...state,
                                                currentPage: newPage,
                                                isSearch: false
                                            })} />
                                    </div>
                                </div>


                            </div>
                        </div>
                    </>
                )}
            </AdminTemplate >
        )
    }

    return render()
}

export default Polizas
import { DateTime } from 'luxon'
import React, { useEffect, useState } from 'react'
import Toolbar from '../components/Toolbar'
import AdminTemplate from '../template/AdminTemplate'
import CakeIcon from '@mui/icons-material/Cake';
import { Link } from 'react-router-dom';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import PaidIcon from '@mui/icons-material/Paid';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import esLocale from 'date-fns/locale/es';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText';
import APIInvoke from '../utils/APIInvoke';
import DalalaDialog from '../components/DalalaDialog';
import ConsultaPoliza from './ConsultaPoliza';
import { Button } from '@material-ui/core';
import ConsultaCliente from './ConsultaCliente';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import LensBlurIcon from '@mui/icons-material/LensBlur';

const Calendar = () => {

    const [loading, setLoading] = useState(false)
    const [date, setDate] = useState(new Date())
    const [compact, setCompact] = useState(false)
    const [events, setEvents] = useState([])
    const [type, setType] = useState("all")

    const [openPolizaDialog, setOpenPolizaDialog] = useState({ show: false, id: null })
    const [openClienteDialog, setOpenClienteDialog] = useState({ show: false, id: null })

    useEffect(async () => {
        try {
            setLoading(true)
            const dateTime = DateTime.fromJSDate(date)
            const start = DateTime.local(dateTime.year, dateTime.month, 1, 0, 0)
            const end = start.set({ day: start.daysInMonth })
            const response = await (await APIInvoke.invokeGET(`/calendario?inicio=${start.toFormat("yyyy-MM-dd")}&fin=${end.toFormat("yyyy-MM-dd")}&type=${type.toUpperCase()}`)).json()

            const eventos = response.body.eventos.map(x => {
                return {
                    ...x,
                    text: x.message,
                    dateString: x.date,
                    date: DateTime.fromFormat(x.date, "yyyy-MM-dd").set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
                }
            })
            setEvents(eventos)
        } catch (error) {
            console.error("error al consultar el calendario", error)
        } finally {
            setLoading(false)
        }
    }, [date.getTime(), type])

    const getCalendar = () => {
        const dateTime = DateTime.fromJSDate(date)
        let currYear = dateTime.year
        let currMonth = dateTime.month
        let currDay = 1
        const startDay = DateTime.local(currYear, currMonth, currDay, 0, 0)
        const daysInMonth = startDay.daysInMonth
        const weekday = startDay.weekday //start in 1
        const today = DateTime.now().set({ hour: 0, minute: 0, second: 0, millisecond: 0 })


        const calendar = []

        if (weekday !== 1) {
            for (let c = 0; c < weekday - 1; c++) {
                calendar.push({
                    thisMonth: false,
                    dateString: "---",
                    events: []
                })
            }
        }

        for (let c = 1; c <= daysInMonth; c++) {
            const date = DateTime.local(currYear, currMonth, c, 0, 0)
            calendar.push({
                thisMonth: true,
                date: date,
                dateString: date.toFormat("dd/MM/yyyy"),
                today: date.toMillis() === today.toMillis(),
                events: events.filter(x => {
                    return x.date.toMillis() === date.toMillis()
                })
            })
        }


        const endDay = DateTime.local(currYear, currMonth, daysInMonth, 0, 0)

        if (endDay.weekday !== 7) {
            for (let c = 0; c <= 7 - endDay.weekday - 1; c++) {
                calendar.push({
                    type: "none",
                    dateString: "---",
                    events: []
                })
            }
        }

        if (compact) {
            return calendar.filter(x => x.events.length > 0)
        } else {
            return calendar
        }

    }

    const renderEvent = (event, id) => {
        let line = null
        if (event.type === "birthday") {
            line = (
                <Button className="flex items-center gap-2 text-xs p-0" onClick={e => setOpenClienteDialog({ show: true, id: event.clienteId })}>
                    <CakeIcon fontSize="small" />
                    <span className="font-normal text-left">{event.text}</span>
                </Button>
            )
        } else if (event.type === "renewal") {
            line = <Button className="flex items-center gap-2 text-xs  p-0" onClick={e => setOpenPolizaDialog({ show: true, id: event.polizaId })}>
                <AutorenewIcon fontSize="small" />
                <span className="font-normal text-left">{event.text}</span>
            </Button>
        } else if (event.type === "collection") {
            line = (<Button className="flex items-center gap-2 text-xs p-0" onClick={e => setOpenPolizaDialog({ show: true, id: event.polizaId })}>
                <PaidIcon fontSize="small" />
                <span className="font-normal text-left">{event.text}</span>
            </Button>)
        }
        return (
            <div key={id} className="m-2 p-1 bg-dark-100 rounded-md text-xs">{line}</div>
        );
    }

    const render = () => {
        const calendar = getCalendar();
        return (
            <AdminTemplate loading={loading}>
                <DalalaDialog
                    title={"Detalle de Póliza"}
                    open={openPolizaDialog.show}
                    handleClose={() => setOpenPolizaDialog({ show: false, id: null })}
                >
                    <ConsultaPoliza modal={true} polizaId={openPolizaDialog.id} onCompleted={() => setOpenPolizaDialog({ show: false, id: null })} />
                </DalalaDialog>
                <DalalaDialog
                    title={"Detalle de Cliente"}
                    open={openClienteDialog.show}
                    handleClose={() => setOpenClienteDialog({ show: false, id: null })}
                >
                    <ConsultaCliente modal={true} clienteId={openClienteDialog.id} onCompleted={() => setOpenClienteDialog({ show: false, id: null })} />
                </DalalaDialog>
                <Toolbar title="Calendario" />
                <div className="main">
                    <div className="grid grid-cols-1 gap-8 m-4 lg:m-8">
                        <p>Calendario de tareas</p>
                        <div className="grid grid-cols-1 gap-4 lg:gap-8">
                            <div className="grid grid-cols-1 lg:flex gap-8">
                                <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
                                    <DatePicker
                                        views={['year', 'month']}
                                        label="Mes"
                                        minDate={DateTime.now().plus({ year: -2 }).toJSDate()}
                                        maxDate={DateTime.now().plus({ year: 2 }).toJSDate()}
                                        value={date}
                                        onChange={(newValue) => {
                                            setDate(newValue);
                                        }}
                                        renderInput={(params) => <TextField {...params} helperText={null} />}
                                    />
                                </LocalizationProvider>

                                <FormControl className="inline-block lg:hidden">
                                    <FormControlLabel
                                        control={
                                            <Checkbox checked={compact} onChange={e => setCompact(e.target.checked)} name="jason" />
                                        }
                                        label="Modo compacto"
                                    />
                                    <FormHelperText>Ver solo fechas con eventos</FormHelperText>
                                </FormControl>
                            </div>
                            <div className="grid grid-cols-1 gap-4">
                                <p>Filtrar por:</p>
                                <ToggleButtonGroup
                                    value={type}
                                    exclusive
                                    onChange={(event, newValue) => setType(newValue)}
                                    aria-label="text alignment"
                                >
                                    <ToggleButton className="pill pill-border rounded-none rounded-l-md p-2 pl-4 mr-1" value="all" aria-label="left aligned">
                                        <p className={type === "all" ? "text-yellow" : ""}><LensBlurIcon className="text-inherit" /> Todos</p>
                                    </ToggleButton>
                                    <ToggleButton className="pill pill-border rounded-none p-2 mr-1" value="birthday" aria-label="left aligned">
                                        <p className={type === "birthday" ? "text-yellow" : ""}><CakeIcon className="text-inherit" /> Cumpleaños</p>
                                    </ToggleButton>
                                    <ToggleButton className="pill pill-border rounded-none p-2 mr-1" value="renewal" aria-label="centered">
                                        <p className={type === "renewal" ? "text-yellow" : ""}><AutorenewIcon className="text-inherit" /> Renovaciones</p>
                                    </ToggleButton>
                                    <ToggleButton className="pill pill-border rounded-r-md p-2 pr-4" value="collection" aria-label="right aligned">
                                        <p className={type === "collection" ? "text-yellow" : ""}><PaidIcon className="text-inherit" /> Cobranza</p>
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </div>
                        </div>

                        <div className="grid grid-cols-1 gap-2 bg-dark-400 p-2 lg:p-4 rounded-md">
                            <div className="hidden lg:grid grid-cols-1 lg:grid-cols-7 gap-2 ">
                                <div className="p-2 bg-dark-300 rounded-md">
                                    <p className="text-center">Lunes</p>
                                </div>
                                <div className="p-2 bg-dark-300 rounded-md">
                                    <p className="text-center">Martes</p>
                                </div>
                                <div className="p-2 bg-dark-300 rounded-md">
                                    <p className="text-center">Míercoles</p>
                                </div>
                                <div className="p-2 bg-dark-300 rounded-md">
                                    <p className="text-center">Júeves</p>
                                </div>
                                <div className="p-2 bg-dark-300 rounded-md">
                                    <p className="text-center">Viernes</p>
                                </div>
                                <div className="p-2 bg-dark-300 rounded-md">
                                    <p className="text-center">Sábado</p>
                                </div>
                                <div className="p-2 bg-dark-300 rounded-md">
                                    <p className="text-center">Domingo</p>
                                </div>
                            </div>
                            <div className="grid grid-cols-1 lg:grid-cols-7 gap-2">
                                {calendar.map((day, index) => {
                                    const border = day.today ? "border-yellow bg-yellow-600" : "border-dark-100"
                                    const style = day.thisMonth ? `bg-dark-300 rounded-md border ${border}` : `bg-dark-500 rounded-md border ${border}`

                                    return (
                                        <div key={index} className={style}>
                                            <div className="bg-dark-400 p-2 rounded-md">
                                                <p className="text-gray-400 text-sm text-center">{day.dateString}</p>
                                            </div>
                                            <div className="" style={{ minHeight: "64px" }}>
                                                {day.events.map((event, index) => renderEvent(event, index))}
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>


                        <div className="flex flex-col lg:flex-row gap-4 justify-center">
                            <p className="text-center lg:text-left"><CakeIcon className="text-yellow" fontSize="small" /> Cumpleaños</p>
                            <p className="text-center lg:text-left"><AutorenewIcon className="text-yellow" fontSize="small" /> Renovaciones</p>
                            <p className="text-center lg:text-left"><PaidIcon className="text-yellow" fontSize="small" /> Cobranza</p>
                        </div>
                    </div>
                </div>

            </AdminTemplate>
        )
    }

    return render()
}

export default Calendar
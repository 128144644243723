import React, { useState } from 'react'
import AdminTemplate from '../template/AdminTemplate';
import Toolbar from '../components/Toolbar'
import NumberFormat from 'react-number-format';
import { Button, TextField } from '@material-ui/core';
import { onlyNumbers } from "../utils/FormUtils";
import APIInvoke from '../utils/APIInvoke';
import Alert from '../components/Alert'
import { useEffect } from 'react';


const Vencimientos = (props) => {

    const [loading, setLoading] = useState(false)

    const [state, setState] = useState({
        vidaPrimero: 0,
        vidaSegundo: 0,
        autosPrimero: 0,
        autosSegundo: 0,
        saludPrimero: 0,
        saludSegundo: 0,
        danosPrimero: 0,
        danosSegundo: 0
    })

    const [message, setMessage] = useState(null)

    useEffect(async () => {
        try {
            setLoading(true)
            const response = await (await APIInvoke.invokeGET(`/parametros`)).json()

            setState({
                vidaPrimero: response.body.find(x => x.parametroId === 5)?.valor || 0,
                vidaSegundo: response.body.find(x => x.parametroId === 6)?.valor || 0,
                autosPrimero: response.body.find(x => x.parametroId === 1)?.valor || 0,
                autosSegundo: response.body.find(x => x.parametroId === 2)?.valor || 0,
                saludPrimero: response.body.find(x => x.parametroId === 3)?.valor || 0,
                saludSegundo: response.body.find(x => x.parametroId === 4)?.valor || 0,
                danosPrimero: response.body.find(x => x.parametroId === 7)?.valor || 0,
                danosSegundo: response.body.find(x => x.parametroId === 8)?.valor || 0,
            })

        } catch (e) {
            console.error(e)
        } finally {
            setLoading(false)
        }
    }, [])


    const onChange = (e) => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        })
    }

    const onSubmit = async (e) => {
        e.preventDefault();

        try {
            setLoading(true)
            const request = [
                {
                    "parametroId": 1,
                    "tipoValor": "entero",
                    "valor": `${state.autosPrimero}`
                },
                {
                    "parametroId": 2,
                    "tipoValor": "entero",
                    "valor": `${state.autosSegundo}`
                },
                {
                    "parametroId": 3,
                    "tipoValor": "entero",
                    "valor": `${state.saludPrimero}`
                },
                {
                    "parametroId": 4,
                    "tipoValor": "entero",
                    "valor": `${state.saludSegundo}`
                },
                {
                    "parametroId": 5,
                    "tipoValor": "entero",
                    "valor": `${state.vidaPrimero}`
                },
                {
                    "parametroId": 6,
                    "tipoValor": "entero",
                    "valor": `${state.vidaSegundo}`
                },
                {
                    "parametroId": 7,
                    "tipoValor": "entero",
                    "valor": `${state.danosPrimero}`
                },
                {
                    "parametroId": 8,
                    "tipoValor": "entero",
                    "valor": `${state.danosSegundo}`
                }
            ]
            const response = await (await APIInvoke.invokePOST(`/parametros`, request)).json()

            if (response.ok) {
                setMessage({
                    message: response.message,
                    type: "success"
                })
            } else {
                setMessage({
                    message: response.message,
                    type: "error"
                })
            }
        } catch (e) {
            console.error(e)
        } finally {
            setLoading(false)
        }
    }

    const render = () => {
        return (
            <AdminTemplate loading={loading} >
                <Toolbar title="Fechas de Vencimiento" backUrl="/configuracion" helpLink="https://appdalala.blogspot.com/2022/05/configuracion-de-vencimientos.html">
                </Toolbar>

                <div className="main">
                    <div className="layout-compact">
                        <form onSubmit={onSubmit}>
                            <div className="grid grid-cols-1 gap-4">
                                <p className="text-base mb-4 text-center">Captura los días que serán sumados a la fecha inicial de vigencia de los recibos para calcular la fecha de vencimiento</p>

                                <p className="text-xl">VIDA</p>
                                <div className="grid grid-cols-2 gap-4 mb-2">
                                    <NumberFormat
                                        name="vidaPrimero"
                                        value={state.vidaPrimero}
                                        onChange={onChange}
                                        customInput={TextField}
                                        onFocus={e => e.target.select()}
                                        label="Primer Recibo"
                                        variant="outlined"
                                        size="medium"
                                        inputProps={{ style: { textAlign: 'right' }, maxLength: 4 }} // the change is here
                                        type="text"
                                        decimalScale={0}
                                        allowNegative={true}
                                        isNumericString={true}
                                        required
                                    />


                                    <NumberFormat
                                        name="vidaSegundo"
                                        value={state.vidaSegundo}
                                        onChange={onChange}
                                        customInput={TextField}
                                        onFocus={e => e.target.select()}
                                        label="Recibo Subsecuente"
                                        variant="outlined"
                                        size="medium"
                                        inputProps={{ style: { textAlign: 'right' }, maxLength: 4 }} // the change is here
                                        type="text"
                                        decimalScale={0}
                                        allowNegative={true}
                                        isNumericString={true}
                                        required
                                    />
                                </div>


                                <p className="text-xl">AUTOS</p>
                                <div className="grid grid-cols-2 gap-4  mb-2">

                                    <NumberFormat
                                        name="autosPrimero"
                                        value={state.autosPrimero}
                                        onChange={onChange}
                                        customInput={TextField}
                                        onFocus={e => e.target.select()}
                                        label="Primer Recibo"
                                        variant="outlined"
                                        size="medium"
                                        inputProps={{ style: { textAlign: 'right' }, maxLength: 4 }} // the change is here
                                        type="text"
                                        decimalScale={0}
                                        allowNegative={true}
                                        isNumericString={true}
                                        required
                                    />


                                    <NumberFormat
                                        name="autosSegundo"
                                        value={state.autosSegundo}
                                        onChange={onChange}
                                        customInput={TextField}
                                        onFocus={e => e.target.select()}
                                        label="Recibo Subsecuente"
                                        variant="outlined"
                                        size="medium"
                                        inputProps={{ style: { textAlign: 'right' }, maxLength: 4 }} // the change is here
                                        type="text"
                                        decimalScale={0}
                                        allowNegative={true}
                                        isNumericString={true}
                                        required
                                    />
                                </div>

                                <p className="text-xl">SALUD</p>
                                <div className="grid grid-cols-2 gap-4  mb-2">
                                    <NumberFormat
                                        name="saludPrimero"
                                        value={state.saludPrimero}
                                        onChange={onChange}
                                        customInput={TextField}
                                        onFocus={e => e.target.select()}
                                        label="Primer Recibo"
                                        variant="outlined"
                                        size="medium"
                                        inputProps={{ style: { textAlign: 'right' }, maxLength: 4 }} // the change is here
                                        type="text"
                                        decimalScale={0}
                                        allowNegative={true}
                                        isNumericString={true}
                                        required
                                    />
                                    <NumberFormat
                                        name="saludSegundo"
                                        value={state.saludSegundo}
                                        onChange={onChange}
                                        customInput={TextField}
                                        onFocus={e => e.target.select()}
                                        label="Recibo Subsecuente"
                                        variant="outlined"
                                        size="medium"
                                        inputProps={{ style: { textAlign: 'right' }, maxLength: 4 }} // the change is here
                                        type="text"
                                        decimalScale={0}
                                        allowNegative={true}
                                        isNumericString={true}
                                        required
                                    />
                                </div>

                                <p className="text-xl">DAÑOS</p>
                                <div className="grid grid-cols-2 gap-4  mb-8">
                                <NumberFormat
                                        name="danosPrimero"
                                        value={state.danosPrimero}
                                        onChange={onChange}
                                        customInput={TextField}
                                        onFocus={e => e.target.select()}
                                        label="Primer Recibo"
                                        variant="outlined"
                                        size="medium"
                                        inputProps={{ style: { textAlign: 'right' }, maxLength: 4 }} // the change is here
                                        type="text"
                                        decimalScale={0}
                                        allowNegative={true}
                                        isNumericString={true}
                                        required
                                    />
                                    <NumberFormat
                                        name="danosSegundo"
                                        value={state.danosSegundo}
                                        onChange={onChange}
                                        customInput={TextField}
                                        onFocus={e => e.target.select()}
                                        label="Recibo Subsecuente"
                                        variant="outlined"
                                        size="medium"
                                        inputProps={{ style: { textAlign: 'right' }, maxLength: 4 }} // the change is here
                                        type="text"
                                        decimalScale={0}
                                        allowNegative={true}
                                        isNumericString={true}
                                        required
                                    />
                                </div>

                                <div className="grid grid-cols-1 gap-8">
                                    <Button type="submit" className="btn btn-yellow">Guardar Configuración</Button>

                                    {message && <Alert type={message.type} message={message.message} />}
                                </div>

                            </div>
                        </form>
                    </div>
                </div>
            </AdminTemplate>
        )
    }

    return render();

}

export default Vencimientos
import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@material-ui/core'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import AdminTemplate from '../template/AdminTemplate'
import APIInvoke from '../utils/APIInvoke'
import Toolbar from '../components/Toolbar'
import { useParams, useHistory } from "react-router-dom";
import Alert from '../components/Alert'


const ReactivarPoliza = (props) => {

    const params = useParams()
    const polizaId = props.polizaId || params.polizaId
    const [loading, setLoading] = useState(false)
    const [state, setState] = useState({
        razones: [],
        poliza: null,
        razon: "",
        observaciones: ""
    })
    const [message, setMessage] = useState(null)

    useEffect(async () => {
        try {
            setLoading(true)
            const razonesResponse = await (await (APIInvoke.invokeGET(`/razones?modelo=0&tipo=0`))).json()
            const razonesPoliza = await (await (APIInvoke.invokeGET(`/polizas/${polizaId}`))).json()
            setState({
                ...state,
                razones: razonesResponse.body,
                poliza: razonesPoliza.body
            })
        } catch (e) {
            console.error(e)
        } finally {
            setLoading(false)
        }
    }, [])

    const onChange = (e) => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        })
    }

    const onSubmit = async (e) => {
        e.preventDefault()

        try {
            setLoading(true)

            const request = {
                "razonId": state.razon,
                "texto": state.observaciones
            }

            const response = await (await (APIInvoke.invokePUT(`/polizas/${polizaId}/reactivar`, request))).json()

            if (response.ok) {
                setMessage({
                    message: response.message,
                    type: "success"
                })

                if (props.onCompleted) {
                    props.onCompleted(response.body)
                }
            } else {
                setMessage({
                    message: response.message,
                    type: "error"
                })
            }

        } catch (e) {
            console.error(e)
        } finally {
            setLoading(false)
        }

    }

    const render = () => {
        const poliza = state.poliza
        const cliente = state.poliza?.cliente
        return (
            <AdminTemplate loading={loading} modal={props.modal}>
                <Toolbar title="Reactivar Póliza" modal={props.modal} />
                <div className="main">
                    <div className="layout-compact">
                        <form onSubmit={onSubmit}>
                            <div className="grid grid-cols-1 gap-4">
                                <h1 className="text-white text-2xl">Reactivar Póliza</h1>
                                <div>
                                    <p className="text-yellow font-bold">No. Poliza</p>
                                    <p >{poliza?.noPoliza}</p>
                                </div>
                                <div>
                                    <p className="text-yellow font-bold">Concepto</p>
                                    <p >{poliza?.concepto}</p>
                                </div>
                                <div className="mb-4">
                                    <p className="text-yellow font-bold">Contratante</p>
                                    <p >{`${cliente?.nombres} ${cliente?.apPat} ${cliente?.apMat}`}</p>
                                </div>

                                <FormControl variant="outlined" size="medium" required>
                                    <InputLabel id="razon" required>Razón de Reapertura</InputLabel>
                                    <Select
                                        required
                                        labelId="razon"
                                        label="Razón de Cierre"
                                        size="medium"
                                        name="razon"
                                        value={state.razon}
                                        onChange={onChange}
                                    >
                                        <MenuItem value="" ><em>Seleccione</em></MenuItem>
                                        {state.razones.map(razon => <MenuItem key={razon.id} value={razon.id} >{razon.nombre}</MenuItem>)}
                                    </Select>
                                </FormControl>

                                <TextField
                                    inputProps={{ className: "text-white" }}
                                    className="w-full"
                                    color="primary"
                                    label="Comentarios"
                                    name="observaciones"
                                    value={state.observaciones}
                                    onChange={onChange}
                                    multiline
                                    rows={4}
                                    required
                                />
                                <Button type="submit" className="btn btn-yellow">REACTIVAR PÓLIZA</Button>
                                <Alert className="" {...message} show={message} />
                            </div>
                        </form>
                    </div>
                </div>
            </AdminTemplate>
        )
    }

    return render()
}

export default ReactivarPoliza
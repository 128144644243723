import { createStore, applyMiddleware } from "redux";
import { combineReducers } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { createLogger } from "redux-logger";

// Reducers
import poliza from "./reducers/poliza-reducer";
import cxc from "./reducers/cxc-reducer";
import claves from "./reducers/claves-reducer";

const middleware = [];
if (process.env.NODE_ENV !== "production") {
  middleware.push(createLogger());
}

const pages = combineReducers({
  poliza,
  cxc,
  claves,
});

const reducers = combineReducers({
  pages,
});

const store = createStore(
  reducers,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;

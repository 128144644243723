import React from 'react'
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

const Loading = ({ show }) => {

    return show ?
        <Backdrop open={show} color="primary" >
            <div className="flex items-center">
                <CircularProgress color="primary" />
            </div>

        </Backdrop>
        : null
}

export default Loading
import React, { useContext } from 'react'
import UserContext from '../state/UserContext'
import Footer from '../components/Footer'
import Loading from '../components/Loading'
import Aside from './Aside'
import ErrorBoundary from './ErrorBoundary'
import Toolbar from './Toolbar'
import { useHistory } from 'react-router-dom'

const AdminTemplate = (props) => {

    const { user } = useContext(UserContext)
    const history = useHistory()

    const render = () => {
        if (props.loged && !user) {
            console.log("location=", history.location)
            history.push({ pathname: "/login", search: `?url=${history.location.pathname}${encodeURIComponent(history.location.search)}` })
            //history.push("/login")
        }

        const childrens = (
            <article className="flex-grow">
                {props.children && (
                    <>
                        <Loading show={props.loading} />
                        <ErrorBoundary>
                            {props.children}
                        </ErrorBoundary>
                    </>
                )}
            </article>
        )

        if (props.modal) {
            return childrens
        }

        return (
            <div className="flex flex-col" style={{ minHeight: "100vh" }}>
                <header className="fixed lg:relative w-full z-10">
                    <Toolbar />
                </header>
                <main className="flex flex-grow flex-row mt-12 lg:mt-0">
                    <Loading show={props.loading} />
                    {props.loged && <Aside />}
                    <article className="flex-grow">
                        {childrens}
                    </article>
                    {/* <nav className="w-72 border-l border-dark-100">...</nav> */}
                </main>
                <footer>
                    <Footer />
                </footer>
            </div>
        )
    }

    return render()
}

AdminTemplate.defaultProps = {
    loading: false,
    loged: true
}

export default AdminTemplate
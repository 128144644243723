import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@material-ui/core'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import AdminTemplate from '../template/AdminTemplate'
import APIInvoke from '../utils/APIInvoke'
import Toolbar from '../components/Toolbar'
import { useParams, useHistory } from "react-router-dom";
import Alert from '../components/Alert'
import { numberFormat } from '../utils/NumberUtils';

const CerrarRecibo = (props) => {

    const params = useParams()
    const reciboId = props.reciboId || params.reciboId
    const [loading, setLoading] = useState(false)
    const [state, setState] = useState({
        razones: [],
        recibo: null,
        poliza: props.poliza,
        razon: "",
        observaciones: ""
    })
    const [message, setMessage] = useState(null)

    useEffect(async () => {
        try {
            setLoading(true)
            const razonesResponse = await (await (APIInvoke.invokeGET(`/razones?modelo=0&tipo=3`))).json()
            const reciboResponse = await (await (APIInvoke.invokeGET(`/recibos/${reciboId}`))).json()
            setState({
                ...state,
                razones: razonesResponse.body,
                recibo: reciboResponse.body
            })
        } catch (e) {
            console.error(e)
        } finally {
            setLoading(false)
        }
    }, [])

    const onChange = (e) => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        })
    }

    const onSubmit = async (e) => {
        e.preventDefault()

        try {
            setLoading(true)

            const request = {
                polizaId: state.poliza.id,
                razonId: state.razon,
                texto: state.observaciones
            }

            let response = null
            if(state.recibo.status === "pendiente") {
                response = await (await (APIInvoke.invokePUT(`/recibos/${reciboId}/cancelar`, request))).json()
            }else if (state.recibo.status === "cancelado") {
                response = await (await (APIInvoke.invokePUT(`/recibos/${reciboId}/reactivar`, request))).json()
            }
            
            if (response.ok) {
                setMessage({
                    message: response.message,
                    type: "success"
                })

                if (props.onCompleted) {
                    props.onCompleted(response.body)
                }
            } else {
                setMessage({
                    message: response.message,
                    type: "error"
                })
            }

        } catch (e) {
            console.error(e)
        } finally {
            setLoading(false)
        }

    }

    const render = () => {
        const poliza = state.poliza
        const recibo = state.recibo || {}
        const cliente = state.poliza?.cliente
        return (
            <AdminTemplate loading={loading} modal={props.modal}>
                <Toolbar title={`${state.algunoPagado ? "Cerrar Póliza" : "Cancelar Póliza"}`} modal={props.modal} />
                <div className="main">
                    <div className="layout-compact">
                        <form onSubmit={onSubmit}>
                            <div className="grid grid-cols-1 gap-4">
                                <div>
                                    <p className="text-yellow font-bold">No. Poliza</p>
                                    <p >{poliza?.noPoliza}</p>
                                </div>
                                <div>
                                    <p className="text-yellow font-bold">Concepto</p>
                                    <p >{poliza?.concepto}</p>
                                </div>
                                <div>
                                    <p className="text-yellow font-bold">Contratante</p>
                                    <p >{`${cliente?.nombres} ${cliente?.apPat} ${cliente?.apMat}`}</p>
                                </div>
                                <div>
                                    <p className="text-yellow font-bold">Recibo</p>
                                    <p >{`${recibo.serie}/${recibo.totalSerie} - ${numberFormat(recibo.total)}`}</p>
                                </div>

                                <FormControl variant="outlined" size="medium" required>
                                    <InputLabel id="razon" required>Razón de Cierre</InputLabel>
                                    <Select
                                        required
                                        labelId="razon"
                                        label="Razón de Cierre"
                                        size="medium"
                                        name="razon"
                                        value={state.razon}
                                        onChange={onChange}
                                    >
                                        <MenuItem value="" ><em>Seleccione</em></MenuItem>
                                        {state.razones.map(razon => <MenuItem key={razon.id} value={razon.id} >{razon.nombre}</MenuItem>)}
                                    </Select>
                                </FormControl>

                                <TextField
                                    inputProps={{ className: "text-white" }}
                                    className="w-full"
                                    color="primary"
                                    label="Comentarios"
                                    name="observaciones"
                                    value={state.observaciones}
                                    onChange={onChange}
                                    multiline
                                    rows={4}
                                    required
                                />
                                <Button type="submit" className="btn btn-yellow">{`${recibo.status === "cancelado" ? "REACTIVAR RECIBO" : "CERRAR RECIBO"}`}</Button>
                                <Alert className="" {...message} show={message} />
                            </div>
                        </form>
                    </div>
                </div>
            </AdminTemplate>
        )
    }

    return render()
}

export default CerrarRecibo
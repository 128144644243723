const { DateTime } = require("luxon");

function lastDayOfMonth(date) {
  return new Date(date.getFullYear(), date.getMonth() + 1, 0);
}

function dateTimeFormat(dateAsString) {
  const date = new Date(dateAsString);

  const day = `${date.getDate()}`.padStart(2, "0");
  const month = `${date.getMonth() + 1}`.padStart(2, "0");
  const year = `${date.getFullYear()}`;
  const hour = `${date.getHours()}`;
  const minute = `${date.getMinutes()}`;

  return `${day}/${month}/${year} ${hour}:${minute} `;
}

function addYear(date, years) {
  const newDate = new Date(date.getTime());
  newDate.setFullYear(newDate.getFullYear() + years);
  return newDate;
}

function addDays(date, days) {
  const newDate = new Date(date.getTime());
  newDate.setDate(newDate.getDate() + days);
  return newDate;
}

function getListOfMonths() {
  //return [...Array(11).keys()].map(key => new Date(0, key).toLocaleString('es', { month: 'long' }))
}

function parse(dateAsString, dateFormat) {
  if(dateAsString === null || dateAsString === undefined) return null;
  return DateTime.fromFormat(dateAsString, dateFormat).toJSDate();
}

// Guia de formatos: https://moment.github.io/luxon/index.html#/formatting?id=table-of-tokens
function format(date, format) {
  return DateTime.fromJSDate(date).toFormat(format);
}


module.exports = {
  addDays,
  lastDayOfMonth,
  dateTimeFormat,
  addYear,
  format,
  getListOfMonths,
  parse,
};

// DateTime.fromFormat("2021-07-05", "yyyy-MM-dd").toJSDate()

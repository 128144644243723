import React, { useState, useContext } from 'react'
import { Link, useHistory, useLocation } from "react-router-dom";
import Loading from '../components/Loading';
import Navbar from '../components/Navbar'
import APIInvoke from '../utils/APIInvoke';
import Alert from '../components/Alert'
import { TextField, InputAdornment, Button, IconButton } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { maxFieldLength } from '../utils/FormUtils';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const SetNewPassword = (props) => {

    const history = useHistory()
    const query = useQuery()
    const [errorMessage, setErrorMessage] = useState('');
    const [state, setState] = useState({
        loading: false,
        showPassword: false,
        email: query.get("email"),
        codigo: query.get("codigo"),
        newPassword: "",
        newPassword2: ""
    })

    const onChange = (e) => {
        const name = e.target.name
        const value = e.target.value
        setState({
            ...state,
            [name]: value
        })
    }

    const onShowPassword = () => {
        setState({
            ...state,
            showPassword: !state.showPassword
        })
    }

    const onSubmit = async (e) => {
        e.preventDefault()
        try {

            if (state.newPassword !== state.newPassword2) {
                setErrorMessage({
                    message: "Los dos passwords no coinciden",
                    type: "error"
                })
                return
            }

            setState({
                ...state,
                loading: true
            })

            const request = {
                "email": state.email,
                "codigo": state.codigo,
                "newPassword": state.newPassword
            }

            const response = await (await APIInvoke.invokePUT(`/usuarios/password/restore/set`, request)).json();
            setErrorMessage({
                message: response.message,
                type: response.ok ? "success" : "error"
            })

            if(response.ok) {
                history.push(`/login`)
            }
        } catch (e) {
            console.log(e)
        } finally {
            setState({
                ...state,
                loading: false
            })
        }
    }

    const render = () => {

        return (
            <div>
                <Loading show={state.loading} />
                <Navbar title="Restaurar Contraseña" showBackButton={false} />

                <div className="mt-8 container m-auto grid grid-cols-1 lg:grid-cols-3 lg:mt-32">
                    <div className="lg:col-start-2 px-8 lg:px-0">
                        <p className="text-gray-400 mb-8">Introduce tu correo para enviarte restaurar el password</p>
                        <form className="grid gap-4" onSubmit={onSubmit}>
                            <TextField
                                label="Correo"
                                type="email"
                                variant="outlined"
                                name="email"
                                required
                                disabled
                                value={state.email}
                                onChange={onChange}
                            />
                            <TextField
                                label="Código"
                                type="text"
                                variant="outlined"
                                name="codigo"
                                required
                                disabled
                                value={state.codigo}
                                onChange={onChange}
                            />
                            <TextField
                                label="Nueva Contraseña"
                                type={state.showPassword ? 'text' : 'password'}
                                variant="outlined"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={onShowPassword}  >
                                                {state.showPassword ? <VisibilityIcon className="text-gray-400" /> : <VisibilityOffIcon className="text-gray-400" />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                onInput={(e) => maxFieldLength(e, 50)}
                                name="newPassword"
                                onChange={onChange}
                                required
                            />
                            <TextField
                                label="Repetir Contraseña"
                                type={state.showPassword ? 'text' : 'password'}
                                variant="outlined"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={onShowPassword}  >
                                                {state.showPassword ? <VisibilityIcon className="text-gray-400" /> : <VisibilityOffIcon className="text-gray-400" />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                onInput={(e) => maxFieldLength(e, 50)}
                                name="newPassword2"
                                onChange={onChange}
                                required
                            />
                            <Alert type={errorMessage.type} message={errorMessage.message} />
                            <p className="text-center text-red">{ }</p>
                            <Button type="submit" className="btn btn-yellow" color="primary">Restaurar Contraseña</Button>
                        </form>

                        {/* 
                        <div className="grid grid-cols-6 mt-8 items-center">
                            <Divider />
                            <p className="col-span-4 text-center text-gray-400">O también puedes registrarte con...</p>
                            <Divider />
                        </div>
                        <div className="grid justify-items-center grid-cols-5 lg:grid-cols-7 mt-4">
                            <div style={border} className="col-start-2 lg:col-start-3 border">
                                <img src="/companies/facebook-logo.svg" alt="facebook" />
                            </div>
                            <div style={border}>
                                <img src="/companies/google-logo.svg" alt="google" />
                            </div>
                            <div style={border}>
                                <img src="/companies/apple-logo.svg" alt="apple" />
                            </div>
                        </div>
                        */}
                        <div className="text-center mt-8">
                            <p className="text-gray-400">¿No tienes cuenta? <Link to={"/registro"} className="no-underline ml-4">Regístrate</Link></p>
                        </div>
                        <div className="text-center mt-8">
                            <span className="text-gray-400 mr-4"><Link to={{ pathname: `/planes`, state: { backUrl: `/login` } }} className="no-underline ml-4 text-sm">Ver planes</Link></span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return render()
}

export default SetNewPassword
import CachedIcon from '@material-ui/icons/Cached';
import DescriptionIcon from '@material-ui/icons/Description';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Hamburger from 'hamburger-react'
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import { Link, NavLink } from 'react-router-dom'
import React, { useContext } from 'react'
import SettingsIcon from '@material-ui/icons/Settings';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import AppContext from '../state/AppContext';
import Drawer from '@material-ui/core/Drawer';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import BusinessIcon from '@mui/icons-material/Business';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import UserContext from '../state/UserContext';
import SchoolIcon from '@mui/icons-material/School'
import AvTimerIcon from '@mui/icons-material/AvTimer'
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload'
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import AssessmentIcon from '@mui/icons-material/Assessment';

const Aside = (props) => {

    const { appContext, setAppContext } = useContext(AppContext)
    const { user, setUser } = useContext(UserContext)

    const toogleNavbar = () => {
        setAppContext({
            ...appContext,
            navbar: {
                open: !appContext.navbar.open
            }
        })
    }

    const logout = () => {
        window.localStorage.removeItem("token")
        window.location = "/login"
    }

    const render = () => {
        const open = appContext.navbar.open
        const styles = open ? "hidden lg:block w-72 transition duration-700 ease-in-out border-r border-dark-100 bg-dark-400" : "hidden lg:block w-14 border-r transition duration-700 ease-in-out border-dark-100 bg-dark-400"
        const labelStyles = open ? "inline-block" : "hidden"
        const ulStyle = open ? "p-4 bg-dark-400" : "px-2 py-4 bg-dark-400"

        const liStyle = "mb-4 text-white hover:text-yellow"
        const aClass = "no-underline rounded-md px-2 py-2 t-0 l-0 flex items-center transition duration-700 ease-in-out "
        const aActiveClass = "bg-yellow text-dark-500"
        const activeStyle = { backgroundColor: "#F4D144", color: "black" }

        const suscription = user && user.suscripcion
            ?
            <li className="mt-4">
                <div className="border-b border-dark-100 mb-4" />
                <p className={`${labelStyles} px-2 text-sm mb-2  text-dark-100`}>Tu suscripción:</p>
                <Link to="mi-plan" className={`${labelStyles} left-0 flex-1 text-left px-2 flex items-center text-gray-200 hover:text-yellow no-underline`}>
                    <BusinessIcon fontSize='small' /> <span className="ml-2 text-sm text-inherit">{user.suscripcion.plan.nombre}</span>
                </Link>
            </li>
            : null

        const menu = (mobile) => (
            <div className="h-12 bg-dark-300">
                <div className="bg-dark-300 text-right flex justify-between lg:justify-end items-center">
                    <img src="/brand/dalala-logo-lg.svg" alt="dalala" className="inline-block lg:hidden h-8 ml-4" />
                    <Hamburger
                        className="text-white bg-white inline-block"
                        size={20}
                        color="#FFF"
                        toggled={open}
                        toggle={toogleNavbar}

                    />
                </div>

                <ul className={ulStyle}>
                    {user && user.rol === "USER" && (
                        <>
                            <li className={liStyle}>
                                <NavLink
                                    to="/resumen"
                                    className={aClass}
                                    activeStyle={activeStyle}
                                    activeClassName={aActiveClass}
                                    style={{ color: "inherit" }}
                                    onClick={mobile ? toogleNavbar : null}
                                >
                                    <DescriptionIcon className="mr-2 " /> <p className={labelStyles} style={{ color: "inherit" }}>Resumen</p>
                                </NavLink>
                            </li>
                            <li className={liStyle}>
                                <NavLink
                                    to="/clientes"
                                    className={aClass}
                                    activeStyle={activeStyle}
                                    activeClassName={aActiveClass}
                                    style={{ color: "inherit" }}
                                    onClick={mobile ? toogleNavbar : null}
                                >
                                    <SupervisedUserCircleIcon className="mr-2 " /> <p className={labelStyles} style={{ color: "inherit" }}>Clientes</p>
                                </NavLink>
                            </li>

                            <li className={liStyle}>
                                <NavLink
                                    to="/polizas"
                                    className={aClass}
                                    activeStyle={activeStyle}
                                    activeClassName={aActiveClass}
                                    style={{ color: "inherit" }}
                                    onClick={mobile ? toogleNavbar : null}
                                >
                                    <InsertDriveFileIcon className="mr-2 " /> <p className={labelStyles} style={{ color: "inherit" }}>Pólizas</p>
                                </NavLink>
                            </li>

                            <li className={liStyle}>
                                <NavLink
                                    to="/cxc"
                                    className={aClass}
                                    activeStyle={activeStyle}
                                    activeClassName={aActiveClass}
                                    style={{ color: "inherit" }}
                                    onClick={mobile ? toogleNavbar : null}
                                >
                                    <MonetizationOnIcon className="mr-2 " /> <p className={labelStyles} style={{ color: "inherit" }}>Cobranza</p>
                                </NavLink>
                            </li>

                            <li className={liStyle}>
                                <NavLink
                                    to="/renovaciones"
                                    className={aClass}
                                    activeClassName={aActiveClass}
                                    style={{ color: "inherit" }}
                                    onClick={mobile ? toogleNavbar : null}
                                >
                                    <CachedIcon className="mr-2" /> <p className={labelStyles} style={{ color: "inherit" }}>Renovaciones</p>
                                </NavLink>
                            </li>

                            <li className={liStyle}>
                                <NavLink
                                    to="/calendario"
                                    className={aClass}
                                    activeClassName={aActiveClass}
                                    style={{ color: "inherit" }}
                                    onClick={mobile ? toogleNavbar : null}
                                >
                                    <EventAvailableIcon className="mr-2" /> <p className={labelStyles} style={{ color: "inherit" }}>Calendario</p>
                                </NavLink>
                            </li>




                            <li className={liStyle}>
                                <NavLink
                                    to="/automatizaciones"
                                    className={aClass}
                                    activeClassName={aActiveClass}
                                    style={{ color: "inherit" }}
                                    onClick={mobile ? toogleNavbar : null}
                                >
                                    <AvTimerIcon className="mr-2" /> <p className={labelStyles} style={{ color: "inherit" }}>Automatizaciones</p>
                                </NavLink>
                            </li>

                            <li className={liStyle}>
                                <NavLink
                                    to="/reportes"
                                    className={aClass}
                                    activeClassName={aActiveClass}
                                    style={{ color: "inherit" }}
                                    onClick={mobile ? toogleNavbar : null}
                                >
                                    <AssessmentIcon className="mr-2" /> <p className={labelStyles} style={{ color: "inherit" }}>Reportes</p>
                                </NavLink>
                            </li>
                            <li className={liStyle}>
                                <NavLink
                                    to="/importar-polizas-pdf"
                                    className={aClass}
                                    activeClassName={aActiveClass}
                                    style={{ color: "inherit" }}
                                    onClick={mobile ? toogleNavbar : null}
                                >
                                    <DriveFolderUploadIcon className="mr-2" /> <p className={labelStyles} style={{ color: "inherit" }}>Importar Pólizas</p>
                                </NavLink>
                            </li>
                            <li className={liStyle}>
                                <a href="https://appdalala.blogspot.com/" className={aClass} style={{ color: "inherit" }} target="_blank" >
                                    <SchoolIcon className="mr-2" /> <p className={labelStyles} style={{ color: "inherit" }}>Blog</p>
                                </a>
                            </li>
                            <li className={liStyle}>
                                <NavLink
                                    to="/configuracion"
                                    className={aClass}
                                    activeClassName={aActiveClass}
                                    style={{ color: "inherit" }}
                                    onClick={mobile ? toogleNavbar : null}
                                >
                                    <SettingsIcon className="mr-2" /> <p className={labelStyles} style={{ color: "inherit" }}>Configuración</p>
                                </NavLink>
                            </li>
                        </>
                    )}

                    <li className={liStyle}>
                        <div className={aClass} style={{ color: "inherit", cursor: "pointer" }} onClick={logout}>
                            <ExitToAppIcon className="mr-2" /> <p className={labelStyles} style={{ color: "inherit" }}>Salir</p>
                        </div>
                    </li>
                    {suscription}


                </ul>
            </div>
        )

        return (
            <>
                <aside className={styles}>
                    <div className="hidden lg:block ">
                        {menu(false)}
                    </div>
                </aside>
                <Drawer className="block lg:hidden bg-dark-400" anchor={"left"} disableScrollLock={true} open={open} onClose={toogleNavbar} color="primary">
                    <div className="bg-dark-400 h-full" style={{ width: "80vw" }}>
                        {menu(true)}
                    </div>
                </Drawer>
            </>
        )
    }

    return render()

}
export default Aside
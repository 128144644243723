import { DeveloperBoardOff } from '@mui/icons-material'
import React from 'react'
import AdminTemplate from '../template/AdminTemplate'
import { Link } from 'react-router-dom'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const Page404 = (props) => {

    return (
        <AdminTemplate loading={false} >
            <div className="main">
                <div className="layout mt-32">
                    <div className="flex flex-col  items-center justify-center content-center ">
                        <img src="/icons/404-yellow.svg" alt="Página no encontrada" className="inline-block relative w-2/3 lg:w-1/2 mb-8" />
                        <p className="text-xl text-center mb-4">Upss... Página no encontrada</p>
                        <Link to="/" className="text-yellow no-underline"><ArrowBackIcon /> Regresar</Link>
                    </div>
                </div>
            </div>
        </AdminTemplate >
    )
}
export default Page404
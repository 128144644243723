import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AttachmentIcon from '@mui/icons-material/Attachment';
import EditIcon from '@mui/icons-material/Edit';
import ForumIcon from '@material-ui/icons/Forum';
import { Link } from 'react-router-dom';
import PersonPinCircleIcon from '@material-ui/icons/PersonPinCircle';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router';

import AdminTemplate from '../template/AdminTemplate';
import APIInvoke from '../utils/APIInvoke'
import { parse, format } from "../utils/DateUtils";
import Toolbar from '../components/Toolbar'
import DalalaDialog from '../components/DalalaDialog';
import Bitacora from './Bitacora';
import Adjuntos from './Adjuntos';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { Button } from '@material-ui/core';

const ConsultaCliente = (props) => {

    const params = useParams()
    const clienteId = props.clienteId || params.clienteId

    const [loading, setLoading] = useState(true);
    const [result, setResult] = useState({
        direcciones: []
    })
    const [hasError, setHasError] = useState(false)
    const [openBitacoraDialog, setOpenBitacoraDialog] = useState({ show: false, id: null })
    const [openAdjuntosDialog, setOpenAdjuntosDialog] = useState({ show: false, id: null })

    useEffect(async () => {

        try {
            setLoading(true)
            const responseConCliente = await (await APIInvoke.invokeGET(`/clientes/${clienteId}`)).json();
            setResult({
                ...responseConCliente.body,
                fecNacimiento: parse(responseConCliente.body.fecNacimiento, "yyyy-MM-dd")
            })
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false)
        }

    }, [])

    const render = () => {

        const direccion = result.direcciones[0] || {}
        const sepomex = direccion.sepomex || {}



        return (
            <AdminTemplate loading={loading} modal={props.modal}>
                <DalalaDialog
                    title={"Bitácora"}
                    open={openBitacoraDialog.show}
                    handleClose={() => setOpenBitacoraDialog({ show: false, id: null })}
                >
                    <Bitacora modal={true} entidad={"clientes"} entidadId={openBitacoraDialog.id} onCompleted={() => setOpenBitacoraDialog({ show: false, id: null })} />
                </DalalaDialog>
                <DalalaDialog
                    title={"Adjuntos"}
                    open={openAdjuntosDialog.show}
                    handleClose={() => setOpenAdjuntosDialog({ show: false, id: null })}
                >
                    <Adjuntos modal={true} entidad={"clientes"} entidadId={openAdjuntosDialog.id} onCompleted={() => setOpenAdjuntosDialog({ show: false, id: null })} />
                </DalalaDialog>
                <Toolbar title="Detalle de Cliente" backUrl={`/clientes`} modal={props.modal}>
                    {result && (
                        <div className="layout">
                            <div className="flex justify-end">
                                <Link className="no-underline p-1" to={`/clientes/${clienteId}/edit`}>
                                    <div className="bg-yellow h-10 w-10 text-center flex items-center rounded-md">
                                        <EditIcon className="text-dark-600 ml-2"></EditIcon>
                                    </div>
                                </Link>
                                <Link className="no-underline p-1" to="#" onClick={e => setOpenAdjuntosDialog({ show: true, id: result.id })}>
                                    <div className="bg-yellow h-10 w-10 text-center flex items-center rounded-md">
                                        <AttachmentIcon className="text-dark-600 ml-2"></AttachmentIcon>
                                    </div>
                                </Link>
                                <Link className="no-underline p-1" to="#" onClick={e => setOpenBitacoraDialog({ show: true, id: result.id })}>
                                    <div className="bg-yellow h-10 w-10 text-center flex items-center rounded-md">
                                        <ForumIcon className="text-dark-600 ml-2"></ForumIcon>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    )}
                </Toolbar>
                <div className="main">
                    <div className="layout-compact">
                        {result && (
                            <div className="grid grid-cols-1 gap-y-8 mb-8">
                                {result?.esRobot && (
                                    <div className="flex gap-4 bg-yellow p-4 rounded-md">
                                        <WarningRoundedIcon className='text-dark-600' />
                                        <p className='flex-1 text-dark-600 text-sm'>Cliente creado automáticamente con la tecnología de sincronización dalala <Link to={"#"} className="text-dark-600" onClick={e => setResult({
                                            ...result,
                                            verInfo: true
                                        })}>Más información</Link> </p>
                                    </div>
                                )}


                                <div>
                                    <p className="text-gray-400 text-base font-semibold">DATOS GENERALES</p>
                                </div>
                                <section className="rounded-md bg-dark-400 px-4">
                                    <div className="flex justify-between">
                                        <p className="text-green-500 mt-4 mb-2 text-base font-semibold uppercase">{result.status}</p>
                                        <AccountCircleIcon fontSize="large" className="ml-28 mt-2 mb-2 xs:p-0 text-gray-400"></AccountCircleIcon>
                                    </div>

                                    <section className="grid mt-4">
                                        <p className="text-yellow text-base">Nombre</p>
                                        <p className="text-white text-base capitalize">{result.nombres + " " + result.apPat + " " + result.apMat}</p>
                                    </section>
                                    <section className="grid mt-4">
                                        <p className="text-yellow text-base">Fecha de Nacimiento</p>
                                        <p className="text-white text-base capitalize">{result.fecNacimiento ? format(result.fecNacimiento, 'dd/MM/yyyy') : "NA"}</p>
                                    </section>
                                    <section className="grid mt-4">
                                        <p className="text-yellow text-base">RFC</p>
                                        <p className="text-white text-base capitalize">{result.rfc}</p>
                                    </section>
                                    <section className="grid mt-4">
                                        <p className="text-yellow text-base">Sexo</p>
                                        <p className="text-white text-base capitalize">{result.sexo}</p>
                                    </section>
                                    <section className="grid mt-4 pb-4">
                                        <p className="text-yellow text-base">Estado Civil</p>
                                        <p className="text-white text-base capitalize">{result.edoCivil}</p>
                                    </section>
                                </section>


                                <div>
                                    <p className="text-gray-400 text-base font-semibold">LOCALIZACIÓN</p>
                                </div>

                                <section className="rounded-md bg-dark-400 p-4">
                                    <section className="flex justify-between">
                                        <div>
                                            <p className="text-yellow text-base font-bold">Dirección</p>
                                            <p className="text-white text-base capitalize">{direccion?.direccion}</p>
                                        </div>
                                        <PersonPinCircleIcon fontSize="large" className="text-gray-400 "></PersonPinCircleIcon>
                                    </section>
                                    <section className="grid mt-4">
                                        <p className="text-yellow text-base">Colonia</p>
                                        <p className="text-white text-base capitalize">{direccion?.colonia}</p>
                                    </section>
                                    <section className="grid mt-4">
                                        <p className="text-yellow text-base">CP</p>
                                        <p className="text-white text-base capitalize">{direccion?.cp}</p>
                                    </section>
                                    <section className="grid mt-4">
                                        <p className="text-yellow text-base">Municipio</p>
                                        <p className="text-white text-base capitalize">{sepomex.municipio}</p>
                                    </section>
                                    <section className="grid mt-4">
                                        <p className="text-yellow text-base">Celular</p>
                                        {result?.celulares?.map((cel, index) => (
                                            <p key={index} className="text-white text-base capitalize">{cel?.numero}</p>
                                        ))}
                                    </section>
                                    <section className="grid mt-4 pb-2">
                                        <p className="text-yellow text-base">Correo</p>
                                        {result?.correos?.map((correo, index) => (
                                            <p key={index} className="text-white text-base">{correo?.correo}</p>
                                        ))}
                                    </section>
                                </section>
                                { result.verInfo === true && (
                                        <Dialog
                                            open={result.verInfo === true}
                                            onClose={e => setResult({
                                                ...result,
                                                verInfo: false
                                            })}
                                            aria-labelledby="alert-dialog-title"
                                            aria-describedby="alert-dialog-description"
                                        >
                                            <DialogContent style={{ width: "330px", maxWidth: "100%" }}>
                                                <DialogContentText className="text-gray-400" id="alert-dialog-description">
                                                <p>
                                                    La póliza o cliente se integró desde la <span className="text-yellow font-bold">sincronización automática</span>, este proceso utiliza el PDF original de la póliza para obtener los datos de la póliza o el cliente, aunque este proceso se perfecciona día con día es <span className="text-yellow font-bold">indispensable</span> sea validado por el agente de forma puntual para así garantizar la <span className="font-bold">confiabilidad</span> de la información, por esto recomendamos revisar los siguientes puntos:
                                                </p>
                                                <p className="pl-4">
                                                    <ul className="py-6 list-disc">
                                                        <li>Datos del Cliente</li>
                                                        <li>Primas de la póliza</li>
                                                        <li>Vigencias de la póliza</li>
                                                        <li>No. de Recibos</li>
                                                        <li>Primas de Recibos</li>
                                                        <li>Vigencias de Recibos</li>
                                                    </ul>
                                                </p>
                                                <p>
                                                    También te recomendamos completar el correo y teléfono de tu cliente para los procesos de comunicación operativos
                                                </p>
                                                </DialogContentText>
                                            </DialogContent>
                                            <DialogActions className="justify-center pb-6">
                                                <Button className="btn-yellow font-bold w-32" onClick={e => setResult({
                                                ...result,
                                                verInfo: false
                                            })}>ACEPTAR</Button>
                                            </DialogActions>
                                        </Dialog>
                                    )}
                            </div>
                        )}
                    </div>
                </div>
            </AdminTemplate>
        )

    }


    return render()

}

export default ConsultaCliente
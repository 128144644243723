import React, { useEffect, useState, useContext } from 'react'
import AdminTemplate from '../template/AdminTemplate'
import Toolbar from '../components/Toolbar'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckIcon from '@mui/icons-material/Check';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Button } from '@material-ui/core';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import config from '../config'
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import DownloadingIcon from '@mui/icons-material/Downloading';
import DescriptionIcon from '@mui/icons-material/Description';
import BarChartIcon from '@mui/icons-material/BarChart';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import APIInvoke from '../utils/APIInvoke'
import UserContext from '../state/UserContext';
import { useHistory, Redirect, Link } from "react-router-dom";

const Planes = (props) => {

    const { user } = useContext(UserContext);
    const [loading, setLoading] = useState(false)

    const [state, setState] = useState({
        tipoPlan: "mensual"
    })

    const [planes, setPlanes] = useState([])
    const history = useHistory()

    useEffect(async () => {
        if (user?.suscripcion && user.suscripcion.plan.periodicidad !== "prueba") {
            history.push("/")
        }
        try {
            setLoading(true)
            const planResponse = await (await APIInvoke.invokeGET(`/planes?periodicidad=${state.tipoPlan.toUpperCase()}`)).json()
            setPlanes(planResponse.body)
        } catch (e) {
            console.error("error", e)
        } finally {
            setLoading(false)
        }
    }, [state.tipoPlan])

    const createCheckout = async (priceId) => {
        try {
            setLoading(true)
            const response = await (await APIInvoke.invokePOST(`/payments/stripe/create-checkout?priceId=${priceId}`)).json()
            window.location = response.body.redirectURL
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }


    const renderPlan = (plan) => {
        if (plan.recomendado) {
            return (
                <article key={plan.id} className="grid grid-cols-1 gap-4 bg-yellow p-4 text-dark-500 rounded-md lg:rounded-none ">
                    <div className="relative">
                        <p className="text-2xl font-bold text-inherit mb-4">{plan.nombre}</p>
                        <div className="mb-2 text-inherit"><p className="text-4xl font-normal inline-block text-inherit">${plan.precio}</p><span className="text-sm text-inherit">/ MXN</span></div>
                        <p className="text-sm text-inherit mb-2">{plan.periodicidad === "mensual" ? "Renta Mensual" : "Renta Anual"}</p>
                        <p className="border-b-2 border-dark-500 absolute bottom-0 left-0 right-0 -mx-4 " />
                    </div>
                    {plan.items.map(item => (
                        <div key={item.id} className="flex flex-row gap-4">
                            <span className="text-sm flex-1 text-inherit">{item.descripcion}</span>
                            <p className="text-right text-sm"><span className="mr-4 text-dark-500 font-bold">{item.cantidad} {item.nombre === "Frecuencia" ? " días" : ""}</span>
                                <Tooltip title={item.ayuda}>
                                    <HelpOutlineIcon className="text-dark-500" />
                                </Tooltip>
                            </p>
                        </div>
                    ))}
                    {user
                        ? <Button onClick={e => createCheckout(plan.stripeId)} className="btn bg-dark-500 hover:bg-dark-600">SELECCIONAR</Button>
                        : <Link className="btn bg-dark-500 hover:bg-dark-600 text-center block no-underline" to={"/registro"}>COMENZAR</Link>
                    }

                </article>
            )
        } else {
            return (
                <article key={plan.id} className="grid grid-cols-1 gap-4 bg-dark-500 p-4 rounded-md lg:rounded-none hover:border hover:border-yellow">
                    <div className=" relative">
                        <p className="text-2xl font-bold mb-4">{plan.nombre}</p>
                        <div className="mb-2"><p className="text-4xl font-thin inline-block">${plan.precio}</p><span className="text-sm">/ MXN</span></div>
                        <p className="text-sm pb-2">{plan.periodicidad === "mensual" ? "Renta Mensual" : "Renta Anual"}</p>
                        <p className="border-b border-yellow absolute bottom-0 left-0 right-0 -mx-4 " />
                    </div>

                    {plan.items.map(item => (
                        <div key={item.id} className="flex flex-row gap-4">
                            <span className="text-sm flex-1">{item.descripcion}</span>
                            <p className="text-right text-sm"><span className="mr-4 text-yellow font-bold">{item.cantidad}  {item.nombre === "Frecuencia" ? " días" : ""}</span>
                                <Tooltip title={item.ayuda}>
                                    <HelpOutlineIcon className="text-gray-500" />
                                </Tooltip>
                            </p>
                        </div>
                    ))}

                    {user
                        ? <Button onClick={e => createCheckout(plan.stripeId)} className="btn btn-yellow">SELECCIONAR</Button>
                        : <Link to="/registro" className="btn btn-yellow text-center block no-underline" >COMENZAR</Link>
                    }
                </article>
            )
        }
    }


    const render = () => {
        return (
            <AdminTemplate loading={loading} loged={user ? true : false} >
                <Toolbar title="Planes" backUrl={`/`} />
                <div className="main mb-0 mt-0">
                    <div className="bg-dark-500 py-16 mb-8 border-b border-yellow-700">
                        <div className="layout">
                            <p className="text-yellow text-lg mb-8">Todos los paquetes incluyen</p>

                            <div className="grid grid-cols-1 lg:grid-cols-2">

                                <ul className="text-gray-500 inline-block ">
                                    <li className="mb-2"><CheckCircleIcon className="text-yellow mr-2" />500 Clientes</li>
                                    <li className="mb-2"><CheckCircleIcon className="text-yellow mr-2" />500 Pólizas</li>
                                    <li className="mb-2"><CheckCircleIcon className="text-yellow mr-2" />2000 Documentos Adjuntos</li>
                                    <li className="mb-2"><CheckCircleIcon className="text-yellow mr-2" />Módulos de Operación</li>
                                    <li className="mb-2"><CheckCircleIcon className="text-yellow mr-2" />Módulos de Renovaciones</li>
                                    <li className="mb-2"><CheckCircleIcon className="text-yellow mr-2" />Integración con MiPoliza*</li>
                                    <li className="mb-2"><CheckCircleIcon className="text-yellow mr-2" />Integración con Todoist**</li>
                                </ul>

                                <ul className="text-gray-500 inline-block ">
                                    <li className="mb-2"><CheckCircleIcon className="text-yellow mr-2" />Seguimiento de procesos</li>
                                    <li className="mb-2"><CheckCircleIcon className="text-yellow mr-2" />Envío de Correos</li>
                                    <li className="mb-2"><CheckCircleIcon className="text-yellow mr-2" />Integración de Pólizas</li>
                                    <li className="mb-2"><CheckCircleIcon className="text-yellow mr-2" />Captura Manual</li>
                                    <li className="mb-2"><CheckCircleIcon className="text-yellow mr-2" />Importación de PDFs*</li>
                                    <li className="mb-2"><CheckCircleIcon className="text-yellow mr-2" />Integración Automática</li>
                                </ul>
                            </div>
                            <p className='mt-8 text-xs'>* MiPóliza es una marca registrada de © Copsis.</p>
                            <p className='mt-8 text-xs'>** Todoist es una marca registrada de © Doist Inc.</p>
                        </div>

                    </div>

                    <div className="layout mb-18">

                        <div className="flex items-center justify-center mb-4">
                            <span className="hidden lg:inline-block text-sm mr-4">-</span>
                            <ToggleButtonGroup
                                value={state.tipoPlan}
                                exclusive={true}
                                onChange={(e, newType) => setState({ ...state, tipoPlan: newType ? newType : state.tipoPlan })}
                                aria-label="text alignment"
                            >
                                <ToggleButton value="mensual" key="mensual" className="square square-border rounded-none h-12 w-32" aria-label="left aligned">Mensual</ToggleButton>
                                <ToggleButton value="anual" key="anual" className="square square-border rounded-none  h-12 w-32" aria-label="centered">Anual</ToggleButton>
                            </ToggleButtonGroup>
                            <span className="hidden lg:inline-block text-sm ml-4">-</span>
                        </div>

                        <p className="text-sm mb-8 text-center font-thin">Contrata lo que necesites y cancela cuando quieras, sin plazos forzosos</p>

                        <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-4 gap-y-8 lg:gap-x-8 xl:gap-x-0 mb-8">
                            {planes.map(plan => renderPlan(plan))}
                        </div>
                        <p className="text-center text-sm mb-8">¿Necesitas un plan personalizado? <a className="no-underline" href={`mailto:${config.contacts.emailContacto}?Subject=Información%20sobre%20planes%20personalizados`}>Contactanos</a></p>
                    </div>
                    <div className="bg-dark-500 py-16">
                        <div className="layout">
                            <div className="grid gap-4 grid-cols-1 lg:grid-cols-3">
                                <div className="">
                                    <p className="text-yellow text-xl">¿Por que Dalala?</p>
                                    <p className="font-thin">Dalala es la única plataforma para agentes de seguros registrados ante la CNSF</p>
                                </div>
                                <div className="col-span-2 grid gap-4 grid-cols-1 lg:grid-cols-2">
                                    <div>
                                        <DownloadingIcon fontSize="large" className="mb-2 text-gray-200 block" />
                                        <p className="text-yellow">Sincronización con las aseguradoras</p>
                                        <p className="font-thin">Utilizamos complejos mecanismos de integración con las principales aseguradoras para sincronizar las pólizas con dalala.app.</p>
                                    </div>
                                    <div>
                                        <DescriptionIcon fontSize="large" className="mb-2 text-gray-200 block" />
                                        <p className="text-yellow">Procesamiento de carátulas PDF</p>
                                        <p className="font-thin">Procesamos y extraemos la información de las carátulas de las pólizas que utilizas todos los días, actualmente contamos con mas de 60 formatos de las principales aseguradoras.</p>
                                    </div>
                                    <div>
                                        <MonetizationOnIcon fontSize="large" className="mb-2 text-gray-200 block" />
                                        <p className="text-yellow">Gestión de cobranza y renovaciones</p>
                                        <p className="font-thin">Contarás con herramientas para el seguimiento de estos importantes procesos a partir de ordenamientos automáticos de tu trabajo.</p>
                                    </div>
                                    <div>
                                        <BarChartIcon fontSize="large" className="mb-2 text-gray-200 block" />
                                        <p className="text-yellow">Seguimiento de metas</p>
                                        <p className="font-thin">Configura y supervisa tus metas de ventas mensuales o anuales con indicadores que miden tu progreso día a día.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </AdminTemplate>
        )
    }

    return render()
}

export default Planes
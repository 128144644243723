import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import AttachmentIcon from '@mui/icons-material/Attachment';
import ClearIcon from '@mui/icons-material/Clear';
import DateRangeIcon from '@material-ui/icons/DateRange';
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import EditIcon from '@mui/icons-material/Edit';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ForumIcon from '@material-ui/icons/Forum';
import GroupIcon from '@mui/icons-material/Group';
import HomeIcon from '@mui/icons-material/Home';
import { Link } from 'react-router-dom';
import MailIcon from '@mui/icons-material/Mail';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import PersonIcon from '@mui/icons-material/Person';
import React, { useEffect, useRef, useState } from 'react';
import UndoIcon from '@mui/icons-material/Undo';
import { useParams } from 'react-router';
import AdminTemplate from '../template/AdminTemplate';
import APIInvoke from '../utils/APIInvoke'
import { format, parse } from "../utils/DateUtils";
import { numberFormat } from '../utils/NumberUtils';
import Toolbar from '../components/Toolbar'
import DalalaDialog from '../components/DalalaDialog';
import Bitacora from './Bitacora';
import PagoRecibo from './PagoRecibo';
import Adjuntos from './Adjuntos';
import CerrarPoliza from './CerrarPoliza';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { useHistory } from "react-router-dom";
import Correo from './Correo';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { Button } from '@material-ui/core';
import CerrarRecibo from './CerrarRecibo';

const ConsultaPoliza = (props) => {
    const [loading, setLoading] = useState(true);
    const params = useParams()
    const polizaId = props.polizaId || params.polizaId
    const reciboId = props.reciboId || params.reciboId

    const history = useHistory()

    const [polizaData, setPolizaData] = useState({});

    const [openBitacoraDialog, setOpenBitacoraDialog] = useState({ show: false, id: null })
    const [openBitReciboDialog, setOpenBitReciboDialog] = useState({ show: false, id: null })

    const [openAdjuntosDialog, setOpenAdjuntosDialog] = useState({ show: false, id: null })
    const [openAdjuntosReciboDialog, setOpenAdjuntosRecDialog] = useState({ show: false, id: null })
    const [openCancelarReciboDialog, setOpenCancelarReciboDialog] = useState({ show: false, id: null })



    const [openPagoDialog, setOpenPagoDialog] = useState({ show: false, id: null })
    const [openCerrarDialog, setOpenCerrarDialog] = useState({ show: false, id: null })

    const [openCorreo, setOpenCorreoDialog] = useState({ show: false, id: null })
    const [openCorreos, setOpenCorreosDialog] = useState({ show: false, id: null })

    const receiptScrollRef = useRef()

    useEffect(async () => {
        try {
            setLoading(true)
            const response = await (await APIInvoke.invokeGET(`/polizas/${polizaId}`)).json();
            setPolizaData(response.body);
            receiptScrollRef?.current?.scrollIntoView({ behavior: "smooth" })
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false)
        }
    }, [])

    const onCompletedPagoRecibo = (recibo) => {
        const reciboId = recibo.id
        const reciboStatus = recibo.status

        const reciboEditado = polizaData.recibos.find((x) => x.id === reciboId)

        reciboEditado.status = reciboStatus

        setPolizaData({
            ...polizaData,
            recibos: [
                ...polizaData.recibos
            ]
        })

        setOpenPagoDialog({ show: false, id: null })
    }

    const handleEditRenov = () => {
        if (polizaData.status === "vigente" || polizaData.status === "terminada" || polizaData.status === "cancelada") {
            history.push(`/polizas/nuevo`, { polizaId, action: "renovar" })
        } else {
            history.push(`/polizas/${polizaId}`)
        }
    }

    const undoReceiptPay = async (reciboId, index) => {
        try {
            setLoading(true)
            const response = await (await APIInvoke.invokePUT(`/recibos/${reciboId}/cancelarPago`)).json();
            if (response.ok === true) {
                polizaData.recibos[index] = response.body;
                setPolizaData(polizaData)
            }
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false)
        }
    }

    const onPolizaClosed = (poliza) => {
        setPolizaData(poliza)
        setOpenCerrarDialog({ show: false, id: null })
    }

    const onReciboClosed = (reciboUpdate) => {
        setPolizaData({
            ...polizaData,
            recibos: polizaData.recibos.map(recibo => {
                return recibo.id === reciboUpdate.id ? reciboUpdate : recibo
            })
        })
        setOpenCancelarReciboDialog({ show: false, id: null })
    }

    const render = () => {

        const direccion = polizaData?.direccion || {}
        const cliente = polizaData?.cliente || {}
        const moneda = polizaData?.moneda || {}
        const vehiculo = polizaData?.vehiculo || {}
        const asegurados = polizaData?.asegurados || []
        const texto = polizaData?.texto || {}
        const recibos = polizaData?.recibos
        let icons;

        switch (polizaData?.ramo?.modelo) {
            case 1:
                icons = <DirectionsCarIcon fontSize="medium" />;
                break;
            case 3:
                icons = <FavoriteIcon fontSize="medium" />;
                break;
            case 5:
                icons = <PersonIcon fontSize="medium" />;
                break;
            case 7:
                icons = <HomeIcon fontSize="medium" />;
                break;
        }

        return (
            <AdminTemplate loading={loading} modal={props.modal}>
                {/* Bitacora de polizas */}
                <DalalaDialog
                    title={"Bitácora"}
                    open={openBitacoraDialog.show}
                    handleClose={() => setOpenBitacoraDialog({ show: false, id: null })}
                >
                    <Bitacora modal={true} entidad={"polizas"} entidadId={openBitacoraDialog.id} onCompleted={() => setOpenBitacoraDialog({ show: false, id: null })} />
                </DalalaDialog>

                {/* Envio Correo */}
                <DalalaDialog
                    title={"Correo Electronico"}
                    open={openCorreo.show}
                    handleClose={() => setOpenCorreoDialog({ show: false, id: null })}
                >
                    <Correo modal={true} entidad={"poliza"} correoPara={polizaData?.cliente?.correos?.map(x => x.correo).join(", ") || ""} entidadId={polizaData.id} objectId={openCorreo.id} onCompleted={() => setOpenCorreoDialog({ show: false, id: null })} />
                </DalalaDialog>

                {/* Envio Correo en Recibo */}
                <DalalaDialog
                    title={"Correo Electronico"}
                    open={openCorreos.show}
                    handleClose={() => setOpenCorreosDialog({ show: false, id: null })}
                >
                    <Correo modal={true} entidad={"polizas"} objectId={openCorreos.id} onCompleted={() => setOpenCorreosDialog({ show: false, id: null })} />
                </DalalaDialog>


                {/* Bitacora de recibos */}
                <DalalaDialog
                    title={"Bitácora"}
                    open={openBitReciboDialog.show}
                    handleClose={() => setOpenBitReciboDialog({ show: false, id: null })}
                >
                    <Bitacora modal={true} entidad={"recibos"} entidadId={openBitReciboDialog.id} onCompleted={() => setOpenBitReciboDialog({ show: false, id: null })} />
                </DalalaDialog>

                {/* Pagar recibos */}

                <DalalaDialog
                    title={"Pagar Recibo"}
                    open={openPagoDialog.show}
                    handleClose={() => setOpenPagoDialog({ show: false, id: null })}
                >
                    <PagoRecibo modal={true} reciboId={openPagoDialog.id} onCompleted={onCompletedPagoRecibo} />
                </DalalaDialog>

                {/* Adjuntos de polizas */}

                <DalalaDialog
                    title={"Adjuntos"}
                    open={openAdjuntosDialog.show}
                    handleClose={() => setOpenAdjuntosDialog({ show: false, id: null })}
                >
                    <Adjuntos modal={true} entidad={"polizas"} entidadId={openAdjuntosDialog.id} onCompleted={() => setOpenAdjuntosDialog({ show: false, id: null })} />
                </DalalaDialog>

                {/* Adjuntos de recibos */}
                <DalalaDialog
                    title={"Adjuntos"}
                    open={openAdjuntosReciboDialog.show}
                    handleClose={() => setOpenAdjuntosRecDialog({ show: false, id: null })}
                >
                    <Adjuntos modal={true} entidad={"recibos"} entidadId={openAdjuntosReciboDialog.id} onCompleted={() => setOpenAdjuntosRecDialog({ show: false, id: null })} />
                </DalalaDialog>

                {/* Cerrar Poliza */}
                <DalalaDialog
                    title={"Cerrar Póliza"}
                    open={openCerrarDialog.show}
                    handleClose={() => setOpenCerrarDialog({ show: false, id: null })}
                >
                    <CerrarPoliza modal={true} entidad={"polizas"} polizaId={openCerrarDialog.id} onCompleted={onPolizaClosed} />
                </DalalaDialog>

                <DalalaDialog
                    title={openCancelarReciboDialog.title}
                    open={openCancelarReciboDialog.show}
                    handleClose={() => setOpenCancelarReciboDialog({ show: false, id: null })}
                >
                    <CerrarRecibo modal={true} poliza={polizaData} entidad={"recibo"} reciboId={openCancelarReciboDialog.id} onCompleted={onReciboClosed} />
                </DalalaDialog>

                <Toolbar title="Detalle de Póliza" backUrl={`/polizas`} modal={props.modal}>
                    {polizaData && (
                        <div className="layout">
                            <section className="flex justify-between items-center">
                                <div>
                                    <img src={polizaData?.agenteClave?.aseguradora?.logoBco} className="inline-block max-h-9 h-9" />
                                </div>
                                <div className="flex items-center">
                                    {polizaData.status !== "terminada" && polizaData.status !== "cancelada" && (
                                        <Link className="no-underline p-1" to="#" onClick={e => setOpenCerrarDialog({ show: true, id: polizaData.id })}>
                                            <div className="bg-yellow h-10 w-10 text-center flex items-center rounded-md">
                                                <ClearIcon className="text-red ml-2"></ClearIcon>
                                            </div>
                                        </Link>
                                    )}

                                    <Link className="no-underline p-1" to="#" onClick={e => setOpenCorreoDialog({ show: true, id: polizaData.id })}>
                                        <div className="bg-yellow h-10 w-10 text-center flex items-center rounded-md">
                                            <MailIcon className="text-dark-600 ml-2"></MailIcon>
                                        </div>
                                    </Link>
                                    {/* <Link className="no-underline p-1" to="#" onClick={handleEditRenov}>
                                        <div className="bg-yellow h-10 w-10 text-center flex items-center rounded-md">
                                            <AutorenewIcon className="text-dark-600 ml-2"></AutorenewIcon>
                                        </div>
                                    </Link> */}
                                    <div className="bg-yellow h-10 w-10 text-center flex items-center rounded-md m-1 cursor-pointer" variant="contained" onClick={handleEditRenov}>
                                        <div>
                                            <AutorenewIcon className="text-dark-600 ml-2"></AutorenewIcon>
                                        </div>
                                    </div>
                                    <Link className="no-underline p-1" to={`/polizas/${polizaId}`}>
                                        <div className="bg-yellow h-10 w-10 text-center flex items-center rounded-md">
                                            <EditIcon className="text-dark-600 ml-2"></EditIcon>
                                        </div>
                                    </Link>
                                    <Link className="no-underline p-1" to="#" onClick={e => setOpenAdjuntosDialog({ show: true, id: polizaData.id })}>
                                        <div className="bg-yellow h-10 w-10 text-center flex items-center rounded-md">
                                            <AttachmentIcon className="text-dark-600 ml-2"></AttachmentIcon>
                                        </div>
                                    </Link>
                                    <Link className="no-underline p-1" to="#" onClick={e => setOpenBitacoraDialog({ show: true, id: polizaData.id })}>
                                        <div className="bg-yellow h-10 w-10 text-center flex items-center rounded-md">
                                            <ForumIcon className="text-dark-600 ml-2"></ForumIcon>
                                        </div>
                                    </Link>
                                </div>
                            </section>
                        </div>
                    )}
                </Toolbar >

                <div className="main">
                    <div className="layout-compact">
                        {polizaData && (
                            <>
                                <div className="grid grid-cols-1 gap-4 mb-8">
                                    {polizaData.esRobot && (
                                        <div className="flex gap-4 bg-yellow p-4 rounded-md mb-8">
                                            <WarningRoundedIcon className='text-dark-600' />
                                            <p className='flex-1 text-dark-600 text-sm'>Póliza creada automáticamente con la tecnología de sincronización dalala <Link to={"#"} className="text-dark-600" onClick={e => setPolizaData({
                                                ...polizaData,
                                                verInfo: true
                                            })}>Más información</Link> </p>
                                        </div>
                                    )}
                                    <div className="bg-dark-400 rounded-md grid grid-cols-1 gap-2 p-2 lg:gap-4 lg:p-4">
                                        <div className="flex">
                                            <p className="text-gray-400 text-sm font-semibold">DATOS GENERALES</p>
                                        </div>
                                        <section className="rounded-md bg-dark-300 p-4 grid grid-cols-1 gap-4">
                                            <div className="flex justify-between">
                                                <div className="flex items-center" >
                                                    <p className="text-green-500 text-sm font-semibold uppercase">{polizaData.status}</p>
                                                </div>
                                                <div className="bg-dark-600 capitalize rounded-md flex items-center px-1 py-2" >
                                                    <p className="pr-1 ml-2 flex items-center text-sm"> {polizaData?.ramo?.ramo}
                                                        <span className="pl-2 flex items-center px-2">{icons}</span>
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="grid ">
                                                <p className="text-yellow text-sm">No. de Póliza</p>
                                                <p className="text-white text-sm capitalize">{polizaData.noPoliza}</p>
                                            </div>
                                            <div className="grid ">
                                                <p className="text-yellow text-sm">Concepto</p>
                                                <p className="text-white text-sm capitalize">{polizaData.concepto}</p>
                                            </div>
                                            <div className="grid ">
                                                <p className="text-yellow text-sm">Contratante</p>
                                                <p className="text-white text-sm capitalize"> {cliente.nombres} {cliente.apPat} {cliente.apMat}</p>
                                            </div>
                                            <div className="grid ">
                                                <p className="text-yellow text-sm">Dirección</p>
                                                <p className="text-white text-sm capitalize">{direccion.direccion}</p>
                                            </div>
                                            <div className="grid ">
                                                <p className="text-yellow text-sm">RFC</p>
                                                <p className="text-white text-sm capitalize">{cliente.rfc}</p>
                                            </div>

                                            {cliente.esRobot && (
                                                <div className="flex gap-4 bg-yellow p-4 rounded-md">
                                                    <WarningRoundedIcon className='text-dark-600' />
                                                    <p className='flex-1 text-dark-600 text-sm'>Cliente creada automáticamente con la tecnología de sincronización dalala <Link to={"#"} className="text-dark-600" onClick={e => setPolizaData({
                                                        ...polizaData,
                                                        verInfo: true
                                                    })}>Más información</Link> </p>
                                                </div>
                                            )}
                                        </section>
                                    </div>


                                    <div className="bg-dark-400 rounded-md grid grid-cols-1 gap-2 p-2 lg:gap-4 lg:p-4">
                                        <p className="text-gray-400 text-sm font-semibold">VIGENCIA</p>
                                        <section className="rounded-md bg-dark-300 p-4">
                                            <section className="flex justify-between">
                                                <div>
                                                    <p className="text-yellow text-sm">Vigencia</p>
                                                    <p className="text-white text-sm capitalize"> {format(parse(polizaData.fechaVigenciaDe, "yyyy-MM-dd"), "dd MMM yyyy")} - {format(parse(polizaData.fechaVigenciaA, "yyyy-MM-dd"), "dd MMM yyyy")}</p>
                                                </div>
                                                <DateRangeIcon fontSize="large" className="text-gray-400 "></DateRangeIcon>
                                            </section>
                                            <section className="grid mt-4">
                                                <p className="text-yellow text-sm">Forma de Pago</p>
                                                <p className="text-white text-sm capitalize">{polizaData.formaPago}</p>
                                            </section>
                                            <section className="grid mt-4">
                                                <p className="text-yellow text-sm">Moneda</p>
                                                <p className="text-white text-sm capitalize">{moneda.moneda}</p>
                                            </section>
                                        </section>
                                    </div>

                                    {polizaData?.vehiculo && (
                                        <div className="bg-dark-400 rounded-md grid grid-cols-1 gap-2 p-2 lg:gap-4 lg:p-4">
                                            <p className="text-gray-400 text-sm font-semibold">VEHICULO</p>
                                            <section className="rounded-md bg-dark-300 p-4">
                                                <section className="flex justify-between">
                                                    <div>
                                                        <p className="text-yellow text-sm">No. de Serie</p>
                                                        <p className="text-white text-sm capitalize">{vehiculo.noSerie}</p>
                                                    </div>
                                                    <DirectionsCarIcon fontSize="large" className="text-gray-400 "></DirectionsCarIcon>
                                                </section>
                                                <section className="grid mt-4">
                                                    <p className="text-yellow text-sm">Modelo</p>
                                                    <p className="text-white text-sm capitalize">{vehiculo.modelo}</p>
                                                </section>
                                                <section className="grid mt-4">
                                                    <p className="text-yellow text-sm">Placas</p>
                                                    <p className="text-white text-sm capitalize">{vehiculo.placas}</p>
                                                </section>
                                            </section>
                                        </div>
                                    )}

                                    {polizaData.asegurados && polizaData.asegurados.length > 1 && (
                                        <div>
                                            <div className="bg-dark-400 rounded-md grid grid-cols-1 gap-2 p-2 lg:gap-4 lg:p-4">
                                                <p className="text-gray-400 text-sm font-semibold"> ASEGURADOS</p>
                                                {polizaData.asegurados.map((asegurados, idx) => (
                                                    <article key={idx} className="rounded-md bg-dark-300 p-4">
                                                        <section className="grid gap-2">
                                                            <section className="flex justify-between">
                                                                <div>
                                                                    <p className="text-yellow text-sm">Parentesco</p>
                                                                    <p className="text-white text-sm capitalize">{asegurados.parentesco}</p>
                                                                </div>
                                                                <GroupIcon fontSize="large" className="text-gray-400 "></GroupIcon>
                                                            </section>
                                                            <section className="grid mt-4">
                                                                <p className="text-yellow text-sm">Nombre</p>
                                                                <p className="text-white text-sm capitalize">{asegurados.nombres} {asegurados.apPat} {asegurados.apMat}</p>
                                                            </section>
                                                            <section className="grid mt-4">
                                                                <p className="text-yellow text-sm">Nacimiento</p>
                                                                <p className="text-white text-sm capitalize">{asegurados.fecNacimiento}</p>
                                                            </section>
                                                            <section className="grid mt-4">
                                                                <p className="text-yellow text-sm">RFC</p>
                                                                <p className="text-white text-sm capitalize">{asegurados.rfc}</p>
                                                            </section>
                                                        </section>
                                                    </article>
                                                ))}
                                            </div>
                                        </div>
                                    )}

                                    {polizaData?.texto && (
                                        <section className="bg-dark-400 rounded-md grid grid-cols-1 gap-2 p-2 lg:gap-4 lg:p-4">
                                            <div className="bg-dark-300 rounded-md p-4">
                                                <p className="text-gray-400 text-sm font-semibold">BIEN ASEGURADO</p>
                                                <section className="flex justify-between">
                                                    <div>
                                                        <p className="text-yellow text-sm">Detalle</p>
                                                        {/* <p dangerouslySetInnerHTML={{ __html: texto.replace("\n", "<br/>") }} className="text-white text-sm mt-2"></p> */}
                                                        <p className="text-white text-sm mt-2 "><pre className="whitespace-pre-wrap text-justify">{texto}</pre></p>
                                                    </div>
                                                    <HomeIcon fontSize="large" className="text-gray-400 "></HomeIcon>
                                                </section>
                                            </div>
                                        </section>
                                    )}



                                    <div className="bg-dark-400 rounded-md grid grid-cols-1 gap-2 p-2 lg:gap-4 lg:p-4" >
                                        <p className="text-gray-400 text-sm font-semibold">PRIMAS</p>
                                        <section className="rounded-md bg-dark-300 p-4">
                                            <section className="grid gap-2">
                                                <div className="grid grid-cols-5">
                                                    <div className="col-span-2">
                                                        <p className="text-yellow text-sm mb-2">Prima Neta</p>
                                                        <p className="text-yellow text-sm mb-2">Derecho</p>
                                                        <p className="text-yellow text-sm mb-2">Recargo</p>
                                                        <p className="text-yellow text-sm mb-2">I.V.A.</p>
                                                        <p className="text-yellow text-sm">Total</p>
                                                    </div>
                                                    <div className="col-start-3 col-span-2 grid justify-items-end">
                                                        <p className="text-sm mb-2">{numberFormat(polizaData.prima)}</p>
                                                        <p className="text-sm mb-2">{numberFormat(polizaData.derecho)}</p>
                                                        <p className="text-sm mb-2">{numberFormat(polizaData.recargo)}</p>
                                                        <p className="text-sm mb-2">{numberFormat(polizaData.iva)}</p>
                                                        <p className="text-sm">{numberFormat(polizaData.total)}</p>
                                                    </div>
                                                    <div className="grid justify-items-end">
                                                        <AttachMoneyIcon fontSize="large" className="text-gray-400" />
                                                    </div>
                                                </div>
                                            </section>
                                        </section>
                                    </div>

                                    {polizaData.recibos && (
                                        <div className="bg-dark-400 rounded-md grid grid-cols-1 gap-2 p-2 lg:gap-4 lg:p-4">
                                            <p className="text-gray-400 text-sm font-semibold">RECIBOS</p>
                                            {polizaData.recibos && polizaData.recibos.map((recibo, idx) => {
                                                let className = '';
                                                if (recibo.status === "pagado") {
                                                    className = "font-bold uppercase text-blue-200"
                                                } else if (recibo.status === "pendiente") {
                                                    className = "font-bold uppercase text-green-200"
                                                } else if (recibo.status === "cancelado") {
                                                    className = "font-bold uppercase text-red"
                                                } else {
                                                    className = "font-bold uppercase text-red-100"
                                                }

                                                return (
                                                    <div key={idx} id={`recibo-${recibo.id}`} ref={recibo.id === reciboId ? receiptScrollRef : null}>
                                                        <section className="rounded-md bg-dark-300 p-4">
                                                            <section className="grid gap-2">
                                                                <div className="flex justify-between">
                                                                    <p className={className}>{recibo.status}</p>
                                                                    <MonetizationOnIcon className="text-gray-400" fontSize="large" />
                                                                </div>

                                                                {recibo.status === "pagado" && (
                                                                    <>
                                                                        <div className="grid grid-cols-5">
                                                                            <div className="grid gap-2 col-span-2 font-semibold">
                                                                                <p className="text-yellow text-sm">Recibo</p>
                                                                                <p className="text-yellow text-sm">Vigencia</p>
                                                                                <p className="text-yellow text-sm">Vencimiento</p>
                                                                                <p className="text-yellow text-sm">Total</p>
                                                                            </div>
                                                                            <div className="grid gap-2 col-start-3 col-span-3 font-semibold">
                                                                                <p className="text-sm" >{recibo.serie}/{recibo.totalSerie}</p>
                                                                                <p className="text-sm"> {format(parse(recibo.fecVigDe, "yyyy-MM-dd"), "dd MMM yyyy")} - {format(parse(recibo.fecVigA, "yyyy-MM-dd"), "dd MMM yyyy")}</p>
                                                                                <p className="text-sm">{format(parse(recibo.fecVence, "yyyy-MM-dd"), "dd MMM yyyy")}</p>
                                                                                <p className="text-sm">{numberFormat(recibo.total)}</p>
                                                                            </div>
                                                                        </div>

                                                                        <div className="border-b-0 border bg-white mt-2 mb-2"></div>

                                                                        <div className="grid grid-cols-5 ">
                                                                            <div className="grid gap-2 col-span-2 font-semibold">
                                                                                <p className="text-yellow text-sm">Fecha de Pago</p>
                                                                                <p className="text-yellow text-sm">Folio</p>
                                                                                <p className="text-yellow text-sm">Tipo de Cambio</p>
                                                                                <p className="text-yellow text-sm">Observaciones</p>
                                                                            </div>
                                                                            <div className="grid gap-2 col-start-3 col-span-3 font-semibold">
                                                                                <p className="text-sm">{recibo.fecPago ? format(parse(recibo.fecPago, "yyyy-MM-dd"), "dd MMM yyyy") : null}</p>
                                                                                <p className="text-sm">{recibo.folioPago}</p>
                                                                                <p className="text-sm">{numberFormat(recibo.tipoCambio)}</p>
                                                                                <p className="text-sm">-{recibo.observacion}</p>
                                                                            </div>
                                                                        </div>

                                                                        <div className="flex items-center">
                                                                            <Link className="no-underline p-1" to="#" onClick={e => undoReceiptPay(recibo.id, idx)}>
                                                                                <div className="bg-yellow h-10 w-10 text-center flex items-center rounded-md">
                                                                                    <UndoIcon className="text-dark-600 ml-2"></UndoIcon>
                                                                                </div>
                                                                            </Link>
                                                                            <Link className="no-underline p-1" to="#" onClick={e => setOpenBitReciboDialog({ show: true, id: recibo.id })}>
                                                                                <div className="bg-yellow h-10 w-10 text-center flex items-center rounded-md">
                                                                                    <ForumIcon className="text-dark-600 ml-2"></ForumIcon>
                                                                                </div>
                                                                            </Link>
                                                                            <Link className="no-underline p-1" to="#" onClick={e => setOpenAdjuntosRecDialog({ show: true, id: recibo.id })}>
                                                                                <div className="bg-yellow h-10 w-10 text-center flex items-center rounded-md">
                                                                                    <AttachmentIcon className="text-dark-600 ml-2"></AttachmentIcon>
                                                                                </div>
                                                                            </Link>
                                                                        </div>
                                                                    </>
                                                                )}

                                                                {recibo.status === "pendiente" && (
                                                                    <>
                                                                        <div className="grid grid-cols-5">
                                                                            <div className="grid gap-2 col-span-2 font-semibold">
                                                                                <p className="text-yellow text-sm">Recibo</p>
                                                                                <p className="text-yellow text-sm">Vigencia</p>
                                                                                <p className="text-yellow text-sm">Vencimiento</p>
                                                                                <p className="text-yellow text-sm">Total</p>
                                                                            </div>
                                                                            <div className="grid gap-2 col-start-3 col-span-3 font-semibold">
                                                                                <p className="text-sm">{recibo.serie}/{recibo.totalSerie}</p>
                                                                                <p className="text-sm"> {format(parse(recibo.fecVigDe, "yyyy-MM-dd"), "dd MMM yyyy")} - {format(parse(recibo.fecVigA, "yyyy-MM-dd"), "dd MMM yyyy")}</p>
                                                                                <p className="text-sm">{format(parse(recibo.fecVence, "yyyy-MM-dd"), "dd MMM yyyy")}</p>
                                                                                <p className="text-sm">{numberFormat(recibo.total)}</p>
                                                                            </div>
                                                                        </div>

                                                                        <div className="flex items-center">
                                                                            <Link className="no-underline p-1" to="#" onClick={e => setOpenPagoDialog({ show: true, id: recibo.id })}>
                                                                                <div className="bg-yellow h-10 w-10 text-center flex items-center rounded-md">
                                                                                    <AttachMoneyIcon className="text-dark-600 ml-2"></AttachMoneyIcon>
                                                                                </div>
                                                                            </Link>
                                                                            <Link className="no-underline p-1" to="#" onClick={e => setOpenCorreosDialog({ show: true, id: recibo.id })}>
                                                                                <div className="bg-yellow h-10 w-10 text-center flex items-center rounded-md">
                                                                                    <MailIcon className="text-dark-600 ml-2"></MailIcon>
                                                                                </div>
                                                                            </Link>
                                                                            <Link className="no-underline p-1" to="#" onClick={e => setOpenBitReciboDialog({ show: true, id: recibo.id })}>
                                                                                <div className="bg-yellow h-10 w-10 text-center flex items-center rounded-md">
                                                                                    <ForumIcon className="text-dark-600 ml-2"></ForumIcon>
                                                                                </div>
                                                                            </Link>
                                                                            <Link className="no-underline p-1" to="#" onClick={e => setOpenAdjuntosRecDialog({ show: true, id: recibo.id })}>
                                                                                <div className="bg-yellow h-10 w-10 text-center flex items-center rounded-md">
                                                                                    <AttachmentIcon className="text-dark-600 ml-2"></AttachmentIcon>
                                                                                </div>
                                                                            </Link>
                                                                            <Link className="no-underline p-1" to="#" onClick={e => setOpenCancelarReciboDialog({ show: true, id: recibo.id, title: "Cerrar Recibo" })}>
                                                                                <div className="bg-yellow h-10 w-10 text-center flex items-center rounded-md">
                                                                                    <ClearIcon className="text-red ml-2"></ClearIcon>
                                                                                </div>
                                                                            </Link>
                                                                        </div>
                                                                    </>
                                                                )}

                                                                {recibo.status === "vencido" && (
                                                                    <>
                                                                        <div className="border-b-0 border bg-white mt-2 mb-2"></div>

                                                                        <div className="grid grid-cols-5">
                                                                            <div className="grid gap-2 col-span-2 font-semibold">
                                                                                <p className="text-yellow text-sm">Recibo</p>
                                                                                <p className="text-yellow text-sm">Vigencia</p>
                                                                                <p className="text-yellow text-sm">Vencimiento</p>
                                                                                <p className="text-yellow text-sm">Total</p>
                                                                            </div>
                                                                            <div className="grid gap-2 col-start-3 col-span-3 font-semibold">
                                                                                <p className="text-sm">{recibo.serie}/{recibo.totalSerie}</p>
                                                                                <p className="text-sm"> {format(parse(recibo.fecVigDe, "yyyy-MM-dd"), "dd MMM yyyy")} - {format(parse(recibo.fecVigA, "yyyy-MM-dd"), "dd MMM yyyy")}</p>
                                                                                <p className="text-sm">{format(parse(recibo.fecVence, "yyyy-MM-dd"), "dd MMM yyyy")}</p>
                                                                                <p className="text-sm">{numberFormat(recibo.total)}</p>
                                                                            </div>
                                                                        </div>

                                                                        <div className="flex items-center">
                                                                            <Link className="no-underline p-1" to="#" onClick={e => setOpenPagoDialog({ show: true, id: recibo.id })}>
                                                                                <div className="bg-yellow h-10 w-10 text-center flex items-center rounded-md">
                                                                                    <AttachMoneyIcon className="text-dark-600 ml-2"></AttachMoneyIcon>
                                                                                </div>
                                                                            </Link>
                                                                            <Link className="no-underline p-1" to="#" onClick={e => setOpenCorreosDialog({ show: true, id: recibo.id })}>
                                                                                <div className="bg-yellow h-10 w-10 text-center flex items-center rounded-md">
                                                                                    <MailIcon className="text-dark-600 ml-2"></MailIcon>
                                                                                </div>
                                                                            </Link>
                                                                            <Link className="no-underline p-1" to="#" onClick={e => setOpenBitReciboDialog({ show: true, id: recibo.id })}>
                                                                                <div className="bg-yellow h-10 w-10 text-center flex items-center rounded-md">
                                                                                    <ForumIcon className="text-dark-600 ml-2"></ForumIcon>
                                                                                </div>
                                                                            </Link>
                                                                            <Link className="no-underline p-1" to="#" onClick={e => setOpenAdjuntosRecDialog({ show: true, id: recibo.id })}>
                                                                                <div className="bg-yellow h-10 w-10 text-center flex items-center rounded-md">
                                                                                    <AttachmentIcon className="text-dark-600 ml-2"></AttachmentIcon>
                                                                                </div>
                                                                            </Link>
                                                                            <Link className="no-underline p-1" to="#" onClick={e => setOpenCancelarReciboDialog({ show: true, id: recibo.id, title: "Cerrar Recibo" })}>
                                                                                <div className="bg-yellow h-10 w-10 text-center flex items-center rounded-md">
                                                                                    <ClearIcon className="text-red ml-2"></ClearIcon>
                                                                                </div>
                                                                            </Link>
                                                                        </div>
                                                                    </>
                                                                )}

                                                                {recibo.status === "cancelado" && (
                                                                    <>
                                                                        <div className="grid grid-cols-5">
                                                                            <div className="grid gap-2 col-span-2 font-semibold">
                                                                                <p className="text-yellow text-sm">Recibo</p>
                                                                                <p className="text-yellow text-sm">Vigencia</p>
                                                                                <p className="text-yellow text-sm">Vencimiento</p>
                                                                                <p className="text-yellow text-sm">Total</p>
                                                                            </div>
                                                                            <div className="grid gap-2 col-start-3 col-span-3 font-semibold">
                                                                                <p className="text-sm">{recibo.serie}/{recibo.totalSerie}</p>
                                                                                <p className="text-sm"> {format(parse(recibo.fecVigDe, "yyyy-MM-dd"), "dd MMM yyyy")} - {format(parse(recibo.fecVigA, "yyyy-MM-dd"), "dd MMM yyyy")}</p>
                                                                                <p className="text-sm">{format(parse(recibo.fecVence, "yyyy-MM-dd"), "dd MMM yyyy")}</p>
                                                                                <p className="text-sm">{numberFormat(recibo.total)}</p>
                                                                            </div>
                                                                        </div>

                                                                        <div className="border-b-0 border bg-white mt-2 mb-2"></div>

                                                                        <div className="grid grid-cols-5 ">
                                                                            <div className="grid gap-2 col-span-2 font-semibold">
                                                                                <p className="text-yellow text-sm">Fecha</p>
                                                                                <p className="text-yellow text-sm">Razón</p>
                                                                                <p className="text-yellow text-sm">Observaciones</p>
                                                                            </div>
                                                                            <div className="grid gap-2 col-start-3 col-span-3 font-semibold">
                                                                                <p className="text-sm">{format(parse(recibo.fecVigA, "yyyy-MM-dd"), "dd MMM yyyy")}</p>
                                                                                <p className="text-sm"> - </p>
                                                                                <p className="text-sm"> {recibo.observacion} </p>
                                                                            </div>
                                                                        </div>

                                                                        <div className="flex items-center">
                                                                            <Link className="no-underline p-1" to="#" onClick={e => setOpenCorreosDialog({ show: true, id: recibo.id })}>
                                                                                <div className="bg-yellow h-10 w-10 text-center flex items-center rounded-md">
                                                                                    <MailIcon className="text-dark-600 ml-2"></MailIcon>
                                                                                </div>
                                                                            </Link>
                                                                            <Link className="no-underline p-1" to="#" onClick={e => setOpenBitReciboDialog({ show: true, id: recibo.id })}>
                                                                                <div className="bg-yellow h-10 w-10 text-center flex items-center rounded-md">
                                                                                    <ForumIcon className="text-dark-600 ml-2"></ForumIcon>
                                                                                </div>
                                                                            </Link>
                                                                            <Link className="no-underline p-1" to="#" onClick={e => setOpenAdjuntosRecDialog({ show: true, id: recibo.id })}>
                                                                                <div className="bg-yellow h-10 w-10 text-center flex items-center rounded-md">
                                                                                    <AttachmentIcon className="text-dark-600 ml-2"></AttachmentIcon>
                                                                                </div>
                                                                            </Link>
                                                                            <Link className="no-underline p-1" to={`#`} onClick={e => setOpenCancelarReciboDialog({ show: true, id: recibo.id, title: "Reactivar Recibo" })}>
                                                                                <div className="bg-yellow h-10 w-10 text-center flex items-center rounded-md">
                                                                                    <UndoIcon className="text-red ml-2"></UndoIcon>
                                                                                </div>
                                                                            </Link>
                                                                        </div>
                                                                    </>
                                                                )}
                                                            </section>
                                                        </section>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    )}
                                    {polizaData.verInfo === true && (
                                        <Dialog
                                            open={polizaData.verInfo === true}
                                            onClose={e => setPolizaData({
                                                ...polizaData,
                                                verInfo: false
                                            })}
                                            aria-labelledby="alert-dialog-title"
                                            aria-describedby="alert-dialog-description"
                                        >
                                            <DialogContent style={{ width: "330px", maxWidth: "100%" }}>
                                                <DialogContentText className="text-gray-400" id="alert-dialog-description">
                                                    <p>
                                                        La póliza o cliente se integró desde la <span className="text-yellow font-bold">sincronización automática</span>, este proceso utiliza el PDF original de la póliza para obtener los datos de la póliza o el cliente, aunque este proceso se perfecciona día con día es <span className="text-yellow font-bold">indispensable</span> sea validado por el agente de forma puntual para así garantizar la <span className="font-bold">confiabilidad</span> de la información, por esto recomendamos revisar los siguientes puntos:
                                                    </p>
                                                    <p className="pl-4">
                                                        <ul className="py-6 list-disc">
                                                            <li>Datos del Cliente</li>
                                                            <li>Primas de la póliza</li>
                                                            <li>Vigencias de la póliza</li>
                                                            <li>No. de Recibos</li>
                                                            <li>Primas de Recibos</li>
                                                            <li>Vigencias de Recibos</li>
                                                        </ul>
                                                    </p>
                                                    <p>
                                                        También te recomendamos completar el correo y teléfono de tu cliente para los procesos de comunicación operativos
                                                    </p>
                                                </DialogContentText>
                                            </DialogContent>
                                            <DialogActions className="justify-center pb-6">
                                                <Button className="btn-yellow font-bold w-32" onClick={e => setPolizaData({
                                                    ...polizaData,
                                                    verInfo: false
                                                })}>ACEPTAR</Button>
                                            </DialogActions>
                                        </Dialog>
                                    )}
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </AdminTemplate >
        )
    }

    return render()
}

export default ConsultaPoliza
import { Button } from '@material-ui/core'
import React from 'react'
import { Link } from 'react-router-dom'
import Loading from '../components/Loading'
import AdminTemplate from '../template/AdminTemplate'
import Toolbar from '../components/Toolbar'


const SuscripcionVencida = (props) => {

    const openChat = () => {
        const tawk = window.Tawk_API || {}
        tawk.maximize()
    }

    const render = () => {
        return (
            <AdminTemplate loading={false}>
                <Toolbar title="Planes" />
                <div>
                    <div className="main">
                        <div className="layout-compact">
                            <div className="grid grid-cols-1 gap-4 lg:gap-8">
                                <div className="text-center">
                                    <img className="inline-block relative w-48 lg:w-96 " src="/icons/payment-error-yellow.svg" alt="" />
                                </div>
                                <p className="text-center text-yellow text-lg">Tu suscripción se ha vencido</p>
                                <p className="text-center">Para acceder nuevamente es necesario contratar un nuevo plan</p>

                                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 lg:gap-8">
                                    <Link to={{ pathname: "/planes" }} className="btn btn-lg btn-yellow text-center block" >Contratar plan</Link>
                                    <Button className="btn btn-block btn-lg bg-dark-200 " onClick={openChat}>Ayuda</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </AdminTemplate>

        )
    }

    return render()
}

export default SuscripcionVencida
import React, { useContext, useEffect, useState } from "react";
import { TextField, InputAdornment, Button, IconButton, Divider } from '@material-ui/core';
import { useHistory } from "react-router-dom";
import { maxFieldLength, onlyNumbers } from "../utils/FormUtils";
import Loading from "../components/Loading";
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import AdminTemplate from "../template/AdminTemplate";
import APIInvoke from "../utils/APIInvoke";
import Alert from '../components/Alert';
import Toolbar from '../components/Toolbar'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import UserContext from "../state/UserContext";
import PersonAddAltRoundedIcon from '@mui/icons-material/PersonAddAltRounded';
import ClearSharpIcon from '@mui/icons-material/ClearSharp';


const Usuario = (props) => {

    const [loading, setLoading] = useState(true)

    const [usage, setUsage] = useState(null)
    const [aditionalUsers, setAditionalUsers] = useState([])

    const [state, setState] = useState({
        showNewUserDialog: false,
        message: null,
        messageType: null,
        nombres: "",
        apPat: "",
        apMat: "",
        correo: ""
    })

    const [deleteUserState, setDeleteUserState] = useState({
        showDeleteUserDialog: false,
        id: null
    })

    useEffect(async () => {
        try {
            setLoading(true)
            const usageResponse = await (await APIInvoke.invokeGET(`/suscripciones/usages?tipoItem=USUARIO`)).json()
            setUsage(usageResponse.body)

            const aditionalUserResponse = await (await APIInvoke.invokeGET(`/usuarios/adicionales`)).json()
            setAditionalUsers(aditionalUserResponse.body)
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    }, [])

    const onChange = (e) => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        })
    }

    const saveUser = async (e) => {
        try {
            setLoading(true)

            const body = {
                nombres: state.nombres,
                apPat: state.apPat,
                apMat: state.apMat,
                correo: state.correo
            }
            const saveResponse = await (await APIInvoke.invokePOST(`/usuarios`, body)).json()
            setState({
                ...state,
                showNewUserDialog: !saveResponse.ok,
                message: saveResponse.message,
                messageType: saveResponse.ok ? "success" : "error",
                nombres: saveResponse.ok ? "" : state.nombres,
                apPat: saveResponse.ok ? "" : state.apPat,
                apMat: saveResponse.ok ? "" : state.apMat,
                correo: saveResponse.ok ? "" : state.correo
            })

            if (saveResponse.ok) {
                setAditionalUsers([
                    ...aditionalUsers,
                    saveResponse.body
                ])
            }

        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    const deleteUser = async () => {
        try {
            setLoading(true)
            const response = await (await APIInvoke.invokeDELETE(`/usuarios/${deleteUserState.id}`)).json()
            if (response.ok) {
                setAditionalUsers([
                    ...aditionalUsers.filter(x => x.id !== deleteUserState.id)
                ])
                setDeleteUserState({
                    ...deleteUserState,
                    showDeleteUserDialog: false
                })
            }
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    const render = () => {
        if (loading) return <AdminTemplate loading={true} />

        return (
            <AdminTemplate>
                <Dialog
                    open={state.showNewUserDialog}
                    onClose={e => setState({ ...state, showNewUserDialog: false })}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    className=""
                >
                    <form onSubmit={saveUser}>
                        <div className="border border-dark-300 rounded-md p-2 ">
                            <DialogTitle id="alert-dialog-title">Cambiar contraseña</DialogTitle>
                            <DialogContent>
                                <div className="grid grid-cols-1 gap-4 mt-4">
                                    <TextField
                                        label="Correo"
                                        value={state.correo}
                                        type='email'
                                        variant="outlined"
                                        onInput={(e) => maxFieldLength(e, 100)}
                                        name="correo"
                                        onChange={onChange}
                                        required
                                    />
                                    <TextField
                                        label="Nombre"
                                        value={state.nombres}
                                        type='text'
                                        variant="outlined"
                                        onInput={(e) => maxFieldLength(e, 80)}
                                        name="nombres"
                                        onChange={onChange}
                                        required
                                    />

                                    <TextField
                                        label="Apellido Paterno"
                                        value={state.apPat}
                                        type="text"
                                        variant="outlined"
                                        onInput={(e) => maxFieldLength(e, 60)}
                                        name="apPat"
                                        onChange={onChange}
                                        required
                                    />
                                    <TextField
                                        label="Apellido Materno"
                                        value={state.apMat}
                                        type="text"
                                        variant="outlined"
                                        onInput={(e) => maxFieldLength(e, 60)}
                                        required
                                        name="apMat"
                                        onChange={onChange}
                                    />
                                    {state.message && <Alert type={state.messageType} message={state.message} />}
                                </div>
                            </DialogContent>
                            <DialogActions>
                                <Button className="mr-4" onClick={e => setState({ ...state, showNewUserDialog: false })}>CANCELAR</Button>
                                <Button className="bg-yellow text-dark-600" type="submit" autoFocus>ACEPTAR</Button>
                            </DialogActions>
                        </div>
                    </form>

                </Dialog>



                <Dialog
                    open={deleteUserState.showDeleteUserDialog}
                    onClose={e => setDeleteUserState({ ...deleteUserState, showDeleteUserDialog: false })}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    className=""
                >
                    <div className="border border-dark-300 rounded-md">
                        <DialogTitle id="alert-dialog-title">Alerta</DialogTitle>
                        <DialogContent>
                            <p>¿Desea eliminar al usuario?</p>
                        </DialogContent>
                        <DialogActions>
                            <Button className="mr-4" onClick={e => setDeleteUserState({ ...deleteUserState, showDeleteUserDialog: false })}>CANCELAR</Button>
                            <Button className="bg-red text-white" onClick={deleteUser} >ACEPTAR</Button>
                        </DialogActions>
                    </div>
                </Dialog>

                <div>
                    <Loading show={loading} />
                    <Toolbar title="Usuarios" backUrl="/configuracion"></Toolbar>
                    <div className="main">
                        <div className="layout-compact grid grid-cols-1 gap-4">



                            {state.message && <Alert type={state.messageType} message={state.message} />}

                            <div className="bg-dark-400 rounded-md p-2 grid grid-cols-1 gap-2">
                                {aditionalUsers.map(user => (
                                    <div key={user.id} className="bg-dark-300 p-2 rounded-md">
                                        <div className="flex gap-4">
                                            <div>
                                                <IconButton onClick={e => setDeleteUserState({ ...deleteUserState, showDeleteUserDialog: true, id: user.id })} color="primary" className="" size="small">
                                                    <ClearSharpIcon className="text-yellow" />
                                                </IconButton>
                                            </div>
                                            <div className="grid grid-cols-1 md:grid-cols-2 gap-2 flex-1 items-center">
                                                <p className="text-sm">{`${user.nombres} ${user.apPat} ${user.apMat}`.trim()}</p>
                                                <p className="text-sm">{`${user.correo}`}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))}

                                {aditionalUsers.length === 0 && <p className="text-center p-2">No hay ningún usuario registrado</p>}
                            </div>

                            <div className="grid grid-cols-1 gap-4">
                                <div className="text-right">
                                    <Button className="btn btn-yellow" onClick={e => setState({ ...state, showNewUserDialog: true })} ><PersonAddAltRoundedIcon className="" />Agregar Usuario</Button>
                                </div>
                            </div>

                            <div className="">
                                {usage.quantity === 1 && <p className="text-yellow text-center">{`Tu suscripción no cuenta con usuarios adicionales.`}</p>}
                                {usage.availavility === 0 && <p className="text-yellow text-center">{`Tu suscripción ha llegado al límite de usuarios disponibles`}</p>}
                                {usage.availavility > 1 && <p className="text-yellow text-center">{`Te quedan ${usage.availavility} usuario adicional`}</p>}
                            </div>
                        </div>
                    </div>
                </div>
            </AdminTemplate>
        );
    };

    return render();
};

export default Usuario;

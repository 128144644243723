import React, { useState, useContext } from 'react';
import Navbar from '../components/Navbar'
import { TextField, InputAdornment, Button, IconButton } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import APIInvoke from '../utils/APIInvoke';
import { Link, useHistory, useLocation } from "react-router-dom";
import UserContext from '../state/UserContext'
import { maxFieldLength } from '../utils/FormUtils';
import Loading from "../components/Loading";


function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const RegistroContra = (props) => {

    const context = useContext(UserContext)
    const query = useQuery()
    const history = useHistory()

    const [loading, setLoading] = useState(false)
    const [state, setState] = useState({
        correo: query.get("email"),
        codigo: query.get("codigo") || "",
        password: "",
        repeatedPassword: "",
        showPassword: false
    })

    const onChange = (e) => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        })
    }

    const [errorMessage, setErrorMessage] = useState('');


    const onShowPassword = () => {
        setState({
            ...state,
            showPassword: !state.showPassword
        })
    }

    const postRequest = async (e) => {
        try {
            setLoading(true)
            e.preventDefault()
            const request = {
                correo: state.correo,
                codigo: state.codigo,
                password: state.password
            }

            const response = await (await APIInvoke.invokePOST(`/usuarios/activate`, request)).json();
            if (response.ok === true) {
                window.localStorage.setItem('token', response.body.token);
                context.setUser({
                    ...response.body.usuario,
                    rol: response.body.role,
                    suscripcion: response.body.suscripcion
                });

                if (response.body.usuario.principal) {
                    history.push(`/comofunciona`)
                } else {
                    history.push(`/`)
                }
            } else {
                setErrorMessage(response.message);
            }
        } catch (e) {
            console.log("Error al asignar contraña", e)
        } finally {
            setLoading(false)
        }
    }

    const render = () => {
        return (
            <div>
                <Loading show={loading} />
                <Navbar title="Registro" showBackButton={true} />
                <div className="mt-8 container m-auto grid grid-cols-1 lg:grid-cols-3 lg:mt-32">
                    <div className="lg:col-start-2 px-8 lg:px-0">
                        <p className="text-gray-400 mb-4 text-center">{state.correo}</p>
                        <p className="text-gray-400 mb-4">Revisa tu correo, recibiste un código para activar tu cuenta</p>
                        <form className="grid gap-4" onSubmit={postRequest}>
                            <TextField
                                label="Código"
                                type="text"
                                variant="outlined"
                                name="codigo"
                                value={state.codigo}
                                onChange={onChange}
                                onInput={(e) => maxFieldLength(e, 6)}
                                inputProps={
                                    { minLength: 6 }
                                }
                                required
                            />
                            <p className="text-gray-400">Por último asigna una contraseña para tu cuenta</p>
                            <TextField
                                label="Contraseña"
                                type={state.showPassword ? 'text' : 'password'}
                                variant="outlined"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={onShowPassword}  >
                                                {state.showPassword ? <VisibilityIcon className="text-gray-400" /> : <VisibilityOffIcon className="text-gray-400" />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                onInput={(e) => maxFieldLength(e, 50)}
                                name="password"
                                onChange={onChange}
                                required
                            />
                            <TextField
                                label="Repite la Contraseña"
                                type={state.showPassword ? 'text' : 'password'}
                                variant="outlined"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={onShowPassword} >
                                                {state.showPassword ? <VisibilityIcon className="text-gray-400" /> : <VisibilityOffIcon className="text-gray-400" />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                onInput={(e) => maxFieldLength(e, 50)}
                                required
                                name="repeatedPassword"
                                onChange={onChange}
                            />
                            {state.password !== state.repeatedPassword ? <p className="text-center text-red text-sm">Las contraseñas deben coincidir</p> : ''}
                            <p className="text-center text-red text-small">{errorMessage}</p>
                            <Button
                                type="submit"
                                className="btn btn-yellow"
                                color="primary"
                                disabled={state.codigo === '' || state.password === '' || state.repeatedPassword === '' || state.password !== state.repeatedPassword}
                            >Asignar Contraseña</Button>
                        </form>
                    </div>
                </div>
            </div>
        )
    }

    return render()

}

export default RegistroContra
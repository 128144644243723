const config = {
    api: {
        //baseUrl: "https://dalala-gateway-cko2rscy.uc.gateway.dev"             //QA
        baseUrl: "https://dalala-gateway-104z13xo.uc.gateway.dev"               //PROD
    },
    uploader: {
        //baseUrl: "https://dalala-storage-gcp-api-ldawljzoxq-uc.a.run.app",    // QA
        baseUrl: "https://dalala-storage-gcp-api-cuittv4wiq-uc.a.run.app",      //PROD
        token: "xxx"
    },
    bucket: {
        //name: "dalala-app-qa"
        name: "dalala-app"
    },
    props: {
        taza: 0.16
    },
    contacts: {
        emailContacto: "hola@dalala.app",
        emailSoporte: "hola@dalala.app"
    }
}


module.exports = config

import React, { useEffect, useState, useCallback, useRef } from 'react'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/core';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import AdminTemplate from '../template/AdminTemplate'
import APIInvoke from '../utils/APIInvoke';
import Toolbar from '../components/Toolbar';
import FavoriteIcon from '@mui/icons-material/Favorite';
import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import "@egjs/react-flicking/dist/flicking.css";
import "@egjs/react-flicking/dist/flicking-inline.css";
import debounce from "lodash.debounce";
import GaugeChart from 'react-gauge-chart'
import { tierNumber } from '../utils/NumberUtils'
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import BarChartIcon from '@mui/icons-material/BarChart';

import { Link } from 'react-router-dom';
import { format } from '../utils/DateUtils';


const Resumen = (props) => {

    const [loading, setLoading] = useState(true)
    const [state, setState] = useState({
        clientes: 0,
        polizas: 0,
        cxc45: 0,
        cxc30: 0,
        cxc15: 0,
        cxc5: 0,
        cxc0: 0,
        ren45: 0,
        ren30: 0,
        ren15: 0,
        ren5: 0,
        ren0: 0,
        ppm: 0,
        paa: 0,
        em: 0,
        eaa: 0,
        metaMensual: 0,
        metaAnual: 0
    })

    const [filters, setFilters] = useState({
        types: ['auto', 'salud', 'vida', 'diversos'],
        range: 0
    })

    useEffect(async () => {
        try {
            setLoading(true)
            let rawResponse = await APIInvoke.invokeGET(`/reportes/resumen-cartera${`?tipo=${filters.types.join(',')}`}`)
            let response = await rawResponse.json()
            setState(response.body)
        } catch (e) {
            console.log(e)
        } finally {
            setLoading(false)
        }
    }, [filters.types])

    const onTypeChange = (e, newType) => {
        console.log("onTypeChange", newType)
        if (filters.types.length === 4 && newType.length === 3) {
            console.log("select one")
            const values = ['auto', 'salud', 'vida', 'diversos']

            setFilters({
                ...filters,
                types: values.filter(x => !newType.includes(x))
            })
        } else if (newType.length) {
            console.log("select more")
            setFilters({
                ...filters,
                types: newType
            })
        }

    }

    const calculePercent = (amount, total) => {
        amount = amount <= 0 ? 1 : amount
        total = total <= 0 ? 1 : total

        const percent = ((amount * 100) / total) / 100

        return percent > 1 ? 1 : percent
    }

    const render = () => {

        if (loading) return <AdminTemplate loading={true} />

        return (
            <AdminTemplate loading={loading}>
                <Toolbar title="Resumen de Cartera" >
                    <div className="layout py-2">
                        <div className="text-left">
                            <div className="grid grid-4 grid-cols-1">
                                <ToggleButtonGroup value={filters.types} onChange={onTypeChange} aria-label="device" className="flex justify-items-center">
                                    <ToggleButton value="vida" aria-label="Vida" className="pill pill-border border mr-1 lg:mr-4 md:mr-4 sm:mr-4 xs:mr-2 rounded-md w-20">
                                        <div className="grid grid-cols-1 justify-items-center gap-1 text-center p-2">
                                            <PersonIcon fontSize="large" />
                                            <p className="text-inherit">Vida</p>
                                        </div>
                                    </ToggleButton>
                                    <ToggleButton value="auto" aria-label="Autos" className="pill pill-border border mr-1 lg:mr-4 md:mr-4 sm:mr-4 rounded-md w-20">
                                        <div>
                                            <DriveEtaIcon fontSize="large" />
                                            Autos
                                        </div>
                                    </ToggleButton>
                                    <ToggleButton value="salud" aria-label="Salud" className="pill pill-border border mr-1 lg:mr-4 md:mr-4 sm:mr-4 rounded-md w-20">
                                        <div>
                                            <FavoriteIcon fontSize="large" />
                                            Salud
                                        </div>
                                    </ToggleButton>
                                    <ToggleButton value="diversos" aria-label="Daños" className="pill pill-border border mr-1 lg:mr-4 md:mr-4  sm:mr-4 rounded-md w-20">
                                        <div>
                                            <HomeIcon fontSize="large" />
                                            Daños
                                        </div>
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </div>
                        </div>
                    </div>
                </Toolbar>

                <div className="main center">
                    <div className="layout lg:layout-compact">
                        {/* 
                        <div className="pb-8">
                            <img src="/banners/home-banner.png" alt="dalala" className="block w-full rounded-md border-2 border-yellow-200"/>
                        </div>
                        */}
                        <div className="grid grid-cols-2 gap-4">
                            <Link className="bg-dark-300 rounded-md flex flex-col p-6 no-underline" to="/clientes" >
                                <p className="text-center mb-4">Clientes</p>
                                <p className="text-center text-5xl">{state.clientes}</p>
                            </Link>
                            <Link className="bg-dark-300 rounded-md flex flex-col p-6 no-underline" to="/polizas" >
                                <p className="text-center mb-4">Pólizas Vigentes</p>
                                <p className="text-center text-5xl">{state.polizas}</p>
                            </Link>

                            <div className="bg-dark-300 rounded-md p-4 col-span-2 md:col-span-1">
                                <div className="grid grid-cols-1 gap-4">
                                    <div className="text-center mb-4">
                                        <Link to={{ pathname: "/cxc", state: { backUrl: "/resumen" } }} className="btn btn-yellow py-2 lg:py-4 no-underline btn-block"><MonetizationOnIcon fontSize="large" className="mr-2 text-center" />Recibos x Cobrar</Link>
                                    </div>
                                    <div className="bg-gradient-to-r from-red via-yellow to-blue block rounded-md " style={{ padding: "2px" }}>
                                        <div className="grid grid-cols-5 rounded-md bg-dark-600 p-2" >
                                            <p className="text-xl text-gray-800 text-center">0</p>
                                            <p className="text-xl text-gray-800 text-center">5</p>
                                            <p className="text-xl text-gray-800 text-center">15</p>
                                            <p className="text-xl text-gray-800 text-center">30</p>
                                            <p className="text-xl text-gray-800 text-center">45</p>
                                        </div>
                                    </div>
                                    <div className="grid grid-cols-5 rounded-md bg-dark-600 p-2">
                                        <p className="text-xl font-bold text-center">{state.cxc0}</p>
                                        <p className="text-xl font-bold text-center">{state.cxc5}</p>
                                        <p className="text-xl font-bold text-center">{state.cxc15}</p>
                                        <p className="text-xl font-bold text-center">{state.cxc30}</p>
                                        <p className="text-xl font-bold text-center">{state.cxc45}</p>
                                    </div>
                                </div>
                            </div>

                            <div className="bg-dark-300 rounded-md p-4 col-span-2 md:col-span-1">
                                <div className="grid grid-cols-1 gap-4">
                                    <div className="text-center mb-4">
                                        <Link to={{ pathname: "/renovaciones", state: { backUrl: "/resumen" } }} className="btn btn-yellow btn-block py-2 lg:py-4 no-underline"><AutorenewIcon fontSize="large" className="mr-2 " />Pólizas x Renovar</Link>
                                    </div>

                                    <div className="bg-gradient-to-r from-red via-yellow to-blue block rounded-md " style={{ padding: "2px" }}>
                                        <div className="grid grid-cols-5 rounded-md bg-dark-600 p-2" >
                                            <p className="text-xl text-gray-800 text-center">0</p>
                                            <p className="text-xl text-gray-800 text-center">5</p>
                                            <p className="text-xl text-gray-800 text-center">15</p>
                                            <p className="text-xl text-gray-800 text-center">30</p>
                                            <p className="text-xl text-gray-800 text-center">45</p>
                                        </div>
                                    </div>
                                    <div className="grid grid-cols-5 rounded-md bg-dark-600 p-2">
                                        <p className="text-xl font-bold text-center">{state.ren0}</p>
                                        <p className="text-xl font-bold text-center">{state.ren5}</p>
                                        <p className="text-xl font-bold text-center">{state.ren15}</p>
                                        <p className="text-xl font-bold text-center">{state.ren30}</p>
                                        <p className="text-xl font-bold text-center">{state.ren45}</p>
                                    </div>
                                </div>
                            </div>

                            <div className="bg-dark-300 rounded-md flex flex-col p-6 col-span-2 lg:col-span-1">
                                <p className="text-center mb-4">Primas Pagadas {format(new Date(), "MMMM")}</p>
                                <GaugeChart id="gauge-chart5"
                                    needleColor="#999"
                                    needleBaseColor="#999"
                                    nrOfLevels={420}
                                    arcsLength={[0.3333, 0.3333, 0.3333]}
                                    colors={['#EA4228', '#F5CD19', '#5BE12C']}
                                    formatTextValue={t => `${tierNumber(state.ppm)}`}
                                    percent={calculePercent(state.ppm, state.metaMensual)}
                                    arcPadding={0.05}
                                />
                                <p className="text-center text-yellow">Meta: {tierNumber(state.metaMensual)}</p>
                            </div>

                            <div className="bg-dark-300 rounded-md flex flex-col p-6 col-span-2 lg:col-span-1">
                                <p className="text-center mb-4">Pagos Acumulados {format(new Date(), "yyyy")}</p>
                                <GaugeChart id="gauge-chart5"
                                    needleColor="#999"
                                    needleBaseColor="#999"
                                    nrOfLevels={420}
                                    arcsLength={[0.3333, 0.3333, 0.3333]}
                                    colors={['#EA4228', '#F5CD19', '#5BE12C']}
                                    formatTextValue={t => `${tierNumber(state.paa)}`}
                                    percent={calculePercent(state.paa, state.metaAnual)}
                                    arcPadding={0.05}
                                />
                                <p className="text-center text-yellow">Meta: {tierNumber(state.metaAnual)}</p>
                            </div>

                            <div className="bg-dark-300 rounded-md flex flex-col p-6 col-span-2 lg:col-span-1">
                                <p className="text-center mb-4">Emisión {format(new Date(), "MMMM")}</p>
                                <GaugeChart id="gauge-chart5"
                                    needleColor="#999"
                                    needleBaseColor="#999"
                                    nrOfLevels={420}
                                    arcsLength={[0.3333, 0.3333, 0.3333]}
                                    colors={['#EA4228', '#F5CD19', '#5BE12C']}
                                    formatTextValue={t => `${tierNumber(state.em)}`}
                                    percent={calculePercent(state.em, state.metaMensual)}
                                    arcPadding={0.05}
                                />
                                <p className="text-center text-yellow">Meta: {tierNumber(state.metaMensual)}</p>
                            </div>

                            <div className="bg-dark-300 rounded-md flex flex-col p-6 col-span-2 lg:col-span-1">
                                <p className="text-center mb-4">Emisión Acumulada {format(new Date(), "yyyy")}</p>
                                <GaugeChart id="gauge-chart5"
                                    needleColor="#999"
                                    needleBaseColor="#999"
                                    nrOfLevels={420}
                                    arcsLength={[0.3333, 0.3333, 0.3333]}
                                    colors={['#EA4228', '#F5CD19', '#5BE12C']}
                                    formatTextValue={t => `${tierNumber(state.eaa)}`}
                                    percent={calculePercent(state.eaa, state.metaAnual)}
                                    arcPadding={0.05}
                                />
                                <p className="text-center text-yellow">Meta: {tierNumber(state.metaAnual)}</p>
                            </div>
                        </div>

                        <div className="col-span-2 pt-8">
                            <div className="grid grid-cols-1 gap-8">
                                <div className="text-center">
                                    <Link to={{ pathname: "/metas", state: { backUrl: "/resumen" } }} className="no-underline text-white transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 hover:text-yellow duration-300 text-sm"> <BarChartIcon fontSize="small" className="mr-1" />Configurar Metas</Link>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </AdminTemplate>
        )
    }
    return render()
}

export default Resumen
import React, { useState, useEffect } from 'react'
import Button from '@material-ui/core/Button';
import { TextField } from '@material-ui/core';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { onlyNumbers } from "../utils/FormUtils";

const Pagination = ({ backLabel, nextLabel, pages, page, totalItems, onPageChange }) => {

    const [currentPage, setCurrentPage] = useState(page)

    useEffect(() => {
        if (page !== currentPage) {
            setCurrentPage(page)
        }
    }, [page])

    const onChange = (e) => {
        let newPage = parseInt(e.target.value)

        if (isNaN(newPage)) {
            newPage = ""
            setCurrentPage(newPage)
            return
        }

        if (newPage < 1) newPage = 1
        if (newPage > pages) newPage = pages

        setCurrentPage(newPage)
    }

    const onChangePage = (newPage) => {
        if (isNaN(newPage)) {
            newPage = ""
            setCurrentPage(newPage)
            return
        }
        if (newPage < 1) newPage = 1
        if (newPage > pages) newPage = pages

        setCurrentPage(newPage)
        onPageChange(newPage)
    }

    const render = () => {
        return (
            <div className="flex items-center justify-between">
                <div>
                    <Button
                        size="small"
                        className="btn btn-yellow p-2 font-bold min-w-0 w-10 lg:min-w-min"
                        onClick={e => onChangePage(currentPage - 1)}
                        disabled={currentPage === 1}
                    >
                        <ArrowBackIcon /><span className="hidden lg:inline-block text-inherit ml-2" >{backLabel}</span>
                    </Button>
                </div>
                <div className="flex flex-1 flex-grow items-center justify-center">
                    <TextField
                        type="text"
                        variant="outlined"
                        value={currentPage}
                        onInput={(e) => onlyNumbers(e, 3)}
                        onKeyPress={e => { if (e.key == "Enter") onPageChange(currentPage) }}
                        onChange={onChange}
                        className="mr-1 w-12 text-center"
                        size="small"
                        required
                    />
                    <span className="text-sm">de {pages}</span>
                    <span className="mx-1 text-sm">•</span>
                    <span className="text-sm">{totalItems} Registros</span>

                </div>
                <div>
                    <Button
                        size="small"
                        className="btn btn-yellow p-2 font-bold min-w-0 w-10 lg:min-w-min"
                        onClick={e => onChangePage(currentPage + 1)}
                        disabled={currentPage === pages}
                    >
                        <span className="hidden lg:inline-block text-inherit mr-2">{nextLabel}</span><ArrowForwardIcon />
                    </Button>
                </div>
            </div>
        )
    }

    return render()
}

Pagination.defaultProps = {
    pages: 1,
    page: 1,
    backLabel: "Anterior",
    nextLabel: "Siguiente",
    onPageChange: () => { }
}

export default Pagination
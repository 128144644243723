import React, { useContext } from 'react'
import Hamburger from 'hamburger-react'
import AppContext from '../state/AppContext';
import UserContext from '../state/UserContext'
const Toolbar = () => {

    const userContext = useContext(UserContext)
    const { appContext, setAppContext } = useContext(AppContext)

    const toogleNavbar = () => {
        setAppContext({
            ...appContext,
            navbar: {
                open: !appContext.navbar.open
            }
        })
    }

    const render = () => {

        const navbar = appContext.navbar

        return (
            <nav className="bg-dark-400 p-2 px-2 h-12 lg:h-16 flex flex-row justify-between items-center border-b border-dark-100">
                <span className="inline-block lg:hidden">
                    <Hamburger className=" text-white bg-white" size={18} color="#FFF" toggled={navbar.open} toggle={toogleNavbar} />
                </span>
                <img src="/brand/dalala-logo-lg.svg" alt="dalala" className="h-full lg:h-10 " />
                {userContext.user && <p>{`${userContext.user.nombres} ${userContext.user.apPat}`}</p>}
            </nav>
        )
    }

    return render()
}
export default Toolbar
import { addYear, parse } from "../../utils/DateUtils";
import {
  NUEVA_POLIZA_RESET_STATE,
  NUEVA_POLIZA_ONCHANGE,
  NUEVA_POLIZA_ONCHANGE_TYPE,
  NUEVA_POLIZA_ONCHANGE_GENERALES,
  NUEVA_POLIZA_ONCHANGE_VEHICULO,
  NUEVA_POLIZA_ONCHANGE_PRIMAS,
  NUEVA_POLIZA_ONCHANGE_ASEGURADO,
  NUEVA_POLIZA_ONCHANGE_RECIBOS,
  NUEVA_POLIZA_ONCHANGE_DETALLES,
  NUEVA_POLIZA_ONCHANGE_STEPPER,
  NUEVA_POLIZA_ONCHANGE_STEPPER_NEXT,
  NUEVA_POLIZA_LOAD_CARATULA,
  NUEVA_POLIZA_AGREGAR_ASEGURADO,
  NUEVA_POLIZA_REMOVER_ASEGURADO,
  EDITAR_POLIZA,
  RENOVAR_POLIZA,
  POLIZA_UPDATE_STATUS,
} from "../redux-const";

import AppsIcon from "@mui/icons-material/Apps";
import DriveEtaIcon from "@material-ui/icons/DriveEta";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import MoneyIcon from "@mui/icons-material/Money";
import PersonIcon from "@mui/icons-material/Person";
import HomeIcon from "@material-ui/icons/Home";

const vehiculoJourney = [
  {
    id: "generales",
    label: "Datos Generales",
    active: true,
    completed: false,
    icon: <AppsIcon fontSize="large" />,
    first: true,
    last: false,
    path: "",
  },
  {
    id: "vehiculo",
    label: "Vehículo",
    active: false,
    completed: false,
    icon: <DriveEtaIcon fontSize="large" />,
    first: false,
    last: false,
    path: "/vehiculo",
  },
  {
    id: "primas",
    label: "Primas",
    active: false,
    completed: false,
    icon: <MonetizationOnIcon fontSize="large" />,
    first: false,
    last: false,
    path: "/primas",
  },
  {
    id: "recibos",
    label: "Recibos",
    active: false,
    completed: false,
    icon: <MoneyIcon fontSize="large" />,
    first: false,
    last: true,
    path: "/recibos",
  },
];

const vidaJourney = [
  {
    id: "generales",
    label: "Datos Generales",
    active: true,
    completed: false,
    icon: <AppsIcon fontSize="large" />,
    first: true,
    last: false,
    path: "",
  },
  {
    id: "asegurado",
    label: "Asegurado",
    active: false,
    completed: false,
    icon: <PersonIcon fontSize="large" />,
    first: false,
    last: false,
    path: "/asegurado",
  },
  {
    id: "primas",
    label: "Primas",
    active: false,
    completed: false,
    icon: <MonetizationOnIcon fontSize="large" />,
    first: false,
    last: false,
    path: "/primas",
  },
  {
    id: "recibos",
    label: "Recibos",
    active: false,
    completed: false,
    icon: <MoneyIcon fontSize="large" />,
    first: false,
    last: true,
    path: "/recibos",
  },
];

const saludJourney = [
  {
    id: "generales",
    label: "Datos Generales",
    active: true,
    completed: false,
    icon: <AppsIcon fontSize="large" />,
    first: true,
    last: false,
    path: "",
  },
  {
    id: "asegurados",
    label: "Asegurados",
    active: false,
    completed: false,
    icon: <PersonIcon fontSize="large" />,
    first: false,
    last: false,
    path: "/asegurados",
  },
  {
    id: "primas",
    label: "Primas",
    active: false,
    completed: false,
    icon: <MonetizationOnIcon fontSize="large" />,
    first: false,
    last: false,
    path: "/primas",
  },
  {
    id: "recibos",
    label: "Recibos",
    active: false,
    completed: false,
    icon: <MoneyIcon fontSize="large" />,
    first: false,
    last: true,
    path: "/recibos",
  },
];

const diversosJourney = [
  {
    id: "generales",
    label: "Datos Generales",
    active: true,
    completed: false,
    icon: <AppsIcon fontSize="large" />,
    first: true,
    last: false,
    path: "",
  },
  {
    id: "diversos",
    label: "Diversos",
    active: false,
    completed: false,
    icon: <HomeIcon fontSize="large" />,
    first: false,
    last: false,
    path: "/diversos",
  },
  {
    id: "primas",
    label: "Primas",
    active: false,
    completed: false,
    icon: <MonetizationOnIcon fontSize="large" />,
    first: false,
    last: false,
    path: "/primas",
  },
  {
    id: "recibos",
    label: "Recibos",
    active: false,
    completed: false,
    icon: <MoneyIcon fontSize="large" />,
    first: false,
    last: true,
    path: "/recibos",
  },
];

const ramos = {
  1: "autos" || "auto",
  3: "salud",
  5: "vida",
  7: "diversos",
};

const initialState = {
  newType: "vida",
  ramoBlocked: false,
  currentRec: 0,
  currency: [],
  aseguradoras: [],
  contratantes: [],
  currentAseg: null,
  vehiculos: [],
  formaPago: [
    { key: "contado", value: "Contado", parcialidades: 1 },
    { key: "semestral", value: "Semestral", parcialidades: 2 },
    { key: "trimestral", value: "Trimestral", parcialidades: 4 },
    { key: "mensual", value: "Mensual", parcialidades: 12 },
  ],
  genero: [
    { key: "hombre", value: "Hombre" },
    { key: "mujer", value: "Mujer" },
  ],
  parentesco: [
    { key: "titular", value: "Titular" },
    { key: "conyuge", value: "Cónyuge" },
    { key: "hijo", value: "Hijo(a)" },
    { key: "otro", value: "Otro" },
  ],

  stepper: {
    activeStep: 0,
    lastStep: 0,
    steps: vidaJourney,
  },
  generales: {
    contratante: null,
    aseguradora: "",
    contratanteId: "",
    agente: "",
    concepto: "",
    fecFinal: new Date(),
    fecInicial: new Date(),
    moneda: "",
    plan: "",
    poliza: "",
    formaPago: "",
    recibos: 0,
    primerRecibo: 0.0,
    reciboSubsecuente: 0.0,
  },
  vehiculo: {
    modelo: new Date().getFullYear(),
    descripcion: null,
    noSerie: "",
    placas: "",
  },
  primas: {
    primaNeta: 0.0,
    derecho: 0.0,
    financiamiento: 0.0,
    iva: 0.0,
    primaTotal: 0.0,
  },
  recibos: [],
  asegurados: [
    {
      parentesco: "titular",
      name: "",
      apPat: "",
      apMat: "",
      nacimiento: null,
      rfc: "",
      genero: "",
      expanded: true
    },
  ],
  detalles: {
    texto: "",
  },
  texto: "",
  pages: {},
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case NUEVA_POLIZA_RESET_STATE:
      initialState.asegurados[0].name = "";
      return initialState;

    case NUEVA_POLIZA_ONCHANGE:
      return {
        ...state,
        ...action.payload,
      };

    case NUEVA_POLIZA_ONCHANGE_TYPE:
      let journey = null;
      if (action.payload.newType === "vida") {
        journey = vidaJourney;
      } else if (action.payload.newType === "autos") {
        journey = vehiculoJourney;
      } else if (action.payload.newType === "salud") {
        journey = saludJourney;
      } else if (action.payload.newType === "diversos") {
        journey = diversosJourney;
      }

      return {
        ...state,
        newType: action.payload.newType,
        stepper: {
          ...state.stepper,
          steps: journey,
        },
      };

    case NUEVA_POLIZA_ONCHANGE_GENERALES:
      return {
        ...state,
        generales: {
          ...state.generales,
          ...action.payload,
        }
      };

    case NUEVA_POLIZA_ONCHANGE_VEHICULO:
      return {
        ...state,
        vehiculo: {
          ...state.vehiculo,
          ...action.payload,
        },
      };

    case NUEVA_POLIZA_ONCHANGE_PRIMAS:
      return {
        ...state,
        primas: {
          ...state.primas,
          ...action.payload,
        },
      };

    case NUEVA_POLIZA_ONCHANGE_ASEGURADO:
      console.log("initialState ASEGURADO", initialState);
      const aseguradoIndex = action.payload.index;
      let currentAsegurado = state.asegurados[aseguradoIndex];

      currentAsegurado = {
        ...currentAsegurado,
        ...action.payload,
        index: undefined,
      };

      state.asegurados[aseguradoIndex] = currentAsegurado;

      return {
        ...state,
        asegurados: [...state.asegurados],
      };

    case NUEVA_POLIZA_ONCHANGE_RECIBOS:
      const currentIndex = state.recibos.findIndex(
        (x) => x.serie === action.payload.serie
      );
      console.log("currentIndex", currentIndex);

      const currentRecibo = state.recibos[currentIndex];
      console.log("currentRecibo", currentRecibo);

      const totalSerie = currentRecibo.totalSerie - 1;

      const primaNeta = action.payload.primaNeta || currentRecibo.primaNeta;
      const derecho = action.payload.derecho || currentRecibo.derecho;
      const financiamiento =
        action.payload.financiamiento || currentRecibo.financiamiento;
      const primaTotal = primaNeta + derecho + financiamiento;
      const iva = primaTotal * 0.16;

      Object.assign(currentRecibo, {
        ...action.payload,
        iva,
        primaTotal: primaTotal + iva,
      });

      /* const newRecibo = {
        ...currentRecibo,
        ...action.payload,
        iva,
        primaTotal: primaTotal + iva,
      }; */
      console.log("state.recibos", state.recibos);

      const newRecibos = [
        state.recibos[0],
        ...state.recibos
          .filter((item, index) => index > 0)
          .map((r) => {
            const pn = (state.primas.primaNeta - primaNeta) / totalSerie;
            const d = (state.primas.derecho - derecho) / totalSerie;
            const f =
              (state.primas.financiamiento - financiamiento) / totalSerie;
            const pt = pn + d + f;
            const iv = pt * 0.16;

            return {
              ...r,
              primaNeta: pn,
              derecho: d,
              financiamiento: f,
              iva: iv,
              primaTotal: pt + iv,
            };
          }),
      ];

      console.log("newRecibos", newRecibos);

      return {
        ...state,
        recibos: newRecibos,
      };

    case NUEVA_POLIZA_ONCHANGE_DETALLES:
      return {
        ...state,
        ...action.payload,
        /* detalles: {
          ...state.detalles,
          ...action.payload,
        }, */
      };

    case NUEVA_POLIZA_ONCHANGE_STEPPER:
      return {
        ...state,
        stepper: {
          ...state.stepper,
          ...action.payload,
        },
      };

    case NUEVA_POLIZA_ONCHANGE_STEPPER_NEXT:
      let activeStep = state.stepper.activeStep;
      let currentStep = state.stepper.steps[activeStep];
      currentStep.completed = true;
      currentStep.active = false;

      if (!currentStep.last) {
        const nextStep = state.stepper.steps[activeStep + 1];
        nextStep.active = true;
      }

      return {
        ...state,
        stepper: {
          ...state.stepper,
          activeStep: activeStep + 1,
          lastStep:
            activeStep + 1 > state.stepper.lastStep
              ? activeStep + 1
              : state.stepper.lastStep,
          steps: [...state.stepper.steps],
        },
      };

    case NUEVA_POLIZA_LOAD_CARATULA:
      const payload = action.payload;
      const { cliente, vehiculo, recibos } = action.payload;
      const ramo = ramos[payload.ramo];

      let journeys = null;
      if (ramo === "vida") {
        journeys = vidaJourney;
      } else if (ramo === "autos") {
        journeys = vehiculoJourney;
      } else if (ramo === "salud") {
        journeys = saludJourney;
      } else if (ramo === "diversos") {
        journeys = diversosJourney;
      }

      const asegurados = payload.asegurados
        ? payload.asegurados.map(x => {
          return {
            parentesco: x.parentesco,
            name: x.nombres,
            apPat: x.apPat,
            apMat: x.apMat,
            nacimiento: x.fecNacimiento ? parse(x.fecNacimiento, "yyyy-MM-dd") : null,
            rfc: x.rfc,
            genero: x.genero,
            expanded: true
          }
        })
        : null

      return {
        ...state,
        ramoBlocked: true,
        newType: ramo,
        stepper: {
          ...state.stepper,
          steps: journeys,
        },
        contratantes: [
          {
            id: cliente.id || "",
            nombres: cliente.nombres || "",
            apPat: cliente.apPat || "",
            apMat: cliente.apMat || "",
            //fecNacimiento: cliente.fecNacimiento,
            //fecRegistro: cliente.fecRegistro,
            fiscal: cliente.fiscal,
            rfc: cliente.rfc,
            edoCivil: cliente.edoCivil,
            sexo: cliente.sexo,
            celular: cliente.celular,
            correo: cliente.correo,
            direccion: cliente.direccion?.direccion,
            cp: cliente.direccion?.cp,
            tipoDireccion: cliente.direccion?.tipo,
            esRobot: true
          },
        ],
        vehiculos: ramo === "autos" ? [
          {
            id: vehiculo.id,
            descripcion: vehiculo.descripcion,
            marca: vehiculo.marca,
            tipo: vehiculo.tipo,
            modelo: vehiculo.modelo,
            amis: vehiculo.amis,
          },
        ] : undefined,
        generales: {
          agente: payload.claveAgente,
          contratante: {
            id: cliente.id || "",
            nombres: cliente.nombres,
            apPat: cliente.apPat,
            apMat: cliente.apMat,
            //fecNacimiento: cliente.fecNacimiento, 
            //fecRegistro: cliente.fecRegistro,
            fiscal: cliente.fiscal,
            rfc: cliente.rfc,
            edoCivil: cliente.edoCivil,
            sexo: cliente.sexo,
            direccion: cliente.direccion?.direccion,
            cp: cliente.direccion?.cp,
            tipoDireccion: cliente.direccion?.tipo,
            esRobot: cliente.esRobot
          },
          concepto: payload.concepto,
          fecFinal: parse(payload.fechaVigenciaA, "yyyy-MM-dd"),
          fecInicial: parse(payload.fechaVigenciaDe, "yyyy-MM-dd"), //new Date(payload.fechaVigenciaDe),
          moneda: payload.moneda,
          plan: payload.paquete,
          poliza: payload.noPoliza,
          formaPago: payload.formaPago,
          recibos: payload.recibos.length,
          primerRecibo: 0,
          reciboSubsecuente: 0,
          contratanteId: cliente.id || "",
          aseguradora: payload.aseguradora,
          esRobot: true
        },
        vehiculo: ramo === "autos" ? {
          id: vehiculo.id,
          modelo: vehiculo.modelo,
          descripcion: {
            descripcion: vehiculo.descripcion,
            marca: vehiculo.marca,
            tipo: vehiculo.tipo,
            modelo: vehiculo.modelo,
            amis: vehiculo.amis,
          },
          noSerie: vehiculo.noSerie,
          placas: vehiculo.placas,
        } : undefined,
        primas: {
          primaNeta: payload.prima,
          derecho: payload.derecho,
          financiamiento: payload.recargo,
          iva: payload.iva,
          primaTotal: payload.total,
        },
        recibos: recibos.map((recibo, index) => {
          return {
            serie: recibo.serie,
            primaNeta: recibo.prima,
            derecho: recibo.derecho,
            financiamiento: 0,
            iva: recibo.iva,
            primaTotal: recibo.total,
            fecInicial: parse(recibo.fecVigDe, "yyyy-MM-dd"),
            fecFinal: parse(recibo.fecVigA, "yyyy-MM-dd"),
            vencimiento: parse(recibo.fecVence, "yyyy-MM-dd"),
          };
        }),
        asegurados,
        caratula: payload.caratula
      };

    case NUEVA_POLIZA_AGREGAR_ASEGURADO:
      const nuevoAsegurado = {
        parentesco: "",
        nombres: "",
        apPat: "",
        apMat: "",
        nacimiento: null,
        rfc: "",
        genero: "",
        expanded: true
      };
      return {
        ...state,
        asegurados: [...state.asegurados, nuevoAsegurado],
      };

    case NUEVA_POLIZA_REMOVER_ASEGURADO:
      if (action.payload.index !== 0) {
        return {
          ...state,
          asegurados: state.asegurados.filter(
            (asegurado, index) => index !== action.payload.index
          ),
        };
      }

    case EDITAR_POLIZA:
      const pay = action.payload;
      const ram = pay.ramo.ramo.toLowerCase();

      console.log("PAY", pay);

      let jour = null;
      if (ram === "vida") {
        jour = vidaJourney;
      } else if (ram === "autos" || ram === "auto") {
        jour = vehiculoJourney;
      } else if (ram === "salud") {
        jour = saludJourney;
      } else if (ram === "diversos") {
        jour = diversosJourney;
      }

      const newState = {
        ...state,
        ramoBlocked: true,
        newType: ram,
        stepper: {
          ...state.stepper,
          steps: jour,
        },
        vehiculos: [
          {
            id: pay.vehiculo?.id,
            descripcion: pay.vehiculo?.descripcion,
            marca: pay.vehiculo?.marca,
            tipo: pay.vehiculo?.tipo,
            modelo: pay.vehiculo?.modelo,
            amis: pay.vehiculo?.amis,
          },
        ],
        generales: {
          polizaId: pay.id,
          agente: pay.agenteClave.id,
          aseguradora: pay.agenteClave.aseguradora.id,
          concepto: pay.concepto,
          fecFinal: parse(pay.fechaVigenciaA, "yyyy-MM-dd"),
          fecInicial: parse(pay.fechaVigenciaDe, "yyyy-MM-dd"),
          moneda: pay.moneda.id,
          plan: pay.paquete,
          poliza: pay.noPoliza,
          formaPago: pay.formaPago,
          recibos: pay.recibos.length,
          esRobot: pay.cliente.esRobot,
          contratante: {
            id: pay.cliente.id,
            nombres: pay.cliente.nombres,
            apPat: pay.cliente.apPat,
            apMat: pay.cliente.apMat,
            fiscal: pay.cliente.fiscal,
            rfc: pay.cliente.rfc,
            edoCivil: pay.cliente.edoCivil,
            genero: pay.cliente.genero,
            esRobot: pay.cliente.esRobot,
          },
        },
        vehiculo: {
          id: pay.vehiculo?.id,
          modelo: pay.vehiculo?.modelo,
          descripcion: {
            descripcion: pay.vehiculo?.descripcion,
            marca: pay.vehiculo?.marca,
            tipo: pay.vehiculo?.tipo,
            modelo: pay.vehiculo?.modelo,
            amis: pay.vehiculo?.amis,
          },
          noSerie: pay.vehiculo?.noSerie,
          placas: pay.vehiculo?.placas,
        },
        asegurados: pay.asegurados.map((asegurado, index) => {
          return {
            apPat: asegurado.apPat,
            apMat: asegurado.apMat,
            genero: asegurado.genero,
            id: asegurado.id,
            name: asegurado.nombres,
            parentesco: asegurado.parentesco,
            rfc: asegurado.rfc,
            nacimiento: parse(asegurado.fecNacimiento, "yyyy-MM-dd"),
            expanded: true
          };
        }),
        primas: {
          primaNeta: pay.prima,
          derecho: pay.derecho,
          financiamiento: pay.recargo,
          iva: pay.iva,
          primaTotal: pay.total,
        },
        recibos: pay.recibos.map((recibo, index) => {
          return {
            id: recibo.id,
            serie: recibo.serie,
            primaNeta: recibo.prima,
            derecho: recibo.derecho,
            financiamiento: 0,
            iva: recibo.iva,
            primaTotal: recibo.total,
            fecInicial: parse(recibo.fecVigDe, "yyyy-MM-dd"),
            fecFinal: parse(recibo.fecVigA, "yyyy-MM-dd"),
            vencimiento: parse(recibo.fecVence, "yyyy-MM-dd"),
            totalSerie: recibo.totalSerie,
            status: recibo.status,
            fecPago: recibo.fecPago
              ? parse(recibo.fecPago, "yyyy-MM-dd")
              : null,
            folioPago: recibo.folioPago,
          };
        }),
        texto: pay.texto,
      };
      console.log("NEWSTATE", pay);
      return newState;

    case RENOVAR_POLIZA:
      const load = action.payload;
      const r = load.ramo.ramo.toLowerCase();

      console.log("PAY", load);

      let j = null;
      if (r === "vida") {
        j = vidaJourney;
      } else if (r === "autos" || r === "auto") {
        j = vehiculoJourney;
      } else if (r === "salud") {
        j = saludJourney;
      } else if (r === "diversos") {
        j = diversosJourney;
      }

      const newStateReno = {
        ...state,
        ramoBlocked: true,
        newType: r,
        stepper: {
          ...state.stepper,
          steps: j,
        },
        vehiculos: [
          {
            id: load?.id,
            descripcion: load?.descripcion,
            marca: load?.marca,
            tipo: load?.tipo,
            modelo: load?.modelo,
            amis: load?.amis,
          },
        ],
        generales: {
          polizaId: load.id,
          agente: load.agenteClave.id,
          aseguradora: load.agenteClave.aseguradora.id,
          concepto: load.concepto,
          fecFinal: addYear(parse(load.fechaVigenciaA, "yyyy-MM-dd"), +1),
          fecInicial: addYear(parse(load.fechaVigenciaDe, "yyyy-MM-dd"), +1),
          moneda: load.moneda.id,
          plan: load.paquete,
          poliza: load.noPoliza,
          formaPago: load.formaPago,
          recibos: load.recibos.length,
          contratante: {
            id: load.cliente.id,
            nombres: load.cliente.nombres,
            apPat: load.cliente.apPat,
            apMat: load.cliente.apMat,
            fiscal: load.cliente.fiscal,
            rfc: load.cliente.rfc,
            edoCivil: load.cliente.edoCivil,
            genero: load.cliente.genero,
          },
        },
        vehiculo: {
          id: load.vehiculo?.id,
          modelo: load.vehiculo?.modelo,
          descripcion: {
            descripcion: load.vehiculo?.descripcion,
            marca: load.vehiculo?.marca,
            tipo: load.vehiculo?.tipo,
            modelo: load.vehiculo?.modelo,
            amis: load.vehiculo?.amis,
          },
          noSerie: load.vehiculo?.noSerie,
          placas: load.vehiculo?.placas,
        },
        asegurados: load.asegurados.map((asegurado, index) => {
          return {
            apPat: asegurado.apPat,
            apMat: asegurado.apMat,
            genero: asegurado.genero,
            // id: asegurado.id,
            name: asegurado.nombres,
            parentesco: asegurado.parentesco,
            rfc: asegurado.rfc,
            nacimiento: parse(asegurado.fecNacimiento, "yyyy-MM-dd"),
            expanded: true
          };
        }),
        primas: {
          primaNeta: 0,
          derecho: 0,
          financiamiento: 0,
          iva: 0,
          primaTotal: 0,
        },
        recibos: [],
        texto: load.texto,
      };
      console.log("NEWSTATE", load);
      return newStateReno;

    case POLIZA_UPDATE_STATUS:
      const { id, status } = action.payload;

      let recibo = state.recibos.find((x) => x.recibo.id === id);

      recibo.recibo.status = status;

      return {
        ...state,
        recibos: [...state.recibos],
      };
  }
  return state;
};

export default reducer;

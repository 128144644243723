import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from 'react-redux'

import "./App.css";
import Login from "./pages/Login";
import Registro from "./pages/Registro";
import ScreenDebug from "./components/ScreenDebug";
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "./theme";
import UserContext from "./state/UserContext";
import AppContext from "./state/AppContext";
import { useState, useEffect } from "react";
import Landing from "./pages/Landing";
import APIInvoke from "./utils/APIInvoke";
import Loading from "./components/Loading";
import RegistroContra from "./pages/RegistroContra"
import ConsultaPoliza from "./pages/ConsultaPoliza";
import ConsultaCliente from "./pages/ConsultaCliente";
import Polizas from "./pages/Polizas";
import { useHistory, Redirect } from "react-router-dom";
import Clientes from "./pages/Clientes";
import NuevoCliente from "./pages/NuevoCliente";
import Bitacora from "./pages/Bitacora";
import NuevaPoliza from "././pages/NuevaPoliza";
import CxC from "././pages/CxC"
import Resumen from "./pages/Resumen"
import PagoRecibo from "././pages/PagoRecibo"
import store from './redux/store'
import Renovaciones from "./pages/Renovaciones";
import Adjuntos from "./pages/Adjuntos";
import Configuracion from "./pages/Configuracion";
import Vencimientos from "./pages/Vencimientos";
import Metas from "./pages/Metas";
import Planes from "./pages/Planes";
import CerrarPoliza from "./pages/CerrarPoliza";
import Modelos from "./pages/Modelos";
import NuevoModelo from "./pages/NuevoModelo";
import Claves from "./pages/Claves";
import Automatizaciones from "./pages/Automatizaciones";
import Automatizacione from "./pages/Automatizacione";
import Page404 from "./pages/404";
import Page500 from "./pages/500";
import ReactivarPoliza from './pages/ReactivarPoliza'
import PaymentComplete from "./pages/PaymentComplete";
import Correo from "./pages/Correo";
import Usuarios from "./pages/Usuarios";
import MiPlan from "./pages/MiPlan";
import Todoist from "./pages/integraciones/Todoist";
import Integraciones from "./pages/integraciones/Integraciones";
import MiCuenta from "./pages/MiCuenta";
import RestaurarPassword from "./pages/RestaurarPassword";
import SetNewPassword from "./pages/SetNewPassword";
import EnvWarning from "./components/EnvWarning";
import SuscripcionVencida from './pages/SuscripcionVencida'
import AvisoPrivacidad from './pages/AvisoPrivacidad'
import TerminosCondiciones from './pages/TerminosCondiciones'
import ImportarPolizas from "./pages/ImportarPolizas";
import Calendar from "./pages/Calendar";
import ReportePolizas from "./pages/reportes/ReportePolizas";
import Reportes from "./pages/reportes/Reportes";

function App() {
  const history = useHistory()
  const [user, setUser] = useState(null);
  const [appContext, setAppContext] = useState({
    navbar: {
      open: window.innerWidth >= 1024 ? true : false,
    },
  });

  const [isReady, setReady] = useState(false);

  useEffect(() => {
    const token = window.localStorage.getItem("token")
    if (token) isTokenExpired(token)
    if (token) {
      APIInvoke.invokePOST(`/usuarios/login`)
        .then((res) => res.json())
        .catch((err) => setReady(true))
        .then((res) => {
          if (res.ok) {
            window.localStorage.setItem("token", res.body.token);
            setUser({
              ...res.body.usuario,
              suscripcion: res.body.suscripcion,
              rol: res.body.role
            });

            setTimeout(() => {
              const tawk = window.Tawk_API || {}
              const tawkRequest = {
                name: `${res.body.usuario.nombres} ${res.body.usuario.apPat} ${res.body.usuario.apMat}`,
                email: res.body.usuario.correo,
                hash: res.body.tawktoToken
              }
              tawk.setAttributes(tawkRequest)
            }, 5000)

            setReady(true);
          } else {
            window.localStorage.removeItem("token")
            history.push("/login")
          }
        })
        .catch((err) => setReady(true));
    } else {
      setReady(true);
    }
  }, []);

  const isTokenExpired = (token) => {
    const expiry = (JSON.parse(atob(token.split('.')[1]))).exp;
    const isExpity = (Math.floor((new Date()).getTime() / 1000)) >= expiry;
    if (isExpity) {
      window.localStorage.removeItem("token")
      history.push("/login")
    }
  }

  const getRoutes = () => {
    if (!user) {
      return (
        <Switch>
          <Route path="/" component={SuscripcionVencida} exact />
          <Route path="/404" component={Page404} exact />
          <Route path="/500" component={Page500} exact />
          <Route path="/login" component={Login} exact />
          <Route path="/registro" component={Registro} exact />
          <Route path="/activar" component={RegistroContra} exact />
          <Route path="/planes" component={Planes} exact />
          <Route path="/restaurar-pass" component={RestaurarPassword} exact />
          <Route path="/establecer-pass" component={SetNewPassword} exact />
          <Route path="/aviso-privacidad" component={AvisoPrivacidad} exact />
          <Route path="/terminos-condiciones" component={TerminosCondiciones} exact />
          <Route component={Page404} exact />
        </Switch>
      )
    } else if (user.rol === "USER") {
      return (
        <Switch>
          <Redirect from="/" to="/resumen" exact strict />
          <Route path="/404" component={Page404} exact />
          <Route path="/500" component={Page500} exact />
          <Route path="/payments/stripe/completed" component={PaymentComplete} exact />
          <Route path="/login" component={Login} exact />
          <Route path="/restaurar-pass" component={RestaurarPassword} exact />
          <Route path="/establecer-pass" component={SetNewPassword} exact />
          <Route path="/aviso-privacidad" component={AvisoPrivacidad} exact />
          <Route path="/terminos-condiciones" component={TerminosCondiciones} exact />
          <Route path="/modelos" component={Modelos} exact strict />
          <Route path="/modelos/" component={NuevoModelo} exact strict />
          <Route path="/Automatizaciones" component={Automatizaciones} exact strict />
          <Route path="/Automatizaciones/:logId" component={Automatizacione} exact strict />
          <Route path="/modelos/:modeloId" component={NuevoModelo} exact strict />
          <Route path="/resumen" component={Resumen} exact />
          <Route path="/configuracion" component={Configuracion} exact />
          <Route path="/integraciones" component={Integraciones} exact />
          <Route path="/integraciones/todoist" component={Todoist} exact />
          <Route path="/vencimientos" component={Vencimientos} exact />
          <Route path="/metas" component={Metas} exact />
          <Route path="/planes" component={Planes} exact />
          <Route path="/cxc" component={CxC} exact />
          <Route path="/renovaciones" component={Renovaciones} exact />
          <Route path="/claves" component={Claves} exact />
          <Route path="/clientes" component={Clientes} exact strict />
          <Route path="/clientes/" component={NuevoCliente} exact strict />
          <Route path="/clientes/:clienteId" component={ConsultaCliente} exact />
          <Route path="/clientes/:clienteId/edit" component={NuevoCliente} exact />
          <Route path="/clientes/:entidadId/adjuntos" render={() => <Adjuntos entidad="clientes" />} exact strict />
          <Route path="/registro" component={Registro} exact />
          <Route path="/activar" component={RegistroContra} exact />
          <Route path="/poliza/:polizaId" component={ConsultaPoliza} exact strict />
          <Route path="/polizas" component={Polizas} exact strict />
          <Route path="/polizas/:polizaId" component={NuevaPoliza} exact strict />
          <Route path="/polizas/:polizaId/cerrar" component={CerrarPoliza} exact strict />
          <Route path="/polizas/:polizaId/reactivar" component={ReactivarPoliza} exact strict />
          <Route path="/:entidad/:entidadId/bitacora" component={Bitacora} exact strict />
          <Route path="/polizas/:entidadId/adjuntos" render={() => <Adjuntos entidad="polizas" />} exact strict />
          <Route path="/polizas/:polizaId/:section" component={NuevaPoliza} exact strict />
          <Route path="/recibos/:reciboId/pagar" component={PagoRecibo} exact strict />
          <Route path="/recibos/:entidadId/adjuntos" render={() => <Adjuntos entidad="recibos" />} exact strict />
          <Route path="/comofunciona" component={Landing} exact />
          <Route path="/correo/:objectId" component={Correo} exact />
          <Route path="/usuarios" component={Usuarios} exact />
          <Route path="/mi-cuenta" component={MiCuenta} exact />
          <Route path="/mi-plan" component={MiPlan} exact />
          <Route path="/importar-polizas-pdf" component={ImportarPolizas} exact />
          <Route path="/calendario" component={Calendar} exact />
          <Route path="/reportes" component={Reportes} exact />
          <Route path="/reportes/polizas" component={ReportePolizas} exact />
          <Route component={Page404} exact />
        </Switch>
      )
    } else if (user.rol === "UNSUBSCRIBE_USER") {
      return (
        <Switch>
          <Redirect from="/resumen" to="/" exact strict />
          <Route path="/" component={SuscripcionVencida} exact />
          <Route path="/404" component={Page404} exact />
          <Route path="/500" component={Page500} exact />
          <Route path="/payments/stripe/completed" component={PaymentComplete} exact />
          <Route path="/login" component={Login} exact />
          <Route path="/planes" component={Planes} exact />
          <Route path="/restaurar-pass" component={RestaurarPassword} exact />
          <Route path="/establecer-pass" component={SetNewPassword} exact />
          <Route path="/aviso-privacidad" component={AvisoPrivacidad} exact />
          <Route path="/terminos-condiciones" component={TerminosCondiciones} exact />
          <Route path="/mi-cuenta" component={MiCuenta} exact />
          <Route path="/mi-plan" component={MiPlan} exact />
          <Route component={Page404} exact />
        </Switch>
      )
    } else {
      return null
    }
  }

  return (
    <UserContext.Provider value={{ user, setUser }}>
      <AppContext.Provider value={{ appContext, setAppContext }}>
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <ScreenDebug />
            <Loading show={!isReady} />
            <EnvWarning />
            {isReady && (
              <Router onUpdate={() => window.scrollTo(0, 0)}>
                {getRoutes()}
              </Router>
            )}
          </ThemeProvider>
        </Provider>
      </AppContext.Provider>
    </UserContext.Provider>
  );
}

export default App;

import React, { useContext, useEffect, useState } from "react";
import { TextField, InputAdornment, Button, IconButton, Divider } from '@material-ui/core';
import { useHistory } from "react-router-dom";
import { maxFieldLength, onlyNumbers } from "../utils/FormUtils";
import Loading from "../components/Loading";
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import AdminTemplate from "../template/AdminTemplate";
import APIInvoke from "../utils/APIInvoke";
import Alert from '../components/Alert';
import Toolbar from '../components/Toolbar'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import LockIcon from '@mui/icons-material/Lock';
import DialogTitle from '@mui/material/DialogTitle';
import UserContext from "../state/UserContext";

const MiCuenta = (props) => {

    const [loading, setLoading] = useState(true)

    const [changePassState, setChangePassState] = useState({
        oldPassword: "",
        newPassword: "",
        newPassword2: "",
        showPassword: false,
        showChangePassDialog: false,
        message: null,
        messageType: null
    })

    const [user, setUser] = useState()

    useEffect(async () => {
        try {
            setLoading(true)
            const meResponse = await (await APIInvoke.invokeGET(`/usuarios/me`)).json()
            setUser(meResponse.body)
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    }, [])


    const changePassword = async (e) => {
        e.preventDefault()
        try {
            if (changePassState.newPassword !== changePassState.newPassword2) {
                setChangePassState({
                    ...changePassState,
                    message: "Los dos password no coinciden",
                    messageType: "error"
                })
                return false
            }

            if (changePassState.newPassword.length < 8 || changePassState.newPassword.length > 50) {
                setChangePassState({
                    ...changePassState,
                    message: "El nuevo password debe contener entre 8 y 50 caracteres",
                    messageType: "error"
                })
                return false
            }

            setLoading(true)

            const body = {
                oldPassword: changePassState.oldPassword,
                newPassword: changePassState.newPassword
            }

            const response = await (await APIInvoke.invokePUT(`/usuarios/password`, body)).json()
            if (response.ok) {
                setChangePassState({
                    ...changePassState,
                    oldPassword: "",
                    newPassword: "",
                    newPassword2: "",
                    showPassword: false,
                    message: response.message,
                    messageType: "success",
                    showChangePassDialog: false
                })
            } else {
                setChangePassState({
                    ...changePassState,
                    message: response.message,
                    messageType: "error"
                })
            }
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    const onChangePass = (e) => {
        setChangePassState({
            ...changePassState,
            [e.target.name]: e.target.value
        })
    }

    const onChangeUser = (e) => {
        setUser({
            ...user,
            [e.target.name]: e.target.value
        })
    }

    const saveChanges = async (e) => {
        e.preventDefault()
        try {
            setLoading(true)

            const body = {
                id: user.id,
                nombres: user.nombres,
                apPat: user.apPat,
                apMat: user.apMat,
                celular: user.celular
            }
            const saveResponse = await (await APIInvoke.invokePUT(`/usuarios`, body)).json()
            setUser({
                ...user,
                messageType: saveResponse.ok ? "success" : "error",
                message: saveResponse.message
            })
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }

    const render = () => {

        if (loading) return <AdminTemplate loading={true} />

        return (
            <AdminTemplate>
                <Dialog
                    open={changePassState.showChangePassDialog}
                    onClose={e => setChangePassState({ ...changePassState, showChangePassDialog: false })}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    className=""
                >
                    <form onSubmit={changePassword}>
                        <div className="border border-dark-300 rounded-md p-2 ">
                            <DialogTitle id="alert-dialog-title">Cambiar contraseña</DialogTitle>
                            <DialogContent>
                                <div className="grid grid-cols-1 gap-4 mt-4">
                                    <TextField
                                        label="Contraseña Actual"
                                        value={changePassState.oldPassword}
                                        type={changePassState.showPassword ? 'text' : 'password'}
                                        variant="outlined"
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton onClick={e => setChangePassState({ ...changePassState, showPassword: !changePassState.showPassword })}  >
                                                        {changePassState.showPassword ? <VisibilityIcon className="text-gray-400" /> : <VisibilityOffIcon className="text-gray-400" />}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                        onInput={(e) => maxFieldLength(e, 50)}
                                        name="oldPassword"
                                        onChange={onChangePass}
                                        required
                                    />

                                    <Divider className="bg-gray-500" />

                                    <TextField
                                        label="Contraseña"
                                        value={changePassState.newPassword}
                                        type={changePassState.showPassword ? 'text' : 'password'}
                                        variant="outlined"
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton onClick={e => setChangePassState({ ...changePassState, showPassword: !changePassState.showPassword })}  >
                                                        {changePassState.showPassword ? <VisibilityIcon className="text-gray-400" /> : <VisibilityOffIcon className="text-gray-400" />}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                        onInput={(e) => maxFieldLength(e, 50)}
                                        name="newPassword"
                                        onChange={onChangePass}
                                        required
                                    />
                                    <TextField
                                        label="Repite la Contraseña"
                                        value={changePassState.oldPassword2}
                                        type={changePassState.showPassword ? 'text' : 'password'}
                                        variant="outlined"
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton onClick={e => setChangePassState({ ...changePassState, showPassword: !changePassState.showPassword })} >
                                                        {changePassState.showPassword ? <VisibilityIcon className="text-gray-400" /> : <VisibilityOffIcon className="text-gray-400" />}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                        onInput={(e) => maxFieldLength(e, 50)}
                                        required
                                        name="newPassword2"
                                        onChange={onChangePass}
                                    />

                                    {changePassState.message && <Alert type={changePassState.messageType} message={changePassState.message} />}
                                </div>


                            </DialogContent>
                            <DialogActions>
                                <Button className="mr-4" onClick={e => setChangePassState({ ...changePassState, showChangePassDialog: false })}>CANCELAR</Button>
                                <Button className="bg-yellow text-dark-600" type="submit" autoFocus>ACEPTAR</Button>
                            </DialogActions>
                        </div>
                    </form>

                </Dialog>

                <div>
                    <Loading show={loading} />
                    <Toolbar title="Mi Cuenta" backUrl="/configuracion"></Toolbar>
                    <div className="main">
                        <div className="layout-compact grid grid-cols-1 gap-4">

                            <div className="grid grid-cols-1 gap-4">
                                <div className="text-right">
                                    <Button className="btn btn-yellow" onClick={e => setChangePassState({ ...changePassState, showChangePassDialog: true })} ><LockIcon className="" />Cambiar password</Button>
                                </div>
                                {changePassState.message && <Alert type={changePassState.messageType} message={changePassState.message} />}
                            </div>

                            <form className="grid gap-4" onSubmit={saveChanges} >

                                {user && (
                                    <div className="bg-dark-400 rounded-md p-4">
                                        <div className="grid grid-cols-1 gap-4">
                                            <p className="text-white font-semibold">Usuario principal</p>
                                            <p className="">{user.correo}</p>
                                            <TextField
                                                disabled={user.principal}
                                                label="Nombres"
                                                type="text"
                                                name="nombres"
                                                variant="outlined"
                                                onChange={onChangeUser}
                                                value={user.nombres}
                                                onInput={(e) => maxFieldLength(e, 60)}
                                                required
                                            />
                                            <TextField
                                                disabled={user.principal}
                                                label="Ap. Paterno"
                                                type="text"
                                                name="apPat"
                                                onChange={onChangeUser}
                                                value={user.apPat}
                                                variant="outlined"
                                                onInput={(e) => maxFieldLength(e, 45)}
                                                required
                                            />
                                            <TextField
                                                disabled={user.principal}
                                                label="Ap. Materno"
                                                type="text"
                                                name="apMat"
                                                variant="outlined"
                                                onChange={onChangeUser}
                                                value={user.apMat}
                                                onInput={(e) => maxFieldLength(e, 45)}
                                            />

                                            <TextField
                                                label="Celular"
                                                type="text"
                                                name="celular"
                                                variant="outlined"
                                                onChange={onChangeUser}
                                                value={user.celular || ""}
                                                onInput={(e) => onlyNumbers(e, 10)}
                                            />

                                            <Button type="submit" className="btn btn-yellow w-full mb-4">Guardar Cambios</Button>
                                            {user.message && <Alert type={user.messageType} message={user.message} />}
                                        </div>
                                    </div>
                                )}
                            </form>
                        </div>
                    </div>
                </div>
            </AdminTemplate>
        );
    };

    return render();
};

export default MiCuenta;

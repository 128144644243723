// NuevaPoliza
export const NUEVA_POLIZA_RESET_STATE = "NUEVA_POLIZA_RESET_STATE"
export const NUEVA_POLIZA_ONCHANGE = "NUEVA_POLIZA_ONCHANGE"
export const NUEVA_POLIZA_ONCHANGE_TYPE = "NUEVA_POLIZA_ONCHANGE_TYPE"
export const NUEVA_POLIZA_ONCHANGE_STEPPER = "NUEVA_POLIZA_ONCHANGE_STEPPER"
export const NUEVA_POLIZA_ONCHANGE_STEPPER_NEXT = "NUEVA_POLIZA_ONCHANGE_STEPPER_NEXT"
export const NUEVA_POLIZA_ONCHANGE_GENERALES = "NUEVA_POLIZA_ONCHANGE_GENERALES"
export const NUEVA_POLIZA_ONCHANGE_VEHICULO = "NUEVA_POLIZA_ONCHANGE_VEHICULO"
export const NUEVA_POLIZA_ONCHANGE_PRIMAS = "NUEVA_POLIZA_ONCHANGE_PRIMAS"
export const NUEVA_POLIZA_ONCHANGE_RECIBOS = "NUEVA_POLIZA_ONCHANGE_RECIBOS"
export const NUEVA_POLIZA_ONCHANGE_DETALLES = "NUEVA_POLIZA_ONCHANGE_DETALLES"
export const NUEVA_POLIZA_CALCULAR_RECIBOS = "NUEVA_POLIZA_CALCULAR_RECIBOS"
export const NUEVA_POLIZA_LOAD_CARATULA = "NUEVA_POLIZA_LOAD_CARATULA"
export const NUEVA_POLIZA_ONCHANGE_ASEGURADO = "NUEVA_POLIZA_ONCHANGE_ASEGURADO"
export const NUEVA_POLIZA_AGREGAR_ASEGURADO = "NUEVA_POLIZA_AGREGAR_ASEGURADO"
export const NUEVA_POLIZA_REMOVER_ASEGURADO = "NUEVA_POLIZA_REMOVER_ASEGURADO"
export const EDITAR_POLIZA = "EDITAR_POLIZA"
export const RENOVAR_POLIZA = "RENOVAR_POLIZA"
export const POLIZA_UPDATE_STATUS = "POLIZA_UPDATE_STATUS"

//CxC
export const CXC_RESET = "CXC_RESET"
export const CXC_UPDATE_ROOT = "CXC_UPDATE_ROOT"
export const CXC_UPDATE_SECTION = "CXC_UPDATE_SECTION"
export const CXC_UPDATE_STATUS = "CXC_UPDATE_STATUS"



// Claves
export const CLAVES_UPDATE_ROOT = "CLAVES_UPDATE_ROOT"
export const CLAVES_UPDATE_CLAVE = "CLAVES_UPDATE_CLAVE"
export const CLAVES_DELETE_CLAVE = "CLAVES_DELETE_CLAVE"
export const CLAVES_ADD_CLAVE = "CLAVES_ADD_CLAVE"


export const DUMMY = "DUMMY"
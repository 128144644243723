
import React, { useEffect, useState, useRef } from 'react'
import { addDays, format, parse } from '../utils/DateUtils';
import { FormControl, InputLabel, MenuItem, Select, TextField, ToggleButton, ToggleButtonGroup } from '@material-ui/core';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import AdminTemplate from '../template/AdminTemplate'
import APIInvoke from '../utils/APIInvoke';
import Toolbar from '../components/Toolbar';
import { Link, useHistory, useParams } from 'react-router-dom'
import Pagination from '../components/Pagination'
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import FavoriteIcon from '@mui/icons-material/Favorite';
import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import { numberFormat } from '../utils/NumberUtils';
import { onlySimpleCharacters } from '../utils/FormUtils'
import "@egjs/react-flicking/dist/flicking.css";
import "@egjs/react-flicking/dist/flicking-inline.css";
import Flicking from "@egjs/react-flicking";
import { Button } from '@mui/material';
import MailIcon from '@mui/icons-material/Mail';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ForumIcon from '@mui/icons-material/Forum';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import SearchIcon from '@mui/icons-material/Search';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import DalalaDialog from '../components/DalalaDialog';
import CerrarPoliza from './CerrarPoliza';
import Bitacora from './Bitacora';
import ConsultaPoliza from './ConsultaPoliza'
import Adjuntos from './Adjuntos';
import Correo from './Correo';
import CancelIcon from '@mui/icons-material/Cancel';

const Renovaciones = (props) => {

    const [loading, setLoading] = useState(false)
    const [loadingListado, setLoadingListado] = useState(false)

    const params = useParams()
    const history = useHistory()
    const polizaId = props.polizaId || params.polizaId

    const [openCerrarPolizaDialog, setOpenCerrarPolizaDialog] = useState({ show: false, id: null })
    const [openBitacoraDialog, setOpenBitacoraDialog] = useState({ show: false, id: null })
    const [openPolizaDialog, setOpenPolizaDialog] = useState({ show: false, id: null })
    const [openAdjuntosDialog, setOpenAdjuntosDialog] = useState({ show: false, id: null })
    const [openCorreoDialog, setOpenCorreoDialog] = useState({ show: false, id: null })

    const [polizas, setPolizas] = useState({
        items: [],
        totalItems: 0,
        currentPage: 1,
        totalPages: 0,
        pageSize: 0
    })

    const [listado, setListado] = useState({
        items: [],
        totalItems: 0,
        currentPage: 1,
        totalPages: 0,
        pageSize: 0
    })


    const [filters, setFilters] = useState({
        types: ['auto', 'salud', 'vida', 'diversos'],
        range: 0,
        clickRange: undefined,
    })

    const [filtersListado, setFiltersListado] = useState({
        field: "cliente",
        value: "",
        orderBy: "desc"

    })

    const fieldList = [
        { id: "cliente", value: "Cliente", type: "text", show: true },
        { id: "noPoliza", value: "Número de Póliza", type: "text", show: true },
        { id: "abreviacion", value: "Aseguradora", type: "text", show: true },
    ]

    const dateRangeScrollRef = useRef()
    const fieldRef = useRef()
    const valueRef = useRef()

    const ranges = [
        [-60, -1],     // -1
        [0, 3],        // 0
        [4, 9],         // 5
        [10, 24],        // 15
        [25, 45],       // 30
        [46, 60]        // 45
    ]

    const getDateRange = () => {
        return ranges.map(x => {
            const start = addDays(new Date(), x[0])
            const end = addDays(new Date(), x[1])
            return [`${start.getDate()} ${start.toLocaleDateString("es-ES", { month: "short" })}`, `${end.getDate()} ${end.toLocaleDateString("es-ES", { month: "short" })}`]
        })
    }

    useEffect(() => {
        fetchPolizas()
    }, [filters.types, filters.range])

    useEffect(() => {
        fetchListado(false)
    }, [listado.currentPage])

    useEffect(() => {
        if (filters.clickRange !== undefined) {
            fetchListado(false)
        }
    }, [filters.types, filters.range, filters.clickRange])


    const fetchPolizas = async () => {
        try {
            setLoading(true)

            let [start, end] = ranges[filters.range]
            start = format(addDays(new Date(), start), "yyyy-MM-dd")
            end = format(addDays(new Date(), end), "yyyy-MM-dd")
            const tipos = filters.types.length > 0 ? `&tipo=${filters.types.join(',')}` : '';

            let rawResponse = await APIInvoke.invokeGET(`/polizas/listar?fechaVigenciaA(bw)=${start},${end}${tipos}&order=fechaVigenciaA ASC&size=100`)
            let response = await rawResponse.json()
            setPolizas(response.body)

            dateRangeScrollRef.current.moveTo(filters.range)
        } catch (e) {
            console.log(e)
        } finally {
            setLoading(false)
        }
    }

    const fetchListado = async (changeCritery) => {
        try {
            setLoadingListado(true)

            const field = fieldList.find(x => x.id === filtersListado.field)

            let [start, end] = ranges[filters.range]
            start = format(addDays(new Date(), start), "yyyy-MM-dd")
            end = format(addDays(new Date(), end), "yyyy-MM-dd")

            let query = ""
            let orderBy = ""
            if ((field.type === "text") && filtersListado.value.trim().length !== 0) {
                query = `&${filtersListado.field}=${filtersListado.value}`

                if (field.id === "cliente") {
                    orderBy = `&order=CONCAT(cliente.nombres, ' ', cliente.apPat, ' ', cliente.apMat) ${filtersListado.orderBy}`
                } else if (field.id === "noPoliza") {
                    orderBy = `&order=noPoliza ${filtersListado.orderBy}`
                }
            } else if (field.type === "date" || field.type === "numeric") {
                if (field.id === "vigencia") {
                    orderBy = `&order=fechaVigenciaA ${filtersListado.orderBy}`
                } else if (field.id === "total") {
                    orderBy = `&order=total ${filtersListado.orderBy}`
                }
            }

            let rawResponse = null;
            let response = null;
            if ((field.type === "text") && filtersListado.value.trim().length !== 0) {
                rawResponse = await APIInvoke.invokeGET(`/polizas/listar?status=vigente${query}${orderBy}&page=${listado.currentPage}&size=10`)
                response = await rawResponse.json()
            } else {
                rawResponse = await APIInvoke.invokeGET(`/polizas/listar?fechaVigenciaA(bw)=${start},${end}&status=vigente${query}${orderBy}&page=${listado.currentPage}&size=10`)
                response = await rawResponse.json()
            }

            if (changeCritery) {
                setListado({ ...response.body, currentPage: 1 })
            } else {
                setListado(response.body)
            }

        } catch (e) {
            console.log(e)
        } finally {
            setLoadingListado(false)
        }
    }

    const onTypeChange = (e, newType) => {
        if (newType.length) {
            setFilters({
                ...filters,
                types: newType
            })
        }
    }

    const onRangeChange = (e, newRange) => {
        if (newRange || newRange === 0) {
            setFilters({
                ...filters,
                range: newRange,
                clickRange: filters?.clickRange ? false : true
            })

            setFiltersListado({
                ...filtersListado,
                value: ""
            })
        }
    }

    const getIcon = (tipo) => {
        switch (tipo) {
            case "Vida":
                return <PersonIcon />
            case "Auto":
                return <DirectionsCarIcon />
            case "Salud":
                return <FavoriteIcon />
            case "Diversos":
                return <HomeIcon />
        }
    }

    const renderTypeField = () => {

        const field = fieldList.find(x => x.id === filtersListado.field)

        if (field.show === false) return null

        if (field.type === "text") {
            return (
                <TextField
                    className="flex-1"
                    label="Buscar..."
                    type='text'
                    size="medium"
                    variant="outlined"
                    onInput={onlySimpleCharacters}
                    value={filtersListado.value}
                    onKeyPress={e => {
                        if (e.key == "Enter") fetchListado(true)
                    }}
                    onChange={e => {
                        setFiltersListado({
                            ...filtersListado,
                            value: e.target.value
                        })
                    }}
                />
            )
        } else if (field.type === "numeric") {
            return (
                <TextField
                    className="flex-1"
                    label="Buscar..."
                    type='number'
                    size="medium"
                    variant="outlined"
                    value={filtersListado.value}
                    onKeyPress={e => {
                        if (e.key == "Enter") fetchListado(true)
                    }}
                    onChange={e => {
                        setFiltersListado({
                            ...filtersListado,
                            value: e.target.value
                        })
                    }}
                />
            )
        }
        return null;
    }

    const handleEditRenov = (e, polizaId, status) => {
        e.preventDefault()
        history.push(`/polizas/nuevo`, { polizaId, action: "renovar",  backUrl: `/renovaciones` })

        /*
        history.push({
            pathname: "/polizas/nuevo",
            state: { backUrl: `/renovaciones` }
        }, { backUrl: `/renovaciones`, polizaId })
        */
    }

    const render = () => {
        return (
            <AdminTemplate loading={loading || loadingListado}>
                <DalalaDialog
                    title={"Adjuntos"}
                    open={openAdjuntosDialog.show}
                    handleClose={() => setOpenAdjuntosDialog({ show: false, id: null })}
                >
                    <Adjuntos modal={true} entidad={"polizas"} entidadId={openAdjuntosDialog.id} onCompleted={() => setOpenAdjuntosDialog({ show: false, id: null })} />
                </DalalaDialog>

                {/* Envio Correo */}
                <DalalaDialog
                    title={"Correo Electronico"}
                    open={openCorreoDialog.show}
                    handleClose={() => setOpenCorreoDialog({ show: false, id: null })}
                >
                    <Correo modal={true} entidad={"poliza"} entidadId={openCorreoDialog.id} correoPara={openCorreoDialog.correoPara} onCompleted={() => setOpenCorreoDialog({ show: false, id: null })} />
                </DalalaDialog>

                <DalalaDialog
                    title={"Cerrar Póliza"}
                    open={openCerrarPolizaDialog.show}
                    handleClose={() => setOpenCerrarPolizaDialog({ show: false, id: null })}
                >
                    <CerrarPoliza modal={true} polizaId={openCerrarPolizaDialog.id} onCompleted={() => setOpenCerrarPolizaDialog({ show: false, id: null })} />
                </DalalaDialog>

                <DalalaDialog
                    title={"Bitácora"}
                    open={openBitacoraDialog.show}
                    handleClose={() => setOpenBitacoraDialog({ show: false, id: null })}
                >
                    <Bitacora modal={true} entidad={"polizas"} entidadId={openBitacoraDialog.id} onCompleted={() => setOpenBitacoraDialog({ show: false, id: null })} />
                </DalalaDialog>

                <DalalaDialog
                    title={"Detalle de Póliza"}
                    open={openPolizaDialog.show}
                    handleClose={() => setOpenPolizaDialog({ show: false, id: null })}
                >
                    <ConsultaPoliza modal={true} polizaId={openPolizaDialog.id} onCompleted={() => setOpenPolizaDialog({ show: false, id: null })} />
                </DalalaDialog>

                <Toolbar title="Renovaciones" >
                </Toolbar>

                <div className="main center">
                    <div className="layout">
                        <div className="grid grid-cols-1 gap-2">

                            <div className="mb-8 text-center">
                                <p className="mb-4 text-lg">Días por vencer</p>
                                <div className="bg-gradient-to-r from-red via-yellow to-blue block lg:inline-block rounded-md mb-2 " style={{ padding: "2px" }}>
                                    <div className="bg-dark-600 rounded-md p-2">
                                        <ToggleButtonGroup
                                            value={filters.range}
                                            size="medium"
                                            exclusive
                                            onChange={onRangeChange}
                                            aria-label="text alignment"
                                            className="gradiant-range-group"
                                        >
                                            <ToggleButton value={0} aria-label="left aligned" className="w-12 h-8">
                                                <p>&lt;</p>
                                            </ToggleButton>
                                            <ToggleButton value={1} aria-label="centered" className="w-12 h-8">
                                                <p>0</p>
                                            </ToggleButton>
                                            <ToggleButton value={2} aria-label="right aligned" className="w-12 h-8">
                                                <p>5</p>
                                            </ToggleButton>
                                            <ToggleButton value={3} aria-label="justified" className="w-12 h-8">
                                                <p>15</p>
                                            </ToggleButton>
                                            <ToggleButton value={4} aria-label="justified" className="w-12 h-8">
                                                <p>30</p>
                                            </ToggleButton>
                                            <ToggleButton value={5} aria-label="justified" className="w-12 h-8" >
                                                <p>45</p>
                                            </ToggleButton>
                                        </ToggleButtonGroup>
                                    </div>

                                </div>

                                <div>
                                    <div className="inline-block " style={{ width: "370px", maxWidth: "100%" }}>
                                        <Flicking
                                            ref={dateRangeScrollRef}
                                            align="center"
                                            adaptive={true}
                                            disableOnInit={true}
                                            panelsPerView={3}
                                            index={filters.range}
                                            currentPanel={filters.range}>
                                            {getDateRange().map((dates, index) => {
                                                const styles = filters.range === index ? "py-0 text-sm mx-2 lg:mx-4 text-yellow panel flicking-panel" : "py-0 text-sm mx-2 lg:mx-4 text-gray-600 panel flicking-panel"
                                                return <p key={index} className={styles}>{`${dates[0]} - ${dates[1]}`}</p>
                                            })}
                                        </Flicking>
                                    </div>
                                </div>
                            </div>

                            <div className="relative mb-8">
                                <div className="hidden lg:block lg:absolute left-0 top-0 bottom-0 w-16 bg-gradient-to-r from-dark-600 to-transparent z-10" />
                                <div className="hidden lg:block lg:absolute right-0 top-0 bottom-0 w-16 bg-gradient-to-r from-transparent to-dark-600  z-10" />
                                {polizas.items.length > 0 && (
                                    <Flicking align="center" adaptive={true}>
                                        {
                                            polizas.items.map((poliza, index) => (
                                                <article className="bg-dark-400 rounded-md p-4 panel mr-4 grid grid-cols-1 gap-1 w-full lg:w-3/5 flicking-panel" key={index}>
                                                    <div className="flex flex-row ">
                                                        <div className="flex-1 flex items-center justify-start truncate">
                                                            <p className="text-base truncate ...">{poliza.contratante}</p>
                                                        </div>
                                                        <div>
                                                            <div className="bg-dark-600 capitalize p-1 rounded-md" >
                                                                <p className="pr-1  flex items-center text-sm">
                                                                    <span className="pl-1 flex items-center">{getIcon(poliza.tipo)}</span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="grid grid-cols-2 gap-2 mb-2">
                                                        <div className=" border-r  border-gray-800 ">
                                                            <p className="text-gray-800 text-sm">Poliza:</p>
                                                            <p className="text-base">{poliza.noPoliza}</p>
                                                        </div>
                                                        <div className="text-sm ">
                                                            <p className="text-gray-800 ">Aseguradora:</p>
                                                            <p>{poliza.aseguradoraAbre}</p>
                                                        </div>
                                                        <div className="text-sm lg:border-r border-gray-800">
                                                            <p className="text-gray-800 ">Vigencia:</p>
                                                            <p>{`${format(parse(poliza.finVigenciaA, "yyyy-MM-dd"), "dd MMM yy")} - ${format(parse(poliza.finVigenciaDe, "yyyy-MM-dd"), "dd MMM yy")}`}</p>
                                                        </div>
                                                        <div className="text-sm ">
                                                            <p className="text-gray-800 ">Total:</p>
                                                            <p>{numberFormat(poliza.total)}</p>
                                                        </div>
                                                    </div>

                                                    <div className="">
                                                        <p className="text-md mb-2">Opciones</p>
                                                        <div className="flex gap-2 lg:gap-2">
                                                            <Link className="no-underline" to="#" onClick={e => setOpenPolizaDialog({ show: true, id: poliza.polizaId })}>
                                                                <div className="bg-yellow h-10 w-10 flex items-center text-right rounded-md">
                                                                    <VisibilityIcon className="text-dark-600 ml-2"></VisibilityIcon>
                                                                </div>
                                                            </Link>

                                                            {
                                                                poliza.status === "terminada" || poliza.status === "vigente" ? (
                                                                    <Link className="no-underline" to="#" onClick={(e) => handleEditRenov(e, poliza.polizaId, poliza.status)}>
                                                                        <div className="bg-yellow h-10 w-10 text-right flex items-center rounded-md cursor-pointer" variant="contained" >
                                                                            <AutorenewIcon className="text-dark-600 ml-2"></AutorenewIcon>
                                                                        </div>
                                                                    </Link>)
                                                                    : (<div className="bg-dark-100 h-10 w-10 text-right flex items-center rounded-md cursor-not-allowed" variant="contained" >
                                                                        <AutorenewIcon className="text-dark-600 ml-2"></AutorenewIcon>
                                                                    </div>)
                                                            }



                                                            <Link className="no-underline" to="#" onClick={e => setOpenCerrarPolizaDialog({ show: true, id: poliza.polizaId })}>
                                                                <div className="bg-yellow h-10 w-10 flex items-center text-right rounded-md">
                                                                    <CancelIcon className="text-dark-600 ml-2"></CancelIcon>
                                                                </div>
                                                            </Link>
                                                            <Link className="no-underline" to="#" onClick={e => setOpenCorreoDialog({ show: true, id: poliza.polizaId, correoPara: poliza?.cliente?.correos?.map(x => x.correo).join(", ") || "" })}>
                                                                <div className="bg-yellow h-10 w-10 text-center flex items-center rounded-md">
                                                                    <MailIcon className="text-dark-600 ml-2"></MailIcon>
                                                                </div>
                                                            </Link>
                                                            <Link className="no-underline" to="#" onClick={e => setOpenBitacoraDialog({ show: true, id: poliza.polizaId })} >
                                                                <div className="bg-yellow h-10 w-10 flex items-center text-right rounded-md">
                                                                    <ForumIcon className="text-dark-600 ml-2"></ForumIcon>
                                                                </div>
                                                            </Link>
                                                            <Link className="no-underline" to="#" onClick={e => setOpenAdjuntosDialog({ show: true, id: poliza.polizaId })}>
                                                                <div className="bg-yellow h-10 w-10 text-center flex items-center rounded-md">
                                                                    <AttachFileIcon className="text-dark-600 ml-2"></AttachFileIcon>
                                                                </div>
                                                            </Link>
                                                        </div>

                                                    </div>
                                                </article>
                                            ))
                                        }
                                    </Flicking>
                                )}
                            </div>

                            {polizas.items.length === 0 && <p className="text-center mb-8">Sin resultados</p>}

                            <p className="mb-4 text-lg">Polizas • {listado.totalItems}</p>
                            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-4">
                                <div className="flex gap-2">
                                    <FormControl variant="outlined" size="medium" required className="flex-1">
                                        <InputLabel id="field" required>Buscar por</InputLabel>
                                        <Select
                                            onChange={e => {
                                                setFiltersListado({
                                                    ...filtersListado,
                                                    field: e.target.value,
                                                    value: "",
                                                    orderBy: "desc"
                                                })
                                            }}
                                            value={filtersListado.field}
                                            labelId="field"
                                            label="Buscar por"
                                            size="medium"
                                            name="field"
                                            required
                                        >
                                            {fieldList.map(item => <MenuItem key={item.id} value={item.id}>{item.value}</MenuItem>)}
                                        </Select>
                                    </FormControl>
                                    <Button
                                        className=" p-0 m-0 border-2 border-yellow border-solid"
                                        onClick={e => {
                                            setFiltersListado({
                                                ...filtersListado,
                                                orderBy: filtersListado.orderBy === "desc" ? "asc" : "desc"
                                            })
                                        }}>
                                        {filtersListado.orderBy === "desc" ? <ArrowDownwardIcon className="text-yellow" /> : <ArrowUpwardIcon className="text-yellow" />}
                                    </Button>
                                </div>

                                <div className="flex gap-2">
                                    {renderTypeField()}


                                    <Button className=" p-0 btn btn-yellow m-0" onClick={e => fetchListado(true)}>
                                        <SearchIcon className="text-dark-600 "></SearchIcon>
                                    </Button>
                                </div>
                            </div>

                            <div className="grid grid-cols-1 bg-dark-400 p-2 lg:p-4 gap-2 lg:gap-4 rounded-md">
                                {
                                    listado.items && listado.items.map(poliza => (
                                        <Link key={poliza.polizaId} className="no-underline" to="#" onClick={e => setOpenPolizaDialog({ show: true, id: poliza.polizaId })}>
                                            <article className="bg-dark-300 rounded-md p-4 ">
                                                <div className="flex flex-row">
                                                    <div className="flex-1">
                                                        <p>{poliza.contratante}</p>
                                                    </div>
                                                    <div>
                                                        <div className="bg-dark-600 capitalize p-1 lg:p-2 rounded-md" >
                                                            <p className="pr-1 ml-1 flex items-center text-sm">
                                                                <span className="flex items-center">{getIcon(poliza.tipo)}</span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="grid grid-cols-2 lg:grid-cols-4 gap-4">
                                                    <p className="text-sm">Poliza: {poliza.noPoliza}</p>
                                                    <p className="text-sm text-right">{poliza.aseguradoraAbre}</p>
                                                    <p className="text-sm col-span-2">Concepto: {poliza.concepto}</p>
                                                    <p className="text-sm lg:col-span-2">Vigencia:<br className="sm:hidden md:hidden" /> {`${format(parse(poliza.finVigenciaDe, "yyyy-MM-dd"), "dd MMM yy")} - ${format(parse(poliza.finVigenciaA, "yyyy-MM-dd"), "dd MMM yy")}`}</p>
                                                    <p className="text-sm">Total:<br className="sm:hidden md:hidden" /> {numberFormat(poliza.total)}</p>
                                                </div>
                                            </article>
                                        </Link>
                                    ))
                                }
                                <div>
                                    <Pagination totalItems={listado.totalItems} pages={listado.totalPages} page={listado.currentPage} onPageChange={newPage => setListado({ ...listado, currentPage: newPage })} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </AdminTemplate >
        )
    }

    return render()
}

export default Renovaciones
const ANT = new Array(45);
ANT[0] = "BUEI";
ANT[1] = "BUEY";
ANT[2] = "CACA";
ANT[3] = "CACO";
ANT[4] = "CAGA";
ANT[5] = "CAGO";
ANT[6] = "CAKA";
ANT[7] = "CAKO";
ANT[8] = "COGE";
ANT[9] = "COGA";
ANT[10] = "COJE";
ANT[11] = "COJI";
ANT[12] = "COJO";
ANT[13] = "CULO";
ANT[14] = "FETO";
ANT[15] = "GUEY";
ANT[16] = "JOTO";
ANT[17] = "KACA";
ANT[18] = "KAKO";
ANT[19] = "KAGA";
ANT[20] = "KAGO";
ANT[21] = "KOGE";
ANT[22] = "KOJO";
ANT[23] = "KAKA";
ANT[24] = "KULO";
ANT[25] = "LOCA";
ANT[26] = "LOCO";
ANT[27] = "LOKA";
ANT[28] = "LOKO";
ANT[29] = "MAME";
ANT[30] = "MAMO";
ANT[31] = "MEAR";
ANT[32] = "MEAS";
ANT[33] = "MEON";
ANT[34] = "MION";
ANT[35] = "MOCO";
ANT[36] = "MULA";
ANT[37] = "PEDA";
ANT[38] = "PEDO";
ANT[39] = "PENE";
ANT[40] = "PUTA";
ANT[41] = "PUTO";
ANT[42] = "QULO";
ANT[43] = "RATA";
ANT[44] = "RUIN";

const codigo = new Array(34);
codigo[0] = "1";
codigo[1] = "2";
codigo[2] = "3";
codigo[3] = "4";
codigo[4] = "5";
codigo[5] = "6";
codigo[6] = "7";
codigo[7] = "8";
codigo[8] = "9";
codigo[9] = "A";
codigo[10] = "B";
codigo[11] = "C";
codigo[12] = "D";
codigo[13] = "E";
codigo[14] = "F";
codigo[15] = "G";
codigo[16] = "H";
codigo[17] = "I";
codigo[18] = "J";
codigo[19] = "K";
codigo[20] = "L";
codigo[21] = "M";
codigo[22] = "N";
codigo[23] = "P";
codigo[24] = "Q";
codigo[25] = "R";
codigo[26] = "S";
codigo[27] = "T";
codigo[28] = "U";
codigo[29] = "V";
codigo[30] = "W";
codigo[31] = "X";
codigo[32] = "Y";
codigo[33] = "Z";

const aDelPerMoral = new Array(30);
aDelPerMoral[1] = " SA DE CV";
aDelPerMoral[2] = " S.A. DE C.V.";
aDelPerMoral[3] = " S.A DE C.V";
aDelPerMoral[4] = " S EN C";
aDelPerMoral[5] = " S EN C POR A";
aDelPerMoral[6] = " S DE RL";
aDelPerMoral[7] = " S DE RL DE IP";
aDelPerMoral[8] = " SNC";
aDelPerMoral[9] = " SNC ";
aDelPerMoral[10] = " SOFOL";
aDelPerMoral[11] = " SOFOL ";
aDelPerMoral[12] = " AFORE";
aDelPerMoral[13] = " AFORE ";
aDelPerMoral[14] = " SIEAFORE";
aDelPerMoral[15] = " SIEAFORE ";
aDelPerMoral[16] = " S DE RL MI";
aDelPerMoral[17] = " SA";
aDelPerMoral[18] = " SA ";
aDelPerMoral[19] = " CV";
aDelPerMoral[20] = " CV ";
aDelPerMoral[21] = " AC";
aDelPerMoral[22] = " AC ";
aDelPerMoral[23] = " SC";
aDelPerMoral[24] = " SC ";
aDelPerMoral[25] = " AF";
aDelPerMoral[26] = " AF ";
aDelPerMoral[27] = " AP";
aDelPerMoral[28] = " AP ";
aDelPerMoral[29] = " AR";
aDelPerMoral[30] = " AR ";



const calcularRFCMoral = function (nombre, nac) {
    var RFC = "";
    try {
        var intJ = 0;
        var strLetra1 = "";
        var strLetra2 = "";
        var strLetra3 = "";
        //Se quitan las palabras no deseadas de la cadena
        for (var intI = 1; intI <= 30; intI++) //  Len(aDelPerMoral)
        {
            intJ = nombre.indexOf(aDelPerMoral[intI]);
            if (intJ != 0) {
                nombre = nombre.replace(aDelPerMoral[intI], "");
            }
            if (intI <= 22) {
                intJ = nombre.indexOf(aDelPerMoral[intI]);
                if (intJ != 0) {
                    nombre = nombre.replace(aDelPerMoral[intI], "");
                }
            }
        }
        nombre = Trim(nombre) + " ";
        var strRegistro = nombre;
        if (nombre.indexOf(" ") == 0) {
            console.error("calcularRFCMoral", "No se puede calcular el RFC de una persona moral con los datos capturados");
            RFC = "";
            return RFC;
        }
        //Se obtiene el primer segmento de la cadena
        strLetra1 = Trim(strRegistro.substr(0, strRegistro.indexOf(" ") - 1));
        strRegistro = Trim(strRegistro.substr((strRegistro.indexOf(" ") + 1), strRegistro.length));
        //Se obtiene el segundo segmento de la cadena
        if (strRegistro.indexOf(" ") != -1) {
            strLetra2 = strRegistro.substr(0, strRegistro.indexOf(" ") - 1);
            strRegistro = strRegistro.substr((strRegistro.indexOf(" ") + 1), strRegistro.length);
        }
        else {
            strLetra2 = strRegistro.substr(0, 2);
            strRegistro = "";
            strLetra3 = "";
        }
        //Se obtiene el tercer segmento de la cadena
        if (strRegistro.length > 0)
            strLetra3 = strRegistro.substr(0, 1);
        //Si hay tres palabras
        if (strLetra3 != "" && strLetra2 != "" && strLetra1 != "")
            strRegistro = strLetra1.substr(0, 1) + strLetra2.substr(0, 1) + strLetra3.substr(0, 1);
        //Si hay dos palabras
        if (strLetra3 == "" && strLetra2 != "" && strLetra1 != "")
            strRegistro = strLetra1.substr(0, 1) + strLetra2.substr(0, 2);
        //Si hay solo una palabra
        if (strLetra3 == "" && strLetra2 == "" && strLetra1 != "")
            strRegistro = strLetra1.substr(0, 3);
        //Se concatena la fecha de RFC
        RFC = strRegistro;
        RFC += fechas(nac);
    }
    catch (error) {
        console.error("calcularRFCMoral", error);
    }
    return RFC;
};
const calcularRFC = function (paterno, materno, nombreC, nac) {
    var RFC = "";
    try {
        var cadena = PrepararCadena(paterno);
        var apePat = cadena;
        var i = checaApellidos(cadena);
        var caracter1 = cadena.substring(i, i + 1);
        var apeMat = "";
        var bandera = true;
        var existeVocal = false;
        //Validar si la cadena contiene alg?n caracter vocal.
        if (cadena.length == 1) {
            console.error("calcularRFC", 'Error en RFC, Apellido Paterno No válido');
            return "";
        }
        for (var i_1 = 1; i_1 <= cadena.length - 1; i_1++) {
            if ((cadena.substring(i_1, i_1 + 1) == "A") || (cadena.substring(i_1, i_1 + 1) == "E") || (cadena.substring(i_1, i_1 + 1) == "I") || (cadena.substring(i_1, i_1 + 1) == "O") || (cadena.substring(i_1, i_1 + 1) == "U")) {
                existeVocal = true;
                break;
            }
        }
        if (!existeVocal) {
            console.error("calcularRFC", "Error en RFC, No se encontró ninguna vocal");
            return "";
        }
        i = 0;
        var letra = "";
        while (bandera && i <= cadena.length - 1) {
            i++;
            letra = cadena.substring(i, i + 1);
            if ((letra == "A") || (letra == "E") || (letra == "I") || (letra == "O") || (letra == "U")) {
                bandera = false;
            }
        }
        caracter1 += letra;
        var caracter2 = "";
        if (materno == "") {
            caracter2 = "X";
        }
        else {
            cadena = PrepararCadena(materno);
            apeMat = cadena;
            var i_2 = checaApellidos(cadena);
            caracter2 = cadena.substring(i_2, i_2 + 1);
        }
        cadena = PrepararCadena(nombreC);
        var nombre = cadena;
        i = checaNombres(cadena);
        var caracter3 = cadena.substring(i, i + 1);
        RFC = caracter1 + caracter2 + caracter3;
        var palabra = RFC;
        caracter3 = altisonante(palabra, caracter3);
        RFC = caracter1 + caracter2 + caracter3;
        RFC += fechas(nac);
        var completo = apePat + " " + apeMat + " " + nombre;
        RFC = homoclaveRFC(completo, RFC);
    }
    catch (error) {
        console.error("calcularRFC", error);
    }
    return RFC;
}; //fin funcion rfc
const altisonante = function (palabra, caracter3) {
    try {
        for (var j = 0; j < ANT.length; j++) {
            if (palabra == ANT[j]) {
                caracter3 = "X";
            }
        }
    }
    catch (error) {
        console.error("altisonante", error);
    }
    return caracter3;
};
const fechas = function (nac) {
    var yy = "";
    var MM = "";
    var dd = "";
    try {
        yy = nac.substring(2, 4);
        MM = nac.substring(5, 7);
        dd = nac.substring(8, 10);
    }
    catch (error) {
        console.error("fechas", error);
    }
    return yy + MM + dd;
};
const PrepararCadena = function (evaluada) {
    var cad = "";
    try {
        var s = evaluada;
        var letra;
        var espacio = true;
        evaluada = Trim(s);
        evaluada = evaluada.toUpperCase();
        for (var i = 0; i < evaluada.length; i++) {
            letra = evaluada.charAt(i);
            if (letra != " ") {
                /*
            if(letra=="A"){letra="A";}
                if(letra=="E"){letra="E";}
                if(letra=="I"){letra="I";}
                if(letra=="O"){letra="O";}
                if(letra=="U"){letra="U";}
                if(letra=="U"){letra="U";}
           */
                if (letra.charCodeAt(0) == 193) {
                    letra = "A";
                }
                if (letra.charCodeAt(0) == 201) {
                    letra = "E";
                }
                if (letra.charCodeAt(0) == 205) {
                    letra = "I";
                }
                if (letra.charCodeAt(0) == 211) {
                    letra = "O";
                }
                if (letra.charCodeAt(0) == 218) {
                    letra = "U";
                }
                if (letra.charCodeAt(0) == 220) {
                    letra = "U";
                }
                cad += letra;
                espacio = true;
            }
            if ((letra == " ") && (espacio)) {
                cad += letra;
                espacio = false;
            }
        }
    }
    catch (error) {
        console.error("PrepararCadena", error);
    }
    return cad;
};
const Trim = function (s) {
    return LTrim(RTrim(s));
};
const RTrim = function (s) {
    var i = 0;
    var j = 0;
    for (var i = s.length - 1; i > -1; i--)
        if (s.substring(i, i + 1) != ' ') {
            j = i;
            break;
        }
    return s.substring(0, j + 1);
};
const LTrim = function (s) {
    var i = 0;
    var j = 0;
    for (i = 0; i <= s.length - 1; i++)
        if (s.substring(i, i + 1) != ' ') {
            j = i;
            break;
        }
    return s.substring(j, s.length);
};
const checaApellidos = function (cadena) {
    var i = 0;
    try {
        if ((cadena.substring(0, 4) == "DEL ") || (cadena.substring(0, 4) == "VAN ") || (cadena.substring(0, 4) == "VON ") || (cadena.substring(0, 4) == "MAC ")) {
            i = 4;
        }
        if (cadena.substring(0, 6) == "DE LA ") {
            i = 6;
        }
        else {
            if ((cadena.substring(0, 7) == "DE LAS ") || (cadena.substring(0, 7) == "DE LOS ")) {
                i = 7;
            }
            else {
                if ((cadena.substring(0, 3) == "DE ") || (cadena.substring(0, 3) == "MC ")) {
                    i = 3;
                }
            }
        }
        if (cadena.substring(0, 2) == "Y ") {
            i = 2;
        }
    }
    catch (error) {
        console.error("checaApellidos", error);
    }
    return i;
};
const checaNombres = function (cadena) {
    var i = 0;
    try {
        if (cadena.substring(0, 6) == "MARIA ") {
            i = 6;
            if (cadena.substring(6, 10) == "DEL ") {
                i = 10;
            }
            else {
                if ((cadena.substring(6, 13) == "DE LOS ") || (cadena.substring(6, 13) == "DE LAS ")) {
                    i = 13;
                }
                else {
                    if (cadena.substring(6, 9) == "DE ") {
                        i = 9;
                    }
                }
            }
        }
        if (cadena.substring(0, 5) == "JOSE ") {
            i = 5;
            if (cadena.substring(0, 8) == "JOSE DE ") {
                i = 8;
            }
        }
        if (cadena.substring(0, 4) == "MA. ") {
            i = 4;
        }
        if (cadena.substring(0, 3) == "MA ") {
            i = 3;
        }
    }
    catch (error) {
        console.error("checaNombres", error);
    }
    return i;
};
const homoclaveRFC = function (completo, RFC) {
    try {
        var temp = "0";
        var indice = 0;
        var car = "";
        while (indice < completo.length) {
            car = completo.charAt(indice);
            if ((car == "0") || (car == "1") || (car == "2") || (car == "3") || (car == "4") || (car == "5") || (car == "6") || (car == "7") || (car == "8") || (car == "9")) {
                car = (car.charCodeAt(0) - 48).toString();
            }
            else {
                if (car == " ") {
                    car = "0";
                }
                else {
                    if (car.charCodeAt(0) == 209) {
                        car = "10";
                    }
                    else {
                        if (car < "J") {
                            car = (car.charCodeAt(0) - 54).toString();
                        }
                        else {
                            if (car < "S") {
                                car = (car.charCodeAt(0) - 53).toString();
                            }
                            else {
                                car = (car.charCodeAt(0) - 51).toString();
                            }
                        }
                    }
                }
            }
            car = (Number(car) + 100).toString();
            car = car.substring(1, 3);
            temp += car;
            indice++;
        }
        var res = 0;
        indice = 0;
        while (indice < temp.length - 1) {
            var pri_1 = parseInt(temp.substring(indice, indice + 2));
            var seg_1 = parseInt(temp.substring(indice + 1, indice + 2));
            res += (pri_1 * seg_1);
            indice++;
        }
        var pri = Math.floor((res % 1000) / 34);
        var seg = Math.floor((res % 1000) % 34);
        car = codigo[pri];
        RFC += car;
        car = codigo[seg];
        RFC += car;
        temp = " ";
        seg = 0;
        indice = 0; //probablemente sea 0
        while (indice < RFC.length) {
            car = RFC.charAt(indice);
            // car=RFC.substring(indice, indice+1);
            if ((car == "0") || (car == "1") || (car == "2") || (car == "3") || (car == "4") || (car == "5") || (car == "6") || (car == "7") || (car == "8") || (car == "9")) {
                pri = car.charCodeAt(0) - 48;
            }
            else {
                if (car == " ") {
                    pri = 37;
                }
                else {
                    if (car.charCodeAt(0) == 209) {
                        pri = 24;
                    }
                    else {
                        if ((car == "A") || (car == "B") || (car == "C") || (car == "D") || (car == "E") || (car == "F") || (car == "G") || (car == "H") || (car == "I") || (car == "J") || (car == "K") || (car == "L") || (car == "M") || (car == "N")) {
                            pri = car.charCodeAt(0) - 55;
                        }
                        else {
                            pri = car.charCodeAt(0) - 54;
                        }
                    }
                }
            }
            seg += (pri * (13 - indice));
            indice++;
        }
        pri = seg % 11;
        if (pri == 0) {
            RFC += "0";
        }
        else {
            pri = 11 - pri;
            if (pri == 10) {
                RFC += "A";
            }
            else {
                if (pri == 0) {
                    RFC += "0";
                }
                else {
                    RFC += "" + pri;
                }
            }
        }
    }
    catch (error) {
        console.error("homoclaveRFC", error);
    }
    return RFC;
};

module.exports = {
    calcularRFCMoral,
    calcularRFC
}
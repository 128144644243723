
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Stepper, Typography } from '@mui/material';
import { Button, FormControl, IconButton, InputLabel, MenuItem, Select, TextField, ToggleButton, ToggleButtonGroup } from '@material-ui/core';
import Box from '@mui/material/Box';
import { CircularProgress } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import debounce from "lodash.debounce";
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import esLocale from 'date-fns/locale/es';
import FavoriteIcon from '@material-ui/icons/Favorite';
import HomeIcon from '@material-ui/icons/Home';
import NumberFormat from 'react-number-format';
import PersonIcon from '@material-ui/icons/Person';
import React, { useEffect, useState, useCallback, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Badge from '@mui/material/Badge';
import { addYear, parse, format } from '../utils/DateUtils';
import AdminTemplate from '../template/AdminTemplate'
import APIInvoke from '../utils/APIInvoke';
import { maxFieldLength, onlySimpleCharacters } from "../utils/FormUtils";
import {
    NUEVA_POLIZA_RESET_STATE,
    NUEVA_POLIZA_ONCHANGE,
    NUEVA_POLIZA_ONCHANGE_TYPE,
    NUEVA_POLIZA_ONCHANGE_STEPPER,
    NUEVA_POLIZA_ONCHANGE_STEPPER_NEXT,
    NUEVA_POLIZA_ONCHANGE_GENERALES,
    NUEVA_POLIZA_ONCHANGE_ASEGURADO,
    NUEVA_POLIZA_ONCHANGE_PRIMAS,
    NUEVA_POLIZA_ONCHANGE_VEHICULO,
    NUEVA_POLIZA_ONCHANGE_DETALLES,
    NUEVA_POLIZA_ONCHANGE_RECIBOS,
    NUEVA_POLIZA_LOAD_CARATULA,
    NUEVA_POLIZA_AGREGAR_ASEGURADO,
    NUEVA_POLIZA_REMOVER_ASEGURADO,
    EDITAR_POLIZA,
    RENOVAR_POLIZA

} from '../redux/redux-const'
import { Link, Route, Switch as SwitchRouter, useHistory } from "react-router-dom";
import Toolbar from '../components/Toolbar';
import { useParams } from 'react-router';
import FileUploader from '../components/FileUploader';
import config from '../config'
import { calcularRFC } from '../utils/RFCGenerator'
import Alert from '../components/Alert';
import DalalaDialog from '../components/DalalaDialog';
import NuevoCliente from './NuevoCliente';
import Switch from '@mui/material/Switch';
import { useLocation } from 'react-router-dom'
import Adjuntos from './Adjuntos';
import AttachmentIcon from '@mui/icons-material/Attachment';

const NuevaPoliza = (props) => {

    const location = useLocation();
    const { polizaId } = useParams()
    const dispatch = useDispatch()
    const history = useHistory()
    const state = useSelector(state => state.pages.poliza)
    const stepper = useSelector(state => state.pages.poliza.stepper)
    const [openAdjuntosDialog, setOpenAdjuntosDialog] = useState({ show: false, id: null })
    const [loading, setLoading] = useState(false);
    const [showResetDialog, setShowResetDialog] = useState(false)
    const [showClaveAgenteDialog, setShowClaveAgenteDialog] = useState({ show: false, message: "" })

    const [errorMessage, setErrorMessage] = useState({
        ok: null,
        message: ""
    })

    useEffect(() => {
        return () => {
            dispatch({ type: NUEVA_POLIZA_RESET_STATE })
        }
    }, [])

    useEffect(async () => {
        const id = location.state?.polizaId
        const action = location.state?.action || ""

        try {
            setLoading(true)
            if (action === "renovar") {
                const responses = await (await APIInvoke.invokeGET(`/polizas/${id}`)).json();
                dispatch({ type: RENOVAR_POLIZA, payload: responses.body })
            } else if (!isNaN(polizaId)) {
                const response = await (await APIInvoke.invokeGET(`/polizas/${polizaId}`)).json();
                dispatch({ type: EDITAR_POLIZA, payload: response.body })
            }
        } catch (e) {
            console.error(e)
        } finally {
            setLoading(false)
        }
    }, [])

    const changeStepper = (newStep) => {
        dispatch({
            type: NUEVA_POLIZA_ONCHANGE_STEPPER,
            payload: {
                activeStep: newStep
            }
        })
        history.push(`/polizas/${polizaId}${stepper.steps[newStep].path}`);
    }
    /* functions */

    const handleChangeTipos = (e, newType) => {
        let value = state.newType

        if (newType != null && newType.length) {
            dispatch({ type: NUEVA_POLIZA_ONCHANGE_TYPE, payload: { newType } })
        }
    };

    const onUpload = async (e) => {
        e.preventDefault()
        try {
            setLoading(true)
            const thisFiles = e.target.files[0]

            const body = new FormData();
            body.append('bucket', config.bucket.name);
            body.append('path', `polizas/${thisFiles.name}`);
            body.append('file', thisFiles)

            const request = {
                method: "POST",
                body
            }

            const uploading = await (await fetch(`${config.uploader.baseUrl}/storage/upload`, request)).json();

            if (uploading.ok !== true) {
                console.error(uploading.message)
                return
            }

            const caratulaRequest = {
                "fileName": thisFiles.name,
                "caratulaUrl": uploading.body.url
            }

            const caratulaRawResponse = await APIInvoke.invokePOST(`/polizas/caratula/preview`, caratulaRequest)
            const caratulaResponse = await caratulaRawResponse.json()
            if (caratulaResponse.ok === false) {
                if (caratulaResponse.code === 10) {
                    setShowClaveAgenteDialog({ show: true, message: `${caratulaResponse.message}, ¿Desea dar de alta la clave de agente en este momento?` })
                } else {
                    setErrorMessage({
                        ok: false,
                        message: caratulaResponse.message
                    })
                }

            }

            dispatch({ type: NUEVA_POLIZA_LOAD_CARATULA, payload: caratulaResponse.body })

        } catch (e) {
            console.error(e)
        } finally {
            setLoading(false)
            e.target.value = '';
        }
    }

    const renderUploadCaratula = () => {
        if (stepper.activeStep !== 0) return null
        if (state.generales.polizaId) return null

        return (
            <div className="mb-4 grid grid-cols-1">
                <FileUploader onUpload={onUpload} >
                    <p className="text-base lg:text-lg font-bold normal-case">Carga la póliza en PDF</p>
                    <p className="text-xs lg:text-sm normal-case"><span className="underline">Da clic aquí o arrastra el PDF</span> de la póliza que deseas procesar</p>
                </FileUploader>
            </div>
        )
    }

    const reset = (e) => {
        e.preventDefault()
        dispatch({ type: NUEVA_POLIZA_RESET_STATE })
        setShowResetDialog(false)
        history.push(`/polizas/nuevo`)
    }

    const render = () => {

        const toolbarComponent = state.generales.polizaId ? (
            <IconButton className="" size="small" onClick={e => setOpenAdjuntosDialog({ show: true, id: state.generales.polizaId })}>
                <AttachmentIcon className="text-yellow"></AttachmentIcon>
            </IconButton>
        ) : null
        return (
            <AdminTemplate loading={loading} modal={props.modal}>

                <Dialog
                    open={showClaveAgenteDialog.show}
                    onClose={e => setShowClaveAgenteDialog({ show: false, message: "" })}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Alerta</DialogTitle>
                    <DialogContent>
                        <DialogContentText className="text-gray-400" id="alert-dialog-description">{showClaveAgenteDialog.message}</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button className="mr-4" onClick={e => setShowClaveAgenteDialog({ show: false, message: "" })}>CANCELAR</Button>
                        <Link className="btn btn-yellow p-2 text-sm" to={{ pathname: "/claves", state: { backUrl: "/polizas/nuevo" } }} autoFocus>ACEPTAR</Link>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={showResetDialog}
                    onClose={e => setShowResetDialog(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Alerta</DialogTitle>
                    <DialogContent>
                        <DialogContentText className="text-gray-400" id="alert-dialog-description">Esta acción limpiará todo el formulario y perderás todos los datos capturadas ¿deséas continúar?</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button className="mr-4" onClick={e => setShowResetDialog(false)}>CANCELAR</Button>
                        <Button className="bg-red-500 text-white" onClick={reset} autoFocus>ACEPTAR</Button>
                    </DialogActions>
                </Dialog>


                <DalalaDialog
                    title={"Adjuntos"}
                    open={openAdjuntosDialog.show}
                    handleClose={() => setOpenAdjuntosDialog({ show: false, id: null })}
                >
                    <Adjuntos modal={true} entidad={"polizas"} entidadId={openAdjuntosDialog.id} onCompleted={() => setOpenAdjuntosDialog({ show: false, id: null })} />
                </DalalaDialog>

                <Toolbar title="Captura de Póliza" backUrl={`/polizas`} modal={props.modal} component={toolbarComponent} helpLink="https://appdalala.blogspot.com/2022/05/importacioncaptura-de-polizas.html">
                    <div className="layout py-2">
                        {renderUploadCaratula()}
                        <div className="text-left">
                            <div className="grid grid-4 grid-cols-1 border-dark-200 border-b pb-4">
                                <ToggleButtonGroup exclusive value={state.newType} onChange={handleChangeTipos} aria-label="device" className="flex justify-items-center">
                                    <ToggleButton value="vida" disabled={state.ramoBlocked} aria-label="Vida" className="pill pill-border border mr-1 lg:mr-4 md:mr-4 sm:mr-4 xs:mr-2 rounded-md w-20">
                                        <div className="grid grid-cols-1 justify-items-center gap-1 text-center p-2">
                                            <PersonIcon fontSize="large" />
                                            Vida
                                        </div>
                                    </ToggleButton>
                                    <ToggleButton value="autos" disabled={state.ramoBlocked} aria-label="Autos" className="pill pill-border border mr-1 lg:mr-4 md:mr-4 sm:mr-4 rounded-md w-20">
                                        <div>
                                            <DriveEtaIcon fontSize="large" />
                                            Autos
                                        </div>
                                    </ToggleButton>
                                    <ToggleButton value="salud" disabled={state.ramoBlocked} aria-label="Salud" className="pill pill-border border mr-1 lg:mr-4 md:mr-4 sm:mr-4 rounded-md w-20">
                                        <div>
                                            <FavoriteIcon fontSize="large" />
                                            Salud
                                        </div>
                                    </ToggleButton>
                                    <ToggleButton value="diversos" disabled={state.ramoBlocked} aria-label="Daños" className="pill pill-border border mr-1 lg:mr-4 md:mr-4  sm:mr-4 rounded-md w-20">
                                        <div>
                                            <HomeIcon fontSize="large" />
                                            Daños
                                        </div>
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </div>
                        </div>

                        <section className="grid gap-4 grid-cols-1 mt-4">

                            <Box sx={{ width: '100%' }}>
                                <Stepper
                                    activeStep={stepper.activeStep}
                                    alternativeLabel
                                    nonLinear
                                    className="stepper-rounded"
                                >
                                    {stepper.steps.map((step, index) => (
                                        <Step
                                            key={step.label}
                                            completed={step.completed}
                                            active={false}
                                        >
                                            <StepLabel
                                                StepIconComponent={({ }) => {
                                                    let styles = "disabled"
                                                    if (index < stepper.lastStep) {
                                                        styles = "enable"
                                                    }
                                                    if (index === stepper.activeStep) {
                                                        styles = "active"
                                                    }

                                                    return (
                                                        <IconButton
                                                            aria-label="delete"
                                                            size="large"
                                                            className={styles}
                                                            color="primary"
                                                            onClick={e => changeStepper(index)}

                                                            disabled={index > stepper.lastStep}
                                                        >
                                                            {step.icon}
                                                        </IconButton>
                                                    )
                                                }}>
                                                <p className={index === stepper.activeStep ? "text-yellow" : "text-white"}>{step.label}</p>
                                            </StepLabel>
                                        </Step>
                                    ))}
                                </Stepper>
                            </Box>
                        </section>
                    </div>
                </Toolbar>

                <div className="main center">
                    <div className="layout-compact">
                        <SwitchRouter>
                            <Route path={`/polizas/:polizaId`} render={() => <DatosGenerales loading={loading} setLoading={setLoading} />} exact strict />
                            <Route path={`/polizas/:polizaId/vehiculo`} render={() => <Vehiculo loading={loading} setLoading={setLoading} />} exact strict />
                            <Route path={`/polizas/:polizaId/asegurado`} render={() => <Asegurados loading={loading} setLoading={setLoading} />} exact strict />
                            <Route path={`/polizas/:polizaId/asegurados`} render={() => <Asegurados loading={loading} setLoading={setLoading} />} exact strict />
                            <Route path={`/polizas/:polizaId/diversos`} render={() => <Diversos loading={loading} setLoading={setLoading} />} exact strict />
                            <Route path={`/polizas/:polizaId/primas`} render={() => <Primas loading={loading} setLoading={setLoading} />} exact strict />
                            <Route path={`/polizas/:polizaId/recibos`} render={() => <Recibos loading={loading} setLoading={setLoading} />} exact strict />
                        </SwitchRouter>
                    </div>

                    <div className="text-center my-8">
                        <Button onClick={e => setShowResetDialog(true)} type="button" className="text-yellow">Volver a comenzar</Button>
                    </div>
                </div>



                {
                    errorMessage.ok === false && (
                        <Dialog
                            open={errorMessage.ok === false}
                            onClose={e => setErrorMessage(true)}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">Alerta</DialogTitle>
                            <DialogContent>
                                <DialogContentText className="text-gray-400" id="alert-dialog-description">{errorMessage.message}</DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button className="mr-4" onClick={e => setErrorMessage(true)}>CANCELAR</Button>
                            </DialogActions>
                        </Dialog>
                    )
                }
            </AdminTemplate >
        )
    }

    return render()
}

const DatosGenerales = (props) => {

    const dispatch = useDispatch()
    const { polizaId } = useParams()
    const history = useHistory();

    const state = useSelector(state => state.pages.poliza)
    const currency = useSelector(state => state.pages.poliza.currency)
    const aseguradoras = useSelector(state => state.pages.poliza.aseguradoras)
    const formaPago = useSelector(state => state.pages.poliza.formaPago)
    const contratantes = useSelector(state => state.pages.poliza.contratantes)

    const [openClienteDialog, setOpenClienteDialog] = useState({ show: false, cliente: null })

    const [open, setOpen] = useState(false);
    const [contratantesLoading, setContratantesLoading] = useState(false)

    useEffect(async () => {
        try {
            props.setLoading(true)
            if (aseguradoras.length > 0 || currency.length > 0) {
                return
            }
            const aseguradoraResponse = await (await APIInvoke.invokeGET(`/aseguradoras/claves`)).json();

            const currencyResponse = await (await APIInvoke.invokeGET(`/monedas`)).json();
            dispatch({
                type: NUEVA_POLIZA_ONCHANGE, payload: {
                    currency: currencyResponse.body,
                    aseguradoras: aseguradoraResponse.body
                }
            })
        } catch (e) {
            console.error(e);
        } finally {
            props.setLoading(false)
        }
    }, [])

    useEffect(async () => {
        if (!state.generales.aseguradora) return;

        const claves = aseguradoras.find((item) => item.aseguradora.id === state.generales.aseguradora)?.claves || []

        if (claves.length === 1) {
            dispatch({ type: NUEVA_POLIZA_ONCHANGE_GENERALES, payload: { agente: claves[0].id } })
        } else {
            dispatch({ type: NUEVA_POLIZA_ONCHANGE_GENERALES, payload: { claves } })
        }

    }, [state.generales.aseguradora, aseguradoras])

    const fetchContratante = async (e) => {

        try {
            setContratantesLoading(true)
            const newValue = e.target.value
            if (newValue.length <= 3 || null) {
                dispatch({ type: NUEVA_POLIZA_ONCHANGE, payload: { contratantes: [] } })
            } else {
                const searchResponse = await (await APIInvoke.invokeGET(`/clientes?nombre=${e.target.value}&size=20&order=nombres asc`)).json()
                dispatch({ type: NUEVA_POLIZA_ONCHANGE, payload: { contratantes: searchResponse.body.items } })
            }
        } catch (e) {
            console.error(e)
        } finally {
            setContratantesLoading(false)
        }

    }

    const fetchContratanteDebounce = useCallback(debounce(fetchContratante, 500), []);

    const onChange = (e) => {
        let value = e.target.value;
        const name = e.target.name;

        let payload = { [e.target.name]: value }

        if (name === "formaPago") {
            const parcialidades = formaPago.find((e) => e.key === value).parcialidades;
            payload = {
                ...payload,
                recibos: parcialidades
            }
        }
        dispatch({ type: NUEVA_POLIZA_ONCHANGE_GENERALES, payload })
    }

    const onChangeContratante = (event, newValue) => {
        try {
            dispatch({ type: NUEVA_POLIZA_ONCHANGE_GENERALES, payload: { contratanteId: newValue.id, contratante: newValue } })
        } catch (error) {
            console.log("error en autocompletado: ", error)
        }
    }

    const onSubmit = (e) => {
        e.preventDefault()
        //validate()
        dispatch({ type: NUEVA_POLIZA_ONCHANGE, payload: { ramoBlocked: true } })
        dispatch({ type: NUEVA_POLIZA_ONCHANGE_STEPPER_NEXT })

        const activeStep = state.stepper.activeStep
        const currentStep = state.stepper.steps[activeStep + 1]
        history.push(`/polizas/${polizaId}${currentStep.path}`);
    }

    const onCompletedCliente = (cliente) => {
        const contratante = {
            id: cliente.id,
            nombres: cliente.nombres,
            apPat: cliente.apPat || "",
            apMat: cliente.apMat || "",
            fecNacimiento: cliente.fecNacimiento || "",
            fecRegistro: cliente.fecRegistro || "",
            fiscal: cliente.personalidad || null,
            rfc: cliente.rfc || "",
            edoCivil: cliente.edoCivil || "",
            sexo: cliente.sexo || "",
            status: cliente.status || "",
            direcciones: cliente.direccion || "",
            celulares: cliente.celulares || "",
            correos: cliente.correos || "",
            esRobot: cliente.esRobot
        }
        dispatch({ type: NUEVA_POLIZA_ONCHANGE_GENERALES, payload: { contratante, contratanteId: cliente.id } })
        setOpenClienteDialog({ show: false, cliente })
    }

    const prepareOpenClienteDialog = (e) => {
        const contratante = state.generales.contratante

        if (state.generales.contratante === null) {
            const cliente = {
                id: "",
                personalidad: "fisica",
                nombres: "",
                apPat: "",
                apMat: "",
                sexo: "",
                fecNacimiento: null,
                rfc: "",
                cp: "",
                edoCivil: "",
                direccionId: null,
                direccion: "",
                colonia: "",
                tipoDireccion: null,
                celular: "",
                celularId: null,
                correo: "",
                correoId: null,
                tipoCorreo: null
            }
            setOpenClienteDialog({ show: true, cliente })
        } else {
            const cliente = {
                id: contratante.id,
                personalidad: contratante.fiscal || "fisica",
                nombres: contratante.nombres,
                apPat: "",
                apMat: "",
                sexo: contratante.sexo,
                fecNacimiento: contratante.fecNacimiento,
                rfc: contratante.rfc,
                cp: contratante.cp || "",
                edoCivil: contratante.edoCivil,
                direccionId: null,
                direccion: contratante.direccion,
                colonia: contratante.direccion?.colonia || "",
                tipoDireccion: contratante.tipoDireccion || "",
                celular: "",
                celularId: null,
                correo: "",
                correoId: null,
                tipoCorreo: null,
            }
            setOpenClienteDialog({ show: true, cliente })
        }
    }

    const render = () => {
        const claves = aseguradoras.find((item) => item.aseguradora.id === state.generales.aseguradora)?.claves || []
        const cliente = state.generales || {}

        return (
            <>
                <DalalaDialog
                    title={"Captura de Cliente"}
                    open={openClienteDialog.show}
                    handleClose={() => setOpenClienteDialog({ show: false, cliente: null })}
                >
                    <NuevoCliente modal={true} cliente={openClienteDialog.cliente} onCompleted={onCompletedCliente} />
                </DalalaDialog>


                <form onSubmit={onSubmit} >

                    <div className="grid grid-cols-1 gap-4">

                        <div className="flex flex-row gap-4">

                            <Autocomplete
                                id="contratante"
                                name="contratante"
                                className="flex-1"
                                required
                                clearText="clearText"
                                closeText="closeText"
                                noOptionsText="Sin resultados"
                                loadingText="Cargando..."
                                openText="openText"
                                open={open}
                                value={state.generales.contratante}
                                onOpen={() => setOpen(true)}
                                onClose={() => setOpen(false)}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                getOptionLabel={(option) => `${option.nombres} ${option.apPat} ${option.apMat}`}
                                options={contratantes}
                                onInput={fetchContratanteDebounce}
                                loading={contratantesLoading}
                                onChange={onChangeContratante}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Contratante"
                                        required
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <React.Fragment>
                                                    {contratantesLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                    {params.InputProps.endAdornment}
                                                </React.Fragment>
                                            ),
                                        }}
                                    />
                                )}
                            />
                            <Badge color="error" variant="standard" badgeContent={cliente.contratante?.esRobot ? " " : 0} >
                                <Button className=" p-0 btn btn-yellow m-0" onClick={e => prepareOpenClienteDialog()}>
                                    <SupervisedUserCircleIcon className="text-dark-600" fontSize="large"></SupervisedUserCircleIcon>
                                </Button>
                            </Badge>
                        </div>

                        <FormControl variant="outlined" size="medium" required >
                            <InputLabel id="aseguradoras" required>Aseguradora</InputLabel>
                            <Select
                                labelId="aseguradoras"
                                label="Aseguradora"
                                size="medium"
                                name="aseguradora"
                                defaultValue=""
                                required
                                value={state.generales.aseguradora}
                                onChange={onChange}>
                                <MenuItem disabled value="" ><em>Seleccione</em></MenuItem>
                                {aseguradoras.map(item => (
                                    <MenuItem key={item.aseguradora.id} value={item.aseguradora.id}>{item.aseguradora.aseguradora}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <FormControl variant="outlined" size="medium" required>
                            <InputLabel id="agente" required>Clave de Agente</InputLabel>
                            <Select
                                labelId="agente"
                                label="agente"
                                size="medium"
                                name="agente"
                                defaultValue=""
                                value={state.generales.agente}
                                onChange={onChange}
                            >
                                <MenuItem disabled value=""><em>Seleccione</em></MenuItem>
                                {claves.map((a) => (
                                    <MenuItem key={a.id} value={a.id}>{a.clave}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>

                    <div className="grid grid-cols-2 gap-4 mt-4">
                        <FormControl variant="outlined" size="medium" required >
                            <InputLabel id="moneda" required>Moneda</InputLabel>
                            <Select
                                labelId="moneda"
                                label="moneda"
                                size="medium"
                                name="moneda"
                                value={state.generales.moneda}
                                onChange={onChange}
                            >
                                <MenuItem disabled value=""><em>Seleccione</em></MenuItem>
                                {currency.map(i => (
                                    <MenuItem key={i.id} value={i.id}>{i.moneda}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <FormControl variant="outlined" size="medium" required>
                            <InputLabel id="formaPago" required>Forma de Pago</InputLabel>
                            <Select
                                disabled={polizaId != "nuevo" || state.recibos?.length > 0}
                                labelId="formaPago"
                                label="pago"
                                size="medium"
                                name="formaPago"
                                value={state.generales.formaPago}
                                onChange={onChange}
                            >
                                <MenuItem disabled value=""><em>Seleccione</em></MenuItem>
                                {formaPago.map(m => (
                                    <MenuItem key={m.key} value={m.key}>{m.value}</MenuItem>
                                ))}

                            </Select>
                        </FormControl>
                    </div>

                    <div className="grid grid-cols-1 gap-4 mt-4">
                        <TextField
                            label="No. de Póliza"
                            type="text"
                            variant="outlined"
                            name="poliza"
                            value={state.generales.poliza.toUpperCase()}
                            onChange={onChange}
                            required
                            onInput={(e) => maxFieldLength(e, 50)}
                        />
                        <TextField
                            label="Concepto"
                            type="text"
                            variant="outlined"
                            name="concepto"
                            value={state.generales.concepto}
                            onChange={onChange}
                            required
                            onInput={(e) => maxFieldLength(e, 100)}
                        />
                    </div>

                    <div className="grid grid-cols-2 gap-4 mt-4">
                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
                            <DatePicker
                                name="fecInicial"
                                label="Vigencia Inicial"
                                value={state.generales.fecInicial}
                                onChange={(newValue) => {
                                    dispatch({ type: NUEVA_POLIZA_ONCHANGE_GENERALES, payload: { fecInicial: newValue, fecFinal: addYear(newValue, +1) } })
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
                            <DatePicker
                                name="fecFinal"
                                label="Vigencia Final"
                                inputFormat="dd/MM/yyyy"
                                value={state.generales.fecFinal}
                                onChange={(newValue) => {
                                    dispatch({ type: NUEVA_POLIZA_ONCHANGE_GENERALES, payload: { fecFinal: newValue } })
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>

                    </div>

                    <div className="grid grid-cols-1 gap-4 mt-4">
                        <TextField
                            label="Plan/Paquete"
                            type="text"
                            variant="outlined"
                            name="plan"
                            value={state.generales.plan}
                            onChange={onChange}
                            required
                            onInput={(e) => maxFieldLength(e, 50)}
                        />
                    </div>

                    <div className="text-center mt-8">
                        <div className="grid grid-cols-1 gap-4 mt-4">
                            <div>
                                <Button disabled={(!state.generales.contratante) || (!state.generales.aseguradora) || (!state.generales.agente) || (!state.generales.moneda)
                                    || (!state.generales.formaPago) || (state.generales.poliza === '') || (state.generales.concepto === '') || (state.generales.plan === '')} type="submit" className="btn btn-yellow text-dark w-full">Continuar</Button>
                            </div>
                        </div>
                    </div>
                </form>
            </>
        )
    }

    return render()
}

const Primas = (props) => {

    const { polizaId } = useParams()
    const dispatch = useDispatch()
    const history = useHistory();

    const state = useSelector(state => state.pages.poliza);

    useEffect(() => {
        if (state.newType !== "vida") {
            const taza = 0.16;
            //I.V.A. = (PN + D + F) * .16
            const iva = ((state.primas.primaNeta || 0) + (state.primas.derecho || 0) + (state.primas.financiamiento || 0)) * taza;

            //PT = PN + D + I.V.A. + F
            const primaTotal = (state.primas.primaNeta || 0) + (state.primas.derecho || 0) + (iva || 0) + (state.primas.financiamiento || 0)

            dispatch({ type: NUEVA_POLIZA_ONCHANGE_PRIMAS, payload: { iva } })
            dispatch({ type: NUEVA_POLIZA_ONCHANGE_PRIMAS, payload: { primaTotal } })
        } else {
            const primaTotal = (state.primas.primaNeta || 0) + (state.primas.derecho || 0) + (state.primas.iva || 0) + (state.primas.financiamiento || 0)

            dispatch({ type: NUEVA_POLIZA_ONCHANGE_PRIMAS, payload: { primaTotal } })
        }
    }, [state.primas.primaNeta, state.primas.derecho, state.primas.financiamiento])

    useEffect(() => {
        //suma todo si es que cambia IVA manualmente
        const primaTotal = (state.primas.primaNeta || 0) + (state.primas.derecho || 0) + (state.primas.iva || 0) + (state.primas.financiamiento || 0)

        dispatch({ type: NUEVA_POLIZA_ONCHANGE_PRIMAS, payload: { primaTotal } })
    }, [state.primas.iva])


    const onChange = (e) => {
        let value = e.target.value
        const name = e.target.name


        if (name === "primaNeta" || name === "derecho" || name === "financiamiento" || name === "iva" || name === "primaTotal") {
            value = (value || "").replaceAll(",", "")
            if (isNaN(value)) {
                value = 0
            } else {
                value = parseFloat(value)
            }
        }
        dispatch({ type: NUEVA_POLIZA_ONCHANGE_PRIMAS, payload: { [e.target.name]: value } })
    }

    const cancelar = (e) => {
        e.preventDefault()
        dispatch({ type: NUEVA_POLIZA_RESET_STATE })
        history.push(`/polizas/nuevo`)
    }

    const onSubmit = async (e) => {
        e.preventDefault()
        /* if (state.recibos.length === 0) { */
        try {
            if (polizaId == "nuevo") {
                props.setLoading(true)
                const requestRecibos = {
                    formaPago: state.generales.formaPago,
                    modelo: state.newType,
                    pagos: state.generales.recibos,
                    fechaVigenciaDe: format(state.generales.fecInicial, "yyyy-MM-dd"),
                    fechaVigenciaA: format(state.generales.fecFinal, "yyyy-MM-dd"),
                    primaNeta: state.primas.primaNeta,
                    derecho: state.primas.derecho,
                    recargo: state.primas.financiamiento,
                    iva: state.primas.iva,
                    primaTotal: state.primas.primaTotal,
                    primaPrimerRecibo: 0,
                    primaSubsecuenteRecibo: 0,
                    /* fecPago: recibo.fecPago ? parse(recibo.fecPago, "yyyy-MM-dd") : "",
                    folioPago: recibo.folioPago,
                    status: recibo.status,
                    moneda: recibo.moneda */
                }
                const recibosResponse = await (await APIInvoke.invokePOST(`/recibos/calcular`, requestRecibos)).json()

                const reciboPost = recibosResponse.body.map(recibo => {
                    return {
                        serie: recibo.serie,
                        primaNeta: recibo.prima,
                        derecho: recibo.derecho,
                        financiamiento: recibo.recargo,
                        iva: recibo.iva,
                        primaTotal: recibo.total,
                        fecInicial: parse(recibo.fecVigDe, "yyyy-MM-dd"),
                        fecFinal: parse(recibo.fecVigA, "yyyy-MM-dd"),
                        vencimiento: parse(recibo.fecVence, "yyyy-MM-dd"),
                        totalSerie: recibo.totalSerie,
                        fecPago: recibo.fecPago ? parse(recibo.fecPago, "yyyy-MM-dd") : null,
                        folioPago: recibo.folioPago,
                        status: recibo.status,
                        moneda: state.generales.moneda
                    }
                })
                dispatch({ type: NUEVA_POLIZA_ONCHANGE, payload: { recibos: reciboPost } })
            }

        } catch (e) {
            console.error("error en requestRecibos", e)
        } finally {
            props.setLoading(false)
        }
        /*  } */
        dispatch({ type: NUEVA_POLIZA_ONCHANGE_STEPPER_NEXT })
        history.push(`/polizas/${polizaId}/recibos`);
    }

    const render = () => {
        return (
            <form onSubmit={onSubmit}>
                <div className="grid grid-cols-1 gap-4">
                    <div className="grid grid-cols-1 gap-4 mt-4">
                        <NumberFormat
                            name={"primaNeta"}
                            value={state.primas.primaNeta}
                            onChange={onChange}
                            customInput={TextField}
                            onFocus={e => e.target.select()}
                            label="Prima Neta"
                            variant="outlined"
                            size="medium"
                            inputProps={{ style: { textAlign: 'right' } }} // the change is here
                            type="text"
                            decimalScale={2}
                            fixedDecimalScale={true}
                            thousandSeparator={","}
                            thousandSeparator={true}
                            allowNegative={false}
                            isNumericString={true}
                            required
                        />

                        <NumberFormat
                            name={"derecho"}
                            value={state.primas.derecho}
                            onChange={onChange}
                            customInput={TextField}
                            onFocus={e => e.target.select()}
                            label="Derecho"
                            variant="outlined"
                            size="medium"
                            inputProps={{ style: { textAlign: 'right' } }} // the change is here
                            type="text"
                            decimalScale={2}
                            fixedDecimalScale={true}
                            thousandSeparator={","}
                            thousandSeparator={true}
                            allowNegative={false}
                            isNumericString={true}
                        />


                        <NumberFormat
                            name={"financiamiento"}
                            value={state.primas.financiamiento}
                            onChange={onChange}
                            customInput={TextField}
                            onFocus={e => e.target.select()}
                            label="Financiamiento"
                            variant="outlined"
                            size="medium"
                            inputProps={{ style: { textAlign: 'right' } }} // the change is here
                            type="text"
                            decimalScale={2}
                            fixedDecimalScale={true}
                            thousandSeparator={","}
                            thousandSeparator={true}
                            allowNegative={true}
                            isNumericString={true}
                        />

                        <NumberFormat
                            name={"iva"}
                            value={state.primas.iva}
                            onChange={onChange}
                            customInput={TextField}
                            onFocus={e => e.target.select()}
                            label="I.V.A"
                            variant="outlined"
                            size="medium"
                            inputProps={{ style: { textAlign: 'right' } }} // the change is here
                            type="text"
                            decimalScale={2}
                            fixedDecimalScale={true}
                            thousandSeparator={","}
                            thousandSeparator={true}
                            allowNegative={false}
                            isNumericString={true}
                            required
                        />

                        <NumberFormat
                            name={"primaTotal"}
                            value={state.primas.primaTotal}
                            onChange={onChange}
                            customInput={TextField}
                            onFocus={e => e.target.select()}
                            label="Prima Total"
                            variant="outlined"
                            size="medium"
                            inputProps={{ style: { textAlign: 'right' } }} // the change is here
                            type="text"
                            decimalScale={2}
                            fixedDecimalScale={true}
                            thousandSeparator={","}
                            thousandSeparator={true}
                            allowNegative={false}
                            isNumericString={true}
                            required
                        />
                    </div>
                </div>

                <div className="text-center mt-8">
                    <div className="grid grid-cols-1 gap-4 mt-4">
                        <div>
                            <Button disabled={(state.primas.primaNeta === 0) || (state.primas.primaTotal === 0)} type="submit" className="btn btn-yellow w-full">
                                Continuar
                            </Button>
                        </div>
                    </div>
                </div>

            </form >
        )
    }

    return render()
}

const Recibos = (props) => {
    const dispatch = useDispatch()
    const { polizaId } = useParams()
    const state = useSelector(state => state.pages.poliza)
    const history = useHistory();

    const [expanded, setExpanded] = useState("panel0")
    const [message, setMessage] = useState(null)

    useEffect(async () => {
        if (!isNaN(polizaId)) {
            try {
                props.setLoading(true)
                /*const response = await (await APIInvoke.invokeGET(`/polizas/${polizaId}`)).json();
                dispatch({ type: EDITAR_POLIZA, payload: response.body })*/
            } catch (e) {
                console.error(e);
            } finally {
                props.setLoading(false)
            }
        }
        return () => {
            dispatch({ type: NUEVA_POLIZA_RESET_STATE })
        }
    }, [])

    const onChangeAccordion = (panel) => (e, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    }

    const onChangeRecibos = (e, index) => {
        let value = e.target.value
        let payload = { [e.target.name]: value, index }
        dispatch({ type: NUEVA_POLIZA_ONCHANGE_GENERALES, payload })
    }

    const onSubmit = async (e) => {
        e.preventDefault()
        dispatch({ type: NUEVA_POLIZA_ONCHANGE, payload: { ramoBlocked: true } })
        try {
            props.setLoading(true)

            const ramos = {
                auto: 1,
                autos: 1,
                salud: 3,
                vida: 5,
                diversos: 7
            };

            let aseguradosRequest = null;

            if (state.newType === "salud" || state.newType === "vida") {
                aseguradosRequest = state.asegurados.map((asegurado, index) => {
                    if (state.newType === "vida") {
                        return {
                            id: asegurado?.id || null,
                            parentesco: index === 0 ? 'titular' : 'otro',
                            nombres: asegurado.name,
                            apPat: asegurado.apPat,
                            apMat: asegurado.apMat,
                            fecNacimiento: asegurado.nacimiento ? format(asegurado.nacimiento, "yyyy-MM-dd") : null,
                            genero: asegurado.genero,
                            rfc: asegurado.rfc
                        }
                    } else {
                        return {
                            id: asegurado?.id || null,
                            parentesco: asegurado.parentesco,
                            nombres: asegurado.name,
                            apPat: asegurado.apPat,
                            apMat: asegurado.apMat,
                            fecNacimiento: asegurado.nacimiento ? format(asegurado.nacimiento, "yyyy-MM-dd") : null,
                            genero: asegurado.genero,
                            rfc: asegurado.rfc
                        }
                    }
                })
            }

            let vehiculoRequest = null;
            if (state.newType === "auto" || state.newType === "autos") {
                vehiculoRequest = {
                    id: state.vehiculo?.id || null,
                    modelo: state.vehiculo.descripcion?.modelo || 0,
                    marca: state.vehiculo.descripcion?.marca || "",
                    tipo: state.vehiculo.descripcion?.tipo || "",
                    amis: state.vehiculo.descripcion?.amis || "",
                    descripcion: state.vehiculo.descripcion?.descripcion || "",
                    noSerie: state.vehiculo?.noSerie,
                    placas: state.vehiculo?.placas
                };
            }

            const request = {
                serie: state.generales.recibos,
                prima: state.primas.primaNeta,
                ajuste: 0, /* va en 0 */
                derecho: state.primas.derecho,
                recargo: state.primas.financiamiento,
                iva: state.primas.iva,
                total: state.primas.primaTotal,
                comision: 0, /* va en 0 */
                fechaVigenciaDe: format(state.generales.fecInicial, "yyyy-MM-dd"),
                fechaVigenciaA: format(state.generales.fecFinal, "yyyy-MM-dd"),
                fecVence: format(state.recibos[state.recibos.length - 1].vencimiento, "yyyy-MM-dd"),
                fecPago: null, /* null */
                tipoCambio: 1,
                totalSerie: 1,
                observacion: "",
                status: "vigente",
                formaPago: state.generales.formaPago,
                noPoliza: state.generales.poliza,
                claveAgente: state.generales.agente,
                ramo: ramos[state.newType],
                moneda: state.generales.moneda,
                concepto: state.generales.concepto,
                texto: state.detalles?.texto,
                texto: state?.texto,
                paquete: state.generales.plan,
                cliente: {
                    id: state.generales.contratante.id,
                    nombres: state.generales.contratante?.nombres,
                    apPat: state.generales.contratante?.apPat,
                    apMat: state.generales.contratante?.apMat,
                    fecNacimiento: "",
                    fecRegistro: "",
                    fiscal: state.generales.contratante?.fiscal,
                    rfc: state.generales.contratante?.rfc,
                    edoCivil: state.generales.contratante?.edoCivil,
                    sexo: state.generales.contratante?.sexo
                },
                asegurados: state.newType === "vida" || state.newType === "salud" ? aseguradosRequest : undefined,
                vehiculo: state.newType === "auto" || state.newType === "autos" ? vehiculoRequest : undefined,
                recibos: state.recibos.map(recibo => {
                    return {
                        id: recibo.id,
                        total: recibo.primaTotal,
                        observacion: "",
                        recargo: recibo.financiamiento,
                        fecVigDe: format(recibo.fecInicial, "yyyy-MM-dd"),
                        fecPago: recibo.fecPago ? format(recibo.fecPago, "yyyy-MM-dd") : null,
                        tipoCambio: state.generales.moneda,
                        folioPago: recibo.folioPago ? recibo.folioPago : "",
                        status: recibo.status,
                        folioAseguradora: "",
                        derecho: recibo.derecho,
                        prima: recibo.primaNeta,
                        serie: recibo.serie,
                        comision: 0,
                        fecConciliacion: null,
                        iva: recibo.iva,
                        fecVigA: format(recibo.fecFinal, "yyyy-MM-dd"),
                        totalSerie: recibo.serie,
                        folioConciliacion: "",
                        fecVence: format(recibo.vencimiento, "yyyy-MM-dd"),
                        ajuste: 0,
                    }
                }),
                caratula: state.caratula && {
                    caratulaUrl: state.caratula.caratulaUrl,
                    filename: state.caratula.filename
                }
            }
            if (polizaId != "nuevo") {
                const response = await (await APIInvoke.invokePUT(`/polizas/${polizaId} `, request)).json()
                if (response.ok === true) {
                    history.push({ pathname: `/poliza/${response.body.id}`, state: { backUrl: `/polizas` } })
                    dispatch({ type: NUEVA_POLIZA_RESET_STATE })
                } else if (response.ok === false) {
                    setMessage({
                        message: response.message,
                        type: "error"
                    })
                }
            } else {
                const response = await (await APIInvoke.invokePOST(`/polizas`, request)).json()
                if (response.ok === true) {
                    history.push({ pathname: `/poliza/${response.body.id}`, state: { backUrl: `/polizas` } })
                    dispatch({ type: NUEVA_POLIZA_RESET_STATE })
                } else if (response.ok === false) {
                    setMessage({
                        message: response.message,
                        type: "error"
                    })
                }
            }

        } catch (e) {
            console.error("error al generar poliza", e)
        } finally {
            props.setLoading(false)
        }
    }

    const cancelar = (e) => {
        e.preventDefault()
        dispatch({ type: NUEVA_POLIZA_RESET_STATE })
        history.push(`/polizas/nuevo`)
    }

    const onChangeRecibo = (e, index) => {
        const name = e.target.name
        let value = e.target.value

        if (name === "primaNeta" || name === "derecho" || name === "financiamiento" || name === "iva" || name === "primaTotal") {
            value = (value || "").replaceAll(",", "")
            if (isNaN(value)) {
                value = 0
            } else {
                value = parseFloat(value)
            }
        } else if (name === "status") {
            value = e.target.checked ? "pagado" : "pendiente"
        }

        dispatch({ type: NUEVA_POLIZA_ONCHANGE_RECIBOS, payload: { [name]: value, serie: index + 1 } })
    }


    const render = () => {

        return (
            <form onSubmit={onSubmit}>
                <div className="grid grid-cols-1 gap-4">
                    <div className="grid grid-cols-2 gap-4 mt-4">

                        {state.generales.recibos != 1 && (
                            <>
                                <NumberFormat
                                    disabled
                                    name={"primerRecibo"}
                                    value={state.recibos[0]?.primaTotal}
                                    onChange={onChangeRecibos}
                                    customInput={TextField}
                                    onFocus={e => e.target.select()}
                                    label="Prima Total Primer Recibo"
                                    variant="outlined"
                                    size="medium"
                                    inputProps={{ style: { textAlign: 'right' } }} // the change is here
                                    type="text"
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    thousandSeparator={","}
                                    thousandSeparator={true}
                                    allowNegative={false}
                                    isNumericString={true}
                                    required
                                />
                                <NumberFormat
                                    disabled
                                    name={"reciboSubsecuente"}
                                    value={state.recibos[1]?.primaTotal}
                                    onChange={onChangeRecibos}
                                    customInput={TextField}
                                    onFocus={e => e.target.select()}
                                    label="Prima Total Recibo Subsecuente"
                                    variant="outlined"
                                    size="medium"
                                    inputProps={{ style: { textAlign: 'right' } }} // the change is here
                                    type="text"
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    thousandSeparator={","}
                                    thousandSeparator={true}
                                    allowNegative={false}
                                    isNumericString={true}
                                    required
                                />
                            </>
                        )}
                    </div>

                    <div className="grid grid-cols-1 gap-4">
                        <div className="grid grid-cols-1">
                            {state.recibos.map((recibo, index) => {
                                return (
                                    <>
                                        <Accordion
                                            expanded={expanded === `panel${index}`}
                                            onChange={onChangeAccordion(`panel${index}`)}
                                            className="bg-dark-400" key={index}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon className="text-white" />}
                                                aria-controls="panel1bh-content"
                                                id="panel1bh-header">
                                                <div className="flex justify-between">
                                                    <p className="text-white">Recibo {recibo.serie}</p>
                                                    {recibo.status === "pagado" && (
                                                        <p className="ml-4 uppercase font-bold text-blue-200"> {recibo.status}</p>
                                                    )}
                                                </div>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <div className="grid grid-cols-1 gap-4 mb-2">
                                                    <NumberFormat
                                                        disabled={index != 0}
                                                        name={"primaNeta"}
                                                        value={recibo.primaNeta}
                                                        onChange={e => onChangeRecibo(e, index)}
                                                        customInput={TextField}
                                                        onFocus={e => e.target.select()}
                                                        label="Prima Neta"
                                                        variant="outlined"
                                                        inputProps={{ style: { textAlign: 'right' } }} // the change is here
                                                        type="text"
                                                        decimalScale={2}
                                                        fixedDecimalScale={true}
                                                        thousandSeparator={","}
                                                        thousandSeparator={true}
                                                        allowNegative={false}
                                                        isNumericString={true}
                                                    /* disabled={state.generales.recibos === 1} */
                                                    />
                                                </div>
                                                <div className="grid grid-cols-1 gap-4 mb-2">
                                                    <NumberFormat
                                                        disabled={index != 0}
                                                        name={"derecho"}
                                                        value={recibo.derecho}
                                                        onChange={e => onChangeRecibo(e, index)}
                                                        customInput={TextField}
                                                        onFocus={e => e.target.select()}
                                                        label="Derecho"
                                                        variant="outlined"
                                                        inputProps={{ style: { textAlign: 'right' } }} // the change is here
                                                        type="text"
                                                        decimalScale={2}
                                                        fixedDecimalScale={true}
                                                        thousandSeparator={","}
                                                        thousandSeparator={true}
                                                        allowNegative={false}
                                                        isNumericString={true}
                                                    />
                                                </div>
                                                <div className="grid grid-cols-1 gap-4 mb-2">
                                                    <NumberFormat
                                                        disabled={index != 0}
                                                        name={"financiamiento"}
                                                        value={recibo.financiamiento}
                                                        onChange={e => onChangeRecibo(e, index)}
                                                        customInput={TextField}
                                                        onFocus={e => e.target.select()}
                                                        label="Financiamiento"
                                                        variant="outlined"
                                                        inputProps={{ style: { textAlign: 'right' } }} // the change is here
                                                        type="text"
                                                        decimalScale={2}
                                                        fixedDecimalScale={true}
                                                        thousandSeparator={","}
                                                        thousandSeparator={true}
                                                        allowNegative={true}
                                                        isNumericString={true}
                                                    />
                                                </div>
                                                <div className="grid grid-cols-1 gap-4 mb-2">
                                                    <NumberFormat
                                                        disabled={index != 0}
                                                        name={"iva"}
                                                        value={recibo.iva}
                                                        customInput={TextField}
                                                        onFocus={e => e.target.select()}
                                                        label="I.V.A"
                                                        variant="outlined"
                                                        inputProps={{ style: { textAlign: 'right' } }} // the change is here
                                                        type="text"
                                                        decimalScale={2}
                                                        fixedDecimalScale={true}
                                                        thousandSeparator={","}
                                                        thousandSeparator={true}
                                                        allowNegative={false}
                                                        isNumericString={true}
                                                        required
                                                    />
                                                </div>
                                                <div className="grid grid-cols-1 gap-4 mb-2">
                                                    <NumberFormat
                                                        disabled
                                                        name={"primaTotal"}
                                                        value={recibo.primaTotal}
                                                        customInput={TextField}
                                                        onFocus={e => e.target.select()}
                                                        label="Prima Total"
                                                        variant="outlined"
                                                        inputProps={{ style: { textAlign: 'right' } }} // the change is here
                                                        type="text"
                                                        decimalScale={2}
                                                        fixedDecimalScale={true}
                                                        thousandSeparator={","}
                                                        thousandSeparator={true}
                                                        allowNegative={false}
                                                        isNumericString={true}
                                                        required
                                                    />
                                                </div>

                                                <div className="grid grid-cols-2 gap-4 mt-4">
                                                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
                                                        <DatePicker
                                                            name="fecInicial"
                                                            label="Vigencia Inicial"
                                                            inputFormat="dd/MM/yyyy"
                                                            value={recibo.fecInicial}
                                                            onChange={newValue => dispatch({ type: NUEVA_POLIZA_ONCHANGE_RECIBOS, payload: { fecInicial: newValue, serie: index + 1 } })}
                                                            renderInput={(params) => <TextField {...params} />}
                                                        />
                                                    </LocalizationProvider>
                                                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
                                                        <DatePicker
                                                            name="fecFinal"
                                                            label="Vigencia Final"
                                                            inputFormat="dd/MM/yyyy"
                                                            value={recibo.fecFinal}
                                                            onChange={newValue => dispatch({ type: NUEVA_POLIZA_ONCHANGE_RECIBOS, payload: { fecFinal: newValue, serie: index + 1 } })}
                                                            renderInput={(params) => <TextField {...params} />}
                                                        />
                                                    </LocalizationProvider>
                                                </div>
                                                <div className="grid grid-cols-2 gap-4 mt-4">
                                                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
                                                        <DatePicker
                                                            name="vencimiento"
                                                            label="Vencimiento"
                                                            inputFormat="dd/MM/yyyy"
                                                            value={recibo.vencimiento}
                                                            onChange={newValue => dispatch({ type: NUEVA_POLIZA_ONCHANGE_RECIBOS, payload: { vencimiento: newValue, serie: index + 1 } })}
                                                            renderInput={(params) => <TextField {...params} />}
                                                        />
                                                    </LocalizationProvider>
                                                </div>

                                                <div className="mt-4 border-b border-gray-800" ></div>

                                                <div className="grid grid-cols-2 gap-4 mt-4">

                                                    <div className="flex align-middle items-center">
                                                        <Switch
                                                            name="status"
                                                            checked={recibo.status === "pagado"}
                                                            onChange={e => onChangeRecibo(e, index)}
                                                        />
                                                        <p className="ml-2 capitalize text-white justify-start"> Pagar Recibo</p>
                                                    </div>
                                                </div>

                                                {recibo.status === "pagado" && (
                                                    <div className="grid grid-cols-2 gap-4 mt-6">
                                                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
                                                            <DatePicker
                                                                name="fecPago"
                                                                label="Pago"
                                                                inputFormat="dd/MM/yyyy"
                                                                value={recibo.fecPago}
                                                                onChange={newValue => dispatch({ type: NUEVA_POLIZA_ONCHANGE_RECIBOS, payload: { fecPago: newValue, serie: index + 1 } })}
                                                                renderInput={(params) => <TextField {...params} />}
                                                            />
                                                        </LocalizationProvider>

                                                        <div className="grid grid-cols-1 gap-4 mb-2">
                                                            <TextField
                                                                name="folioPago"
                                                                label="Folio Pago"
                                                                value={recibo.folioPago}
                                                                onChange={e => onChangeRecibo(e, index)}
                                                                onInput={event => onlySimpleCharacters(event, 50)}
                                                                className="col-span-2"
                                                            />
                                                        </div>
                                                    </div>
                                                )}
                                            </AccordionDetails>
                                        </Accordion>
                                    </>
                                )
                            })}
                        </div>
                    </div>
                </div>

                <div className="text-center mt-8">
                    {message && <Alert type={message.type} message={message.message} />}
                    <div className="grid grid-cols-1 gap-4 mt-4">
                        <Button disabled={(state.recibos.primerRecibo === 0) || (state.recibos.reciboSubsecuente === 0)} type="submit" className="btn btn-yellow w-full">Guardar Póliza</Button>
                    </div>
                </div>
            </form >
        )
    }

    return render()
}

/* Vehiculo */

const Vehiculo = (props) => {

    const { polizaId } = useParams()
    const dispatch = useDispatch()
    const history = useHistory();
    const state = useSelector(state => state.pages.poliza)
    const vehiculos = useSelector(state => state.pages.poliza.vehiculos)

    const [descLoading, setDescLoading] = useState(false)
    const [open, setOpen] = useState(false);

    const [descriptionVehicle, setDescriptionVehicle] = useState("")

    const onChangeYear = (e) => {
        dispatch({ type: NUEVA_POLIZA_ONCHANGE_VEHICULO, payload: { "modelo": e.target.value } })
    }

    const onSubmit = (e) => {
        e.preventDefault()
        //validate()
        dispatch({ type: NUEVA_POLIZA_ONCHANGE, payload: { ramoBlocked: true } })
        dispatch({ type: NUEVA_POLIZA_ONCHANGE_STEPPER_NEXT })
        history.push(`/polizas/${polizaId}/primas`);
    }

    const anio = (year) => {
        const anio = parseInt(new Date().getFullYear())
        const max = anio + 1
        const min = anio - 41
        var list = [];
        for (var year = max; year > min; year--) {
            list.push(year);
        }
        return list
    }

    const fetchDescripcion = async (e) => {
        try {
            setDescLoading(true)
            const newValue = e.target.value
            if (newValue.length <= 3 || null) {
                dispatch({ type: NUEVA_POLIZA_ONCHANGE, payload: { vehiculos: [] } })
                return
            }

            const searchDesc = await (await APIInvoke.invokeGET(`/autos/buscador/modelos?description=${e.target.value}&model=${state.vehiculo.modelo}&company=29&pickup=0`)).json()

            dispatch({ type: NUEVA_POLIZA_ONCHANGE, payload: { vehiculos: searchDesc } })
        } catch (e) {
            console.error(e)
        } finally {
            setDescLoading(false)
        }
    }

    const fetchDescDebounce = useCallback(debounce(fetchDescripcion, 500), []);

    const onChangeDesc = (event, newValue) => {
        if (newValue != null) {
            dispatch({ type: NUEVA_POLIZA_ONCHANGE_VEHICULO, payload: { "descId": newValue.id || "", "descripcion": newValue } })
        }
    }

    const onChangeManual = (event) => {
        setDescriptionVehicle(event.target.value)
        dispatch({ type: NUEVA_POLIZA_ONCHANGE_VEHICULO, payload: { "descripcion": event.target.value } })
    }

    const onChange = (e) => {
        const value = e.target.value
        dispatch({ type: NUEVA_POLIZA_ONCHANGE_VEHICULO, payload: { [e.target.name]: value } })
    }

    const render = () => {
        return (
            <form onSubmit={onSubmit}>
                <div className="grid grid-cols-1 gap-4">
                    <div className="grid grid-cols-3 gap-4">
                        <FormControl variant="outlined" size="medium" >
                            <InputLabel id="anio" required>Modelo</InputLabel>
                            <Select labelId="anio" label="Año" value={state.vehiculo.modelo} onChange={onChangeYear}>
                                <MenuItem disabled ><em>Seleccione</em></MenuItem>
                                {anio().map(year => <MenuItem key={year} value={year}>{year}</MenuItem>)}
                            </Select>
                        </FormControl>
                    </div>

                    <Autocomplete
                        id="descripcion"
                        name="descripcion"
                        required
                        freeSolo
                        clearText="clearText"
                        closeText="closeText"
                        noOptionsText="Sin resultados"
                        loadingText="Cargando..."
                        openText="openText"
                        open={open}
                        value={state.vehiculo.descripcion}
                        onOpen={() => setOpen(true)}
                        onClose={() => setOpen(false)}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        getOptionLabel={(option) => option.marca ? `${option.marca} ${option.tipo} ${option.amis} ${option.descripcion}` : state.vehiculo.descripcion.descripcion}
                        options={vehiculos}
                        onInput={fetchDescDebounce}
                        loading={descLoading}
                        onChange={onChangeDesc}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Descripción"
                                name="descripcion"
                                required
                                //onChange={onChangeManual}
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <React.Fragment>
                                            {descLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                    ),
                                }}
                            />
                        )}
                    />

                    <TextField
                        label="No. de Serie"
                        type="text"
                        variant="outlined"
                        name="noSerie"
                        value={state.vehiculo.noSerie.toUpperCase()}
                        onChange={onChange}
                        onInput={(e) => maxFieldLength(e, 17)}
                        required
                    />
                    <TextField
                        label="Placas"
                        type="text"
                        variant="outlined"
                        name="placas"
                        value={state.vehiculo.placas}
                        onChange={onChange}
                        onInput={(e) => maxFieldLength(e, 10)}
                    />
                </div>
                <div className="text-center mt-8">
                    <div className="grid grid-cols-1 gap-4 mt-4">
                        <Button disabled={(!state.vehiculo.modelo) || (state.vehiculo.descripcion === '') || (state.vehiculo.noSerie === '')} type="submit" className="btn btn-yellow w-full">Continuar</Button>
                    </div>
                </div>
            </form>
        )
    }

    return render()
}

/* Salud */
const Asegurados = (props) => {

    const { polizaId } = useParams()
    const dispatch = useDispatch()
    const parentesco = useSelector(state => state.pages.poliza.parentesco)
    const genero = useSelector(state => state.pages.poliza.genero)
    const state = useSelector(state => state.pages.poliza)

    const history = useHistory();

    const [message, setMessage] = useState(null)

    const onChangeAccordion = (index) => (e, isExpanded) => {
        //setExpanded(isExpanded ? panel : false);
        //dispatch({ type: NUEVA_POLIZA_ONCHANGE_ASEGURADO, payload: { index, expanded: isExpanded } })

    }

    const onSubmit = (e) => {
        e.preventDefault()
        if (!validate()) return false
        dispatch({ type: NUEVA_POLIZA_ONCHANGE, payload: { ramoBlocked: true } })
        dispatch({ type: NUEVA_POLIZA_ONCHANGE_STEPPER_NEXT })
        history.push(`/polizas/${polizaId}/primas`);
    }

    const validate = () => {
        const asegurados = state.asegurados
        for (let c = 0; c < asegurados.length; c++) {
            const asegurado = asegurados[c]

            if (asegurado.parentesco === null) {
                dispatch({ type: NUEVA_POLIZA_ONCHANGE_ASEGURADO, payload: { index: c, expanded: true, message: "El parentesto es obligatorio" } })
                return false
            }

            if (asegurado.name === null || asegurado.name.trim().length === 0) {
                dispatch({ type: NUEVA_POLIZA_ONCHANGE_ASEGURADO, payload: { index: c, expanded: true, message: "El nombre es obligatorio" } })
                return false
            }
            if (asegurado.apPat === null || asegurado.apPat.trim().length === 0) {
                dispatch({ type: NUEVA_POLIZA_ONCHANGE_ASEGURADO, payload: { index: c, expanded: true, message: "El apellido paterno es obligatorio" } })
                return false
            }
            if (asegurado.genero === null || asegurado.genero.trim().length === 0) {
                dispatch({ type: NUEVA_POLIZA_ONCHANGE_ASEGURADO, payload: { index: c, expanded: true, message: "El género es obligatorio" } })
                return false
            }
            dispatch({ type: NUEVA_POLIZA_ONCHANGE_ASEGURADO, payload: { index: c, message: null } })
        }
        return true;
    }

    const validateAsegurado = (asegurado) => {

    }

    function isValid(d) {
        return d instanceof Date && !isNaN(d)
    }

    const onChange = (e, index) => {

        let value
        let name
        let payload

        if (e instanceof Date) {
            value = e
            name = "nacimiento"
            payload = { [name]: value, index }
        } else {
            value = e.target.value
            name = e.target.name
            payload = { [e.target.name]: value, index }
        }
        if (name === "name" || name === "apPat" || name === "apMat" || name === "nacimiento") {
            try {
                const asegurado = state.asegurados[index]
                const date = name === "nacimiento" ? e : asegurado.nacimiento;
                const dates = `${date.getFullYear()}/${String((date.getMonth() + 1)).padStart(2, "0")}/${String(date.getDate()).padStart(2, "0")}`
                if (!(!date || asegurado.name.length === 0 || asegurado.apPat.length === 0)) {
                    const rfc = calcularRFC(asegurado.apPat, asegurado.apMat, asegurado.name, dates)
                    payload = {
                        ...payload,
                        rfc
                    }
                }
            } catch (e) {
                console.log("invalida RFC", e)
            }
        }
        dispatch({ type: NUEVA_POLIZA_ONCHANGE_ASEGURADO, payload })
    }

    const agregarAsegurado = (e) => {
        dispatch({ type: NUEVA_POLIZA_AGREGAR_ASEGURADO })
    }

    const removerAsegurado = (index) => {
        dispatch({ type: NUEVA_POLIZA_REMOVER_ASEGURADO, payload: { index } })
    }

    const getParentesco = (index) => {
        if (index === 0) return parentesco

        let parentescos = parentesco.filter(x => x.key !== "titular")

        const conyuge = state.asegurados.find(x => x.parentesco === "conyuge")

        if (conyuge) {
            parentescos = parentescos.filter(x => x.key !== "conyuge")
        }
        return parentescos
    }

    const cancelar = (e) => {
        e.preventDefault()
        dispatch({ type: NUEVA_POLIZA_RESET_STATE })
        history.push(`/polizas/nuevo`)
    }

    const render = () => {
        return (
            <form onSubmit={onSubmit} >
                {state.asegurados.map((asegurado, index) => {
                    return (
                        <Accordion
                            key={index}
                            expanded={asegurado.expanded}
                            onChange={onChangeAccordion(index)}
                            className="bg-dark-400 rounded-md"  >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon className="text-white" />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header">
                                {state.newType !== 'vida' && (
                                    <Typography sx={{ width: "33%", flexShrink: 0 }}>
                                        <p className="capitalize">{parentesco.find(x => x.key === asegurado.parentesco)?.value || 'Por Definir'}</p>
                                    </Typography>
                                )}
                                {state.newType === 'vida' && (
                                    <Typography sx={{ width: "33%", flexShrink: 0 }}>
                                        <p className="capitalize">{index === 0 ? 'Titular' : 'Otro'}</p>
                                    </Typography>
                                )}
                            </AccordionSummary>
                            <AccordionDetails>
                                <div key={index} className="grid grid-cols-1 gap-4">
                                    {state.newType !== 'vida' && (
                                        <FormControl variant="outlined" size="medium" required>
                                            <InputLabel id="parentesco" required>Parentesco</InputLabel>
                                            <Select
                                                disabled={asegurado.parentesco === "titular"}
                                                labelId="parentesco"
                                                label="parentesco"
                                                size="medium"
                                                name="parentesco"
                                                value={asegurado.parentesco}
                                                onChange={e => onChange(e, index)}
                                            >

                                                <MenuItem disabled value=""><em>Seleccione</em></MenuItem>

                                                {getParentesco(index).map(r => (
                                                    <MenuItem key={r.key} value={r.key}>{r.value}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    )}
                                    <TextField
                                        label="Nombres"
                                        type="text"
                                        variant="outlined"
                                        name="name"
                                        value={asegurado.name}
                                        onChange={e => onChange(e, index)}
                                        onInput={(e) => maxFieldLength(e, 20)}
                                        required
                                    />
                                    <TextField
                                        label="Apellido Paterno"
                                        type="text"
                                        variant="outlined"
                                        name="apPat"
                                        value={asegurado.apPat}
                                        onChange={e => onChange(e, index)}
                                        onInput={(e) => maxFieldLength(e, 20)}
                                        required
                                    />
                                    <TextField
                                        label="Apellido Materno"
                                        type="text"
                                        variant="outlined"
                                        name="apMat"
                                        value={asegurado.apMat}
                                        onChange={e => onChange(e, index)}
                                        onInput={(e) => maxFieldLength(e, 20)}

                                    />

                                    <div className="grid grid-cols-2 gap-4 mt-4">
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DatePicker
                                                name="nacimiento"
                                                label="Nacimiento"
                                                inputFormat="dd/MM/yyyy"
                                                value={asegurado.nacimiento}
                                                onChange={e => isValid(e) && onChange(e, index)}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                        </LocalizationProvider>

                                        <FormControl variant="outlined" size="medium" required>
                                            <InputLabel id="genero" required>Género</InputLabel>
                                            <Select
                                                labelId="genero"
                                                label="genero"
                                                size="medium"
                                                name="genero"
                                                value={asegurado.genero}
                                                onChange={e => onChange(e, index)}
                                            >
                                                <MenuItem disabled value=""><em>Seleccione</em></MenuItem>
                                                {genero.map(g => (
                                                    <MenuItem key={g.key} value={g.key}>{g.value}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>

                                    <TextField
                                        label="RFC"
                                        type="text"
                                        variant="outlined"
                                        name="rfc"
                                        value={asegurado.rfc.toUpperCase()}
                                        onChange={e => onChange(e, index)}
                                        onInput={(e) => maxFieldLength(e, 20)}
                                    />
                                    {index !== 0 &&
                                        <div className="text-center mt-4 mb-4">
                                            <Button onClick={e => removerAsegurado(index)} className="bg-red-500 upperCase text-white font-semibold w-full h-12">Eliminar</Button>
                                        </div>
                                    }

                                    {asegurado.message && <Alert message={asegurado.message} type="error" />}
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    )
                })}

                <div className="text-center">
                    <div className="text-center mt-2 mb-16">
                        <Button onClick={agregarAsegurado} className="bg-dark-400 text-white h-12 w-full capitalize"><AddBoxIcon className="mr-2" />Agregar Otro Asegurado </Button>
                    </div>
                    <div className="text-center mt-8">
                        <div className="grid grid-cols-1 gap-4 mt-4">
                            <Button type="submit" className="btn btn-yellow w-full">Continuar</Button>
                        </div>
                    </div>

                </div>
            </form>
        )
    }
    return render()
}

/* Diversos */
const Diversos = (props) => {

    const { polizaId } = useParams()
    const dispatch = useDispatch()
    const history = useHistory();
    const detalles = useSelector(state => state.pages.poliza.detalles)
    const state = useSelector(state => state.pages.poliza)

    const onSubmit = (e) => {
        e.preventDefault()
        //validate()
        dispatch({ type: NUEVA_POLIZA_ONCHANGE, payload: { ramoBlocked: true } })
        dispatch({ type: NUEVA_POLIZA_ONCHANGE_STEPPER_NEXT })
        history.push(`/polizas/${polizaId}/primas`);
    }

    const onChange = (e) => {
        let value = e.target.value;
        const name = e.target.name;
        dispatch({ type: NUEVA_POLIZA_ONCHANGE_DETALLES, payload: { [name]: value } })
    }

    const render = () => {
        return (
            <form onSubmit={onSubmit}>
                <div className="grid grid-cols-1 gap-y-8 mb-8">

                    <TextField
                        inputProps={{ className: "text-white" }}
                        className="w-full"
                        color="primary"
                        id="outlined-multiline-static"
                        label="Detalle del Bien Asegurado"
                        name="texto"
                        value={state.texto}
                        onChange={e => onChange(e)}
                        multiline
                        rows={20}
                        required
                    />

                </div>
                <div className="text-center mt-8">
                    <div className="grid grid-cols-1 gap-4 mt-4">
                        <Button type="submit" disabled={state.texto === ""} className="btn btn-yellow w-full">Continuar</Button>
                    </div>
                </div>
            </form>
        )
    }

    return render()
}

export default NuevaPoliza
import React from 'react'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import ReportIcon from '@mui/icons-material/Report';

const Alert = ({ message, type }) => {
    const render = () => {

        let className = null
        let classNameMessage = null
        let icon = null
        if (type === "success") {
            className = "py-2 px-4 mb-1 rounded-md text-gray-400 bg-green-300 "
            icon = <CheckCircleOutlineIcon fontSize="medium" className="text-gray-200" />
            classNameMessage = "text-gray-200"
        } else if (type === "error") {
            className = "py-2 px-4 mb-1 rounded-md text-gray-400 bg-red"
            icon = <ErrorOutlineIcon fontSize="medium" className="text-gray-200" />
            classNameMessage = "text-gray-200"
        } else if (type === "warning") {
            className = "py-2 px-4 mb-1 rounded-md text-dark-400 bg-yellow-500"
            icon = <ReportIcon fontSize="medium" className="text-dark-400" />
            classNameMessage = "text-dark-400"
        } else {
            new Error("Unsupported type parameter, expected values: success, error, warning")
        }
        return (
            <article className={className}>
                <div className="flex items-center gap-4">
                    {icon}
                    <p className={classNameMessage}>{message}</p>
                </div>
            </article>
        )
    }
    return render();
}

export default Alert

import React, { useEffect, useState } from 'react'
import AdminTemplate from '../template/AdminTemplate'
import Toolbar from '../components/Toolbar'
import APIInvoke from '../utils/APIInvoke'
import { parse, format } from '../utils/DateUtils'
import { Link, useParams, useLocation, useHistory } from 'react-router-dom'
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import Pagination from '../components/Pagination'
import ClearIcon from '@mui/icons-material/Clear';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const Automatizacione = (props) => {

    const query = useQuery();
    const { logId } = useParams()
    const history = useHistory()
    const [loading, setLoading] = useState(false)
    const [state, setState] = useState(null)

    const page = query.get("page") || 1
    const size = query.get("size") || 20

    useEffect(async () => {
        try {
            setLoading(true)
            const logDetalleResponse = await (await APIInvoke.invokeGET(`/rpa/logs/${logId}/detalle?page=${page}&size=${size}`)).json()

            if (state) {
                setState({
                    ...state,
                    detalle: logDetalleResponse.body.items,
                    pagination: {
                        ...logDetalleResponse.body,
                        items: undefined
                    }
                })
            } else {
                const logResponse = await (await APIInvoke.invokeGET(`/rpa/logs/${logId}`)).json()
                setState({
                    log: logResponse.body,
                    detalle: logDetalleResponse.body.items,
                    pagination: {
                        ...logDetalleResponse.body,
                        items: undefined
                    }
                })
            }

        } catch (e) {
            console.error(e)
        } finally {
            setLoading(false)
        }
    }, [page])

    const renderSuccess = (detalle) => {
        const cliente = detalle.poliza?.cliente || detalle.recibo?.cliente || {}
        return (
            <article key={detalle.id}>
                <div className="bg-dark-400 p-4">
                    <span className="float-right">{detalle.polizaEsRobot || detalle.clienteEsRobot ? <WarningRoundedIcon className="text-yellow" /> : <CheckRoundedIcon className="text-green" />}</span>
                    <div className="grid grid-cols-1 gap-2">
                        <p>Póliza: <Link to={{ pathname: `/polizas/${detalle.poliza.id}`, state: { backUrl: `/automatizaciones/${logId}` } }}>{detalle.poliza?.noPoliza}</Link></p>
                        <p>Ramo: {detalle.poliza?.ramo.ramo}</p>
                        <p>Contratante: <Link Link to={{ pathname: `/clientes/${cliente.id}`, state: { backUrl: `/clientes/${cliente.id}` } }} >{`${cliente.nombres} ${cliente.apPat} ${cliente.apMat}`}</Link></p>
                        {detalle.polizaEsRobot && <p className="text-yellow font-thin">Es necesario validar la póliza</p>}
                        {detalle.clienteEsRobot && <p className="text-yellow font-thin">El cliente es nuevo y es necesario validarlo</p>}
                    </div>
                </div>
            </article>
        )
    }

    const renderError = (detalle) => {
        return (
            <article key={detalle.id}>
                <div className="bg-dark-400 p-4">
                    <span className="float-right">
                        <ClearIcon className="text-red" />
                    </span>
                    <div className="grid grid-cols-1 gap-2">
                        <p>Póliza: {detalle.noPoliza}</p>
                        <p>{detalle.resultado}</p>
                    </div>
                </div>
            </article>
        )
    }

    const renderPendiente = (detalle) => {
        return (
            <article key={detalle.id}>
                <div className="bg-dark-400 p-4">
                    <span className="float-right">
                        <AccessTimeIcon className="text-yellow" />
                    </span>
                    <div className="grid grid-cols-1 gap-2">
                        <p>Póliza: {detalle.noPoliza}</p>
                        <p>Procesamiento en curso</p>
                    </div>
                </div>
            </article>
        )
    }

    const renderLogDetalle = (detalle) => {
        if (detalle.status === "error") {
            return renderError(detalle)
        } if (detalle.status === "pendiente") {
            return renderPendiente(detalle)
        } else {
            return renderSuccess(detalle)
        }
    }

    const render = () => {
        if (!state) return <AdminTemplate loading={true} />
        return (
            <AdminTemplate loading={loading}>
                <Toolbar title="Automatización" backUrl="/automatizaciones" />
                <div className="main">
                    <div className="layout">
                        <div className="grid grid-cols-1 gap-4">
                            <p className="text-lg mb-4">RESULTADO DE PROCESO</p>
                            <p>Aseguradora: {state.log.aseguradora.abreviacion}</p>
                            <p>Proceso: Importación de Pólizas</p>
                            <p>Fecha: {format(parse(state.log.fechaTermino, 'yyyy-MM-dd HH:mm:ss'), "dd MMM yyyy")}</p>
                            <p>Pólizas: {state.log.totalRegistros}</p>

                            <div className="grid grid-cols-1 gap-4">
                                {state.detalle.map(detalle => renderLogDetalle(detalle))}
                            </div>

                            <Pagination totalItems={state.pagination.totalItems} pages={state.pagination.totalPages} page={state.pagination.currentPage} onPageChange={updatePage} />
                        </div>
                    </div>
                </div>
            </AdminTemplate>
        )
    }

    const updatePage = (newPage) => {

        history.push({
            pathname: history.location.pathname,
            search: `?page=${newPage}`
        })
    }

    return render()
}

export default Automatizacione
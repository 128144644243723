import React from 'react'
import { Link } from 'react-router-dom'
import Toolbar from '../../components/Toolbar'
import AdminTemplate from '../../template/AdminTemplate'
import ArticleIcon from '@mui/icons-material/Article';
const Reportes = (props) => {

    const render = () => {
        return (
            <AdminTemplate loading={false}>
                <Toolbar title="Reportes" />


                <div className="main">
                    <div className="layout">
                        <div className="grid grid-cols-1 gap-8">
                            <Link className="btn btn-yellow block " to={{ pathname: "/reportes/polizas", state: { backUrl: "/reportes" } }} ><ArticleIcon /> Reporte de Pólizas</Link>
                        </div>
                    </div>
                </div>
            </AdminTemplate>
        )
    }

    return render()
}

export default Reportes
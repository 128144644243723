import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Divider from "@material-ui/core/Divider";
import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import { Link, useHistory } from "react-router-dom";
import Alert from "../components/Alert";
import APIInvoke from "../utils/APIInvoke";
import { parse, format } from "../utils/DateUtils";
import Navbar from "../components/Navbar";
import { maxFieldLength } from "../utils/FormUtils";
import Loading from "../components/Loading";



const Registro = (props) => {

  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [state, setState] = useState({
    correo: "",
    nombre: "",
    apPaterno: "",
    apMaterno: "",
    terminos: "",
    ok: false
  })

  const [validaCorreo, setValidacionCorreo] = useState({ ok: null, message: "" })
  const [error, setError] = useState({ ok: null, message: "" })

  const validarCorreo = async (data) => {
    try {
      setLoading(true)
      const emailResponse = await (
        await APIInvoke.invokeGET(`/usuarios/validar-email?email=${state.correo}`)).json()
      if (emailResponse.ok) {
        setValidacionCorreo({ ok: true, message: "" })
      } else {
        setValidacionCorreo({ ok: false, message: emailResponse.message })
      }
    } catch (e) {
      console.log("Error al validar el correo", e)
    } finally {
      setLoading(false)
    }
  };


  const enviarDatos = async (e) => {
    try {
      e.preventDefault()
      setLoading(true)
      const request = {
        "correo": state.correo,
        "nombre": state.nombre,
        "apPaterno": state.apPaterno,
        "apMaterno": state.apMaterno,
        "cedula": state.cedula
      }
      const postDatos = await (await APIInvoke.invokePOST(`/agentes`, request)).json();
      if (postDatos.ok === true) {
        history.push({
          pathname: '/activar',
          search: `?email=${state.correo}`
        })
      } else {
        console.log("errr")
        setError({ ok: false, message: postDatos.message })
      }
    } catch (error) {
      console.log("error", error)
    } finally {
      setLoading(false)
    }
  }

  const onChange = (e) => {
    let value = e.target.value
    if (e.target.type == "checkbox") {
      value = e.target.checked
    }

    setState({
      ...state,
      [e.target.name]: value
    })
  }


  const render = () => {
    let vigenciaAsString = '';
    if (state.vigencia !== null) {
      vigenciaAsString = format(parse(state.vigencia, "yyyy-MM-dd"), "dd/MM/yyyy")
    }
    return (
      <div>
        <Loading show={loading} />
        <Navbar showBackButton={false} />
        <div className="main">
          <div className="layout-compact">
            <p className="text-gray-400 mb-8">
              Es importante que sepas que esta aplicación es exclusiva para
              agentes de seguros registrados ante la CNSF.
            </p>
            <p className="text-gray-400 mb-8">
              Para continuar captura tus datos como están registrados en dicha
              institución.
            </p>
            <form className="grid gap-4" onSubmit={enviarDatos}>
              <TextField
                label="Correo"
                type="email"
                name="correo"
                onChange={onChange}
                onBlur={validarCorreo}
                value={state.correo}
                variant="outlined"
                className="text-gray-400"
                required
              />

              {validaCorreo.ok === false && (<Alert message={`${validaCorreo.message}`} type="error" />)}

              <TextField
                label="Nombres"
                type="text"
                name="nombre"
                variant="outlined"
                onChange={onChange}
                value={state.nombre}
                onInput={(e) => maxFieldLength(e, 60)}
                required
              />
              <TextField
                label="Ap. Paterno"
                type="text"
                name="apPaterno"
                onChange={onChange}
                value={state.apPaterno}
                variant="outlined"
                onInput={(e) => maxFieldLength(e, 45)}
                required
              />
              <TextField
                label="Ap. Materno"
                type="text"
                name="apMaterno"
                variant="outlined"
                onChange={onChange}
                value={state.apMaterno}
                onInput={(e) => maxFieldLength(e, 45)}
              />
              <div className="">
                <Checkbox name="terminos" onChange={onChange} value={state.terminos} required />
                <a href="/terminos-condiciones" target="_blank" className="col-span-4 text-left">
                  Acepto los términos y condiciones del servicio
                </a>
              </div>
              <Button
                type="submit"
                className="btn btn-yellow w-full"
                disabled={!state.terminos}
              >
                Crear Cuenta
              </Button>

              <div className="text-center">
                {error.ok === false && (<Alert message={`${error.message}`} type="error" />)}
                <p className="text-gray-400 mt-8 mb-8">
                  Ya tengo cuenta <Link to="/login" className="no-underline mt-8 ml-4">Iniciar sesión</Link>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  };

  return render();
};

export default Registro;

import {
  CXC_RESET,
  CXC_UPDATE_ROOT,
  CXC_UPDATE_SECTION,
  CXC_UPDATE_STATUS,
} from "../redux-const";

const initialState = {
  loading: false,
  loadingListado: false,
  recibos: {
    items: [],
    totalItems: 0,
    currentPage: 1,
    totalPages: 0,
    pageSize: 0,
  },
  listado: {
    items: [],
    totalItems: 0,
    currentPage: 1,
    totalPages: 0,
    pageSize: 0,
  },
  filters: {
    types: ["auto", "salud", "vida", "diversos"],
    range: 0,
    clickRange: undefined,
  },
  filtersListado: {
    field: "cliente",
    value: "",
    orderBy: "desc",
  },
};

export const reducer = (state = initialState, action) => {
  const payload = action.payload;
  const section = action.section;

  switch (action.type) {
    case CXC_RESET:
      return initialState;
    case CXC_UPDATE_ROOT:
      return {
        ...state,
        ...payload,
      };
    case CXC_UPDATE_SECTION:
      return {
        ...state,
        [section]: {
          ...state[section],
          ...payload,
        },
      };

    case CXC_UPDATE_STATUS:
      const { id, status } = action.payload;

      let recibo = state.recibos.items.find((x) => x.recibo.id === id);

      recibo.recibo.status = status;
      
      return {
        ...state,
        recibos: {
          ...state.recibos,
          items: [...state.recibos.items],
        },
      };
  }

  return state;
};

export default reducer;

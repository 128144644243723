import { Button } from '@mui/material'
import React, { useRef } from 'react'
import BackupIcon from '@mui/icons-material/Backup';

const FileUploader = ({ children, onUpload }) => {

    const file = useRef()

    const onFileSelect = () => {
        file.current.click()
    }

    const render = () => {
        return (
            <Button className="block p-0" onClick={onFileSelect}>
                <input
                    type="file"
                    name="file"
                    ref={file}
                    className="hidden"
                    accept=".pdf"
                    onChange={onUpload}
                />
                <div className="rounded-md bg-dark-200 flex p-4 gap-4 items-center">
                    <div className="rounded-md bg-dark-500 p-4 inline-block">
                        <BackupIcon className="text-gray-500" fontSize="large" />
                    </div>
                    <div className="text-left">
                        {children}
                    </div>
                </div>
            </Button>
        )
    }

    return render()
}

export default FileUploader
import React, { useEffect, useState } from 'react'
import AdminTemplate from '../../template/AdminTemplate'
import Toolbar from '../../components/Toolbar'
import APIInvoke from '../../utils/APIInvoke';
import { DateTime } from 'luxon';
import { numberFormat } from '../../utils/NumberUtils';
import { DataGrid, GridToolbar, GridRow } from '@mui/x-data-grid';
import Checkbox from '@mui/material/Checkbox';
import Pagination from '../../components/Pagination';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import esLocale from 'date-fns/locale/es';
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@material-ui/core'
import { PieChart, Pie, Sector, Cell, ResponsiveContainer, Tooltip } from 'recharts';
import millify from "millify";
import DateGridlocaleTextConstantsES from '../../utils/DateGridlocaleTextConstantsES'
import FilterAltIcon from '@mui/icons-material/FilterAlt'

const ReportePolizas = (props) => {

    const [data, setData] = useState({
        items: [],
        totalItems: 1,
        currentPage: 1,
        totalPages: 1,
        pageSize: 1
    })
    const [loading, setLoading] = useState(true)
    const [state, setState] = useState({
        page: 1,
        size: 10,
        vigenciaDe: DateTime.now().set({ hour: 0, minute: 0, second: 0 }).plus({ year: -1 }),
        vigenciaA: DateTime.now().set({ hour: 0, minute: 0, second: 0 }),
        status: "",
        ramo: "",
        formaPago: "",
        moneda: ""
    })

    const [status, setStatus] = useState([])
    const [ramos, setRamos] = useState([])
    const [formaPago, setFormaPago] = useState([])
    const [monedas, setMonedas] = useState([])
    const [views, setViews] = useState({})
    //'#0088FE', '#00C49F', '#FFBB28', '#FF8042', 
    const COLORS = ['#FED140', '#6EC81D', '#006FD3', '#B40983', '#E73131', '#FF9420', '#FF9420', '#BEEE62', '#F4743B', '#FF69EB', '#83B5D1'];


    const columns = [
        {
            field: 'noPoliza',
            headerName: 'No Póliza',
            type: 'string',
            width: 150,
            editable: false,
            valueGetter: (params) => `${params.row.noPoliza.toUpperCase()}`
        }, {
            field: 'concepto',
            headerName: 'Concepto',
            type: 'string',
            width: 200,
            editable: false,
            valueGetter: (params) => `${params.row.concepto.toLowerCase()}`
        }, {
            field: 'paquete',
            headerName: 'Paquete',
            type: 'string',
            width: 200,
            editable: false,
            renderCell: (params) => (<p className="text-sm">{params.row.paquete.toLowerCase()}</p>)
        }, {
            field: 'fechaVigenciaDe',
            headerName: 'Vigencia De',
            type: 'date',
            width: 150,
            editable: false,
            renderCell: (params) => (
                <div className="w-full py-1 my-1">
                    <p className="">
                        {params.row.fechaVigenciaDe.toFormat("dd/MM/yyyy")}
                    </p>
                </div>
            )
        }, {
            field: 'fechaVigenciaA',
            headerName: 'Vigencia A',
            type: 'date',
            width: 200,
            editable: false,
            renderCell: (params) => (
                <div className="w-full py-1 my-1">
                    <p className="">
                        {params.row.fechaVigenciaA.toFormat("dd/MM/yyyy")}
                    </p>
                </div>
            )
        }, {
            field: 'prima',
            headerName: 'Prima neta',
            type: 'string',
            width: 170,
            editable: false,
            renderCell: (params) => (
                <div className="w-full py-1 my-1">
                    <p className="text-right">
                        {`${numberFormat(params.row.prima)} ${params.row.moneda}`}
                    </p>
                </div>
            )
        }, {
            field: 'total',
            headerName: 'Prima Total',
            type: 'string',
            width: 170,
            editable: false,
            renderCell: (params) => (
                <div className="w-full py-1 my-1">
                    <p className="text-right">
                        {`${numberFormat(params.row.total)} ${params.row.moneda}`}
                    </p>
                </div>
            )
        }, {
            field: 'formaPago',
            headerName: 'Forma de Pago',
            type: 'string',
            width: 120,
            editable: false,
            valueGetter: (params) => `${params.row.formaPago.toUpperCase()}`
        }, {
            field: 'status',
            headerName: 'Status',
            type: 'string',
            width: 120,
            editable: false,
            valueGetter: (params) => `${params.row.status.toUpperCase()}`
        }, {
            field: 'ramo',
            headerName: 'Ramo',
            type: 'string',
            width: 100,
            editable: false,
            valueGetter: (params) => `${params.row.ramo.toUpperCase()}`
        }, {
            field: 'aseguradora',
            headerName: 'Aseguradora',
            type: 'string',
            width: 120,
            editable: false,
            valueGetter: (params) => `${params.row.aseguradora}`
        }, {
            field: 'clave',
            headerName: 'Clave',
            type: 'string',
            width: 110,
            editable: false,
            valueGetter: (params) => `${params.row.clave}`
        }, {
            field: 'Contratante',
            headerName: 'Contratante',
            type: 'string',
            width: 200,
            editable: false,
            valueGetter: (params) => `${params.row.nombres} ${params.row.apPat} ${params.row.apMat}`
        }, {
            field: 'esRobot',
            type: 'boolean',
            headerName: 'Robot',
            width: 80,
            editable: false,
            renderCell: (params) => (<Checkbox disabled checked={params.row.esRobot} />)

        }
    ]

    useEffect(() => {
        const loadStatus = async () => {
            const response = await (await APIInvoke.invokeGET(`/catalogos/polizas/status`)).json()
            setStatus(response.body)
        }

        const loadRamos = async () => {
            const response = await (await APIInvoke.invokeGET(`/ramos`)).json()
            setRamos(response.body)
        }

        const loadFormaPago = async () => {
            const response = await (await APIInvoke.invokeGET(`/catalogos/forma-pago`)).json()
            setFormaPago(response.body)
        }
        const loadMonedas = async () => {
            const response = await (await APIInvoke.invokeGET(`/monedas`)).json()
            setMonedas(response.body)
        }
        loadStatus()
        loadRamos()
        loadFormaPago()
        loadMonedas()
    }, [])

    useEffect(async () => {
        fetchDate()
    }, [state])

    const fetchDate = async () => {
        try {
            setLoading(true)

            const vigenciaDe = state.vigenciaDe !== "" ? `fechaVigenciaDe[ge]=${state.vigenciaDe.toFormat("yyyy-MM-dd")}` : ""
            const vigenciaA = state.vigenciaA !== "" ? `&fechaVigenciaDe[le]=${state.vigenciaA.toFormat("yyyy-MM-dd")}` : ""
            const status = state.status !== "" ? `&status=${state.status}` : ""
            const ramo = state.ramo !== "" ? `&ramo=${state.ramo}` : ""
            const formaPago = state.formaPago !== "" ? `&formaPago=${state.formaPago}` : ""
            const moneda = state.moneda !== "" ? `&moneda=${state.moneda}` : ""

            const response = await (await APIInvoke.invokeGET(`/reportes/polizas?${vigenciaDe}${vigenciaA}${status}${ramo}${formaPago}${moneda}`)).json()
            setData({
                ...response.body,
                items: response.body.items.map(x => {
                    return {
                        ...x,
                        fechaVigenciaDe: DateTime.fromFormat(x.fechaVigenciaDe, "yyyy-MM-dd").set({ hour: 0, minute: 0, second: 0 }),
                        fechaVigenciaA: DateTime.fromFormat(x.fechaVigenciaA, "yyyy-MM-dd").set({ hour: 0, minute: 0, second: 0 })
                    }
                })
            })

            const views = response.body.items.reduce((previes, current) => {
                previes.primas += current.total

                if (previes.status[current.status]) {
                    previes.status[current.status].count = previes.status[current.status].count + 1
                    previes.status[current.status].sum = previes.status[current.status].sum + current.total
                } else {
                    previes.status[current.status] = {
                        name: current.status,
                        count: 1,
                        sum: current.total
                    }
                }

                if (previes.ramos[current.ramo]) {
                    previes.ramos[current.ramo].count = previes.ramos[current.ramo].count + 1
                    previes.ramos[current.ramo].sum = previes.ramos[current.ramo].sum + current.total
                } else {
                    previes.ramos[current.ramo] = {
                        name: current.ramo,
                        count: 1,
                        sum: current.total
                    }
                }

                if (previes.aseguradoras[current.aseguradora]) {
                    previes.aseguradoras[current.aseguradora].count = previes.aseguradoras[current.aseguradora].count + 1
                    previes.aseguradoras[current.aseguradora].sum = previes.aseguradoras[current.aseguradora].sum + current.total
                } else {
                    previes.aseguradoras[current.aseguradora] = {
                        name: current.aseguradora,
                        count: 1,
                        sum: current.total
                    }
                }

                return previes
            }, { primas: 0, polizas: response.body.items.length, status: {}, ramos: {}, aseguradoras: {} })

            setViews({
                polizas: views.polizas,
                primas: views.primas,
                polizasCountXStatus: Object.keys(views.status).map(x => {
                    return {
                        name: views.status[x].name,
                        value: views.status[x].count
                    }
                }),
                polizasSumXStatus: Object.keys(views.status).map(x => {
                    return {
                        name: views.status[x].name,
                        value: views.status[x].sum
                    }
                }),
                polizasCountXRamo: Object.keys(views.ramos).map(x => {
                    return {
                        name: views.ramos[x].name,
                        value: views.ramos[x].count
                    }
                }),
                polizasSumXRamo: Object.keys(views.ramos).map(x => {
                    return {
                        name: views.ramos[x].name,
                        value: views.ramos[x].sum
                    }
                }),
                polizasCountXAseguradora: Object.keys(views.aseguradoras).map(x => {
                    return {
                        name: views.aseguradoras[x].name,
                        value: views.aseguradoras[x].count
                    }
                }),
                polizasSumXAseguradora: Object.keys(views.aseguradoras).map(x => {
                    return {
                        name: views.aseguradoras[x].name,
                        value: views.aseguradoras[x].sum
                    }
                })
            })
        } catch (e) {
            console.error(e)
        } finally {
            setLoading(false)
        }
    }

    const render = () => {
        if (loading) return <AdminTemplate loading={true} />
        return (
            <AdminTemplate loading={loading}>
                <Toolbar title="Reporte de pólizas" />

                <div className="main ">
                    <div className="mx-4 lg:mx-8">
                        <div className="grid grid-cols-1 gap-4 ">
                            <div className=" bg-dark-400 p-4 rounded-md">
                                <div className="grid grid-cols-1 gap-4">
                                    <p className="font-bold"><FilterAltIcon /> Filtros:</p>
                                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-2 lg:gap-4">
                                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
                                            <DatePicker
                                                label="Inicio de Vigencia De"
                                                value={state.vigenciaDe}
                                                onChange={(newValue) => {
                                                    setState({ ...state, vigenciaDe: DateTime.fromJSDate(newValue) })
                                                }}
                                                renderInput={(params) => <TextField {...params} helperText={null} />}
                                            />
                                        </LocalizationProvider>

                                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
                                            <DatePicker
                                                label="Inicio de Vigencia A"
                                                minDate={state.vigenciaDe}
                                                value={state.vigenciaA}
                                                onChange={(newValue) => {
                                                    setState({ ...state, vigenciaA: DateTime.fromJSDate(newValue) })
                                                }}
                                                renderInput={(params) => <TextField {...params} helperText={null} />}
                                            />
                                        </LocalizationProvider>

                                        <FormControl variant="outlined" size="medium" >
                                            <InputLabel id="status" >Estatus Póliza</InputLabel>
                                            <Select
                                                labelId="status"
                                                label="Estatus"
                                                size="medium"
                                                name="status"
                                                value={state.status}
                                                onChange={e => setState({ ...state, status: e.target.value })}
                                            >
                                                <MenuItem value="" ><em>Todos</em></MenuItem>
                                                {status.map(x => <MenuItem key={x.key} value={x.key} >{x.value}</MenuItem>)}
                                            </Select>
                                        </FormControl>

                                        <FormControl variant="outlined" size="medium" >
                                            <InputLabel id="ramo" >Ramo</InputLabel>
                                            <Select
                                                labelId="ramo"
                                                label="Ramo"
                                                size="medium"
                                                name="ramo"
                                                value={state.ramo}
                                                onChange={e => setState({ ...state, ramo: e.target.value })}
                                            >
                                                <MenuItem value="" ><em>Todos</em></MenuItem>
                                                {ramos.map(x => <MenuItem key={x.id} value={x.ramo} >{x.ramo}</MenuItem>)}
                                            </Select>
                                        </FormControl>

                                        <FormControl variant="outlined" size="medium" >
                                            <InputLabel id="formaPago" >Forma de Pago</InputLabel>
                                            <Select
                                                labelId="formaPago"
                                                label="Forma de Pago"
                                                size="medium"
                                                name="formaPago"
                                                value={state.formaPago}
                                                onChange={e => setState({ ...state, formaPago: e.target.value })}
                                            >
                                                <MenuItem value="" ><em>Todos</em></MenuItem>
                                                {formaPago.map(x => <MenuItem key={x.key} value={x.key} >{x.value}</MenuItem>)}
                                            </Select>
                                        </FormControl>

                                        <FormControl variant="outlined" size="medium" >
                                            <InputLabel id="formaPago" >Moneda</InputLabel>
                                            <Select
                                                labelId="moneda"
                                                label="Moneda"
                                                size="medium"
                                                name="moneda"
                                                value={state.moneda}
                                                onChange={e => setState({ ...state, moneda: e.target.value })}
                                            >
                                                <MenuItem value="" ><em>Todos</em></MenuItem>
                                                {monedas.map(x => <MenuItem key={x.id} value={x.abreviacion} >{`${x.moneda} (${x.abreviacion})`}</MenuItem>)}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                            </div>

                            <div className="border-b-2 border-dark-300" />

                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                <div className=" bg-dark-400 p-4 rounded-md" >
                                    <div className="grid grid-cols-1 gap-4 text-center items-center" style={{ height: "300px" }}>
                                        <p className="font-bold">Conteo de pólizas por Ramo</p>
                                        <ResponsiveContainer >
                                            <PieChart >
                                                <Pie
                                                    data={views.polizasCountXRamo}
                                                    label={(e) => `${e.payload.name} - ${e.payload.value}`}
                                                    fontSize="12"
                                                    innerRadius={60}
                                                    outerRadius={80}
                                                    fill="#8884d8"
                                                    paddingAngle={5}
                                                    dataKey="value"
                                                >
                                                    {views.polizasCountXRamo.map((entry, index) => (
                                                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                                    ))}
                                                </Pie>
                                            </PieChart>
                                        </ResponsiveContainer>
                                    </div>
                                    <div className="flex flex-row gap-2 justify-center flex-wrap mb-4">
                                        {views.polizasCountXRamo.map((entry, index) => (
                                            <span key={`${index}`} className="flex flex-row gap-1 items-center text-gray-400 mr-2 text-xs"><span className="inline-block rounded-sm" style={{ height: "15px", width: "15px", backgroundColor: `${COLORS[index % COLORS.length]}` }} /> {`${entry.name}(${entry.value})`}</span>
                                        ))}
                                    </div>
                                    <p className="text-center">Total de pólizas: {views.polizas}</p>
                                </div>
                                <div className=" bg-dark-400 p-4 rounded-md" >
                                    <div className="grid grid-cols-1 gap-4 text-center items-center h-80">
                                        <p className="font-bold">Acumulados de primas por Ramo</p>
                                        <ResponsiveContainer >
                                            <PieChart >
                                                <Pie

                                                    data={views.polizasSumXRamo}
                                                    label={(e) => `${e.payload.name} - ${millify(e.payload.value, { precision: 2 })}`}
                                                    fontSize="12"
                                                    innerRadius={60}
                                                    outerRadius={80}
                                                    fill="#8884d8"
                                                    paddingAngle={5}
                                                    dataKey="value"
                                                    height={150}
                                                >
                                                    {views.polizasSumXRamo.map((entry, index) => (
                                                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                                    ))}
                                                </Pie>
                                            </PieChart>
                                        </ResponsiveContainer>
                                    </div>
                                    <div className="flex flex-row gap-2 justify-center flex-wrap mb-4">
                                        {views.polizasSumXRamo.map((entry, index) => (
                                            <span key={`${index}`} className="flex flex-row gap-1 items-center text-gray-400 mr-2 text-xs"><span className="inline-block rounded-sm" style={{ height: "15px", width: "15px", backgroundColor: `${COLORS[index % COLORS.length]}` }} /> {`${entry.name}(${millify(entry.value, { precision: 2 })})`}</span>
                                        ))}
                                    </div>
                                    <p className="text-center">Primas acumuladas {numberFormat(views.primas)}</p>
                                </div>
                            </div>

                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                <div className=" bg-dark-400 p-4 rounded-md" >
                                    <div className="grid grid-cols-1 gap-4 text-center items-center h-80">
                                        <p className="font-bold">Conteo de pólizas por Estatus</p>
                                        <ResponsiveContainer>
                                            <PieChart margin={{ top: 15, right: 15, left: 15, bottom: 15 }} height={200} >
                                                <Pie
                                                    height={200}
                                                    data={views.polizasCountXStatus}
                                                    fontSize="12"
                                                    label={(e) => `${e.payload.name} - ${e.payload.value}`}
                                                    innerRadius={60}
                                                    outerRadius={80}
                                                    fill="#8884d8"
                                                    paddingAngle={5}
                                                    dataKey="value"
                                                >
                                                    {Object.keys(views.polizasCountXStatus).map((entry, index) => (
                                                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                                    ))}
                                                </Pie>
                                            </PieChart>
                                        </ResponsiveContainer>
                                    </div>
                                    <div className="flex flex-row gap-2 justify-center flex-wrap mb-4">
                                        {views.polizasCountXStatus.map((entry, index) => (
                                            <span key={`${index}`} className="flex flex-row gap-1 items-center text-gray-400 mr-2 text-xs"><span className="inline-block rounded-sm" style={{ height: "15px", width: "15px", backgroundColor: `${COLORS[index % COLORS.length]}` }} /> {`${entry.name}(${entry.value})`}</span>
                                        ))}
                                    </div>
                                    <p className="text-center">Total de pólizas: {views.polizas}</p>
                                </div>
                                <div className=" bg-dark-400 p-4 rounded-md" >
                                    <div className="grid grid-cols-1 gap-4 text-center items-center h-80">
                                        <p className="font-bold">Acumulados de primas por Estatus</p>
                                        <ResponsiveContainer>
                                            <PieChart margin={{ top: 15, right: 15, left: 15, bottom: 15 }} >
                                                <Pie
                                                    data={views.polizasSumXStatus}
                                                    label={(e) => `${e.payload.name} - ${millify(e.payload.value, { precision: 2 })}`}
                                                    fontSize="12"
                                                    innerRadius={60}
                                                    outerRadius={80}
                                                    fill="#8884d8"
                                                    paddingAngle={5}
                                                    dataKey="value"
                                                >
                                                    {Object.keys(views.polizasSumXStatus).map((entry, index) => (
                                                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                                    ))}
                                                </Pie>
                                            </PieChart>
                                        </ResponsiveContainer>
                                    </div>
                                    <div className="flex flex-row gap-2 justify-center flex-wrap mb-4">
                                        {views.polizasSumXStatus.map((entry, index) => (
                                            <span key={`${index}`} className="flex flex-row gap-1 items-center text-gray-400 mr-2 text-xs"><span className="inline-block rounded-sm" style={{ height: "15px", width: "15px", backgroundColor: `${COLORS[index % COLORS.length]}` }} /> {`${entry.name}(${millify(entry.value, { precision: 2 })})`}</span>
                                        ))}
                                    </div>
                                    <p className="text-center">Primas acumuladas {numberFormat(views.primas)}</p>
                                </div>
                            </div>

                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                <div className=" bg-dark-400 p-4 rounded-md" >
                                    <div className="grid grid-cols-1 gap-4 text-center items-center h-80">
                                        <p className="font-bold">Conteo de pólizas por Aseguradora</p>
                                        <ResponsiveContainer >
                                            <PieChart margin={{ top: 100, right: 100, left: 100, bottom: 100 }} >
                                                <Pie
                                                    data={views.polizasCountXAseguradora}
                                                    label={(e) => `${e.payload.name} - ${millify(e.payload.value, { precision: 2 })}`}
                                                    fontSize="12"
                                                    innerRadius={60}
                                                    outerRadius={80}
                                                    fill="#8884d8"
                                                    paddingAngle={5}
                                                    dataKey="value"
                                                >
                                                    {Object.keys(views.polizasCountXAseguradora).map((entry, index) => (
                                                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                                    ))}
                                                </Pie>
                                            </PieChart>
                                        </ResponsiveContainer>
                                    </div>
                                    <div className="flex flex-row gap-2 justify-center flex-wrap mb-4">
                                        {views.polizasCountXAseguradora.map((entry, index) => (
                                            <span key={`${index}`} className="flex flex-row gap-1 items-center text-gray-400 mr-2 text-xs"><span className="inline-block rounded-sm" style={{ height: "15px", width: "15px", backgroundColor: `${COLORS[index % COLORS.length]}` }} /> {`${entry.name}(${entry.value})`}</span>
                                        ))}
                                    </div>
                                    <p className="text-center">Total de pólizas: {views.polizas}</p>
                                </div>
                                <div className=" bg-dark-400 p-4 rounded-md" >
                                    <div className="grid grid-cols-1 gap-4 text-center items-center h-80">
                                        <p className="font-bold">Acumulados de primas por Aseguradora</p>
                                        <ResponsiveContainer>
                                            <PieChart margin={{ top: 15, right: 15, left: 15, bottom: 15 }} >
                                                <Pie
                                                    data={views.polizasSumXAseguradora}
                                                    label={(e) => `${e.payload.name} - ${millify(e.payload.value, { precision: 2 })}`}
                                                    fontSize="12"
                                                    innerRadius={60}
                                                    outerRadius={80}
                                                    fill="#8884d8"
                                                    paddingAngle={5}
                                                    dataKey="value"
                                                >
                                                    {Object.keys(views.polizasSumXAseguradora).map((entry, index) => (
                                                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                                    ))}
                                                </Pie>
                                            </PieChart>
                                        </ResponsiveContainer>
                                    </div>
                                    <div className="flex flex-row gap-2 justify-center flex-wrap mb-4">
                                        {views.polizasSumXAseguradora.map((entry, index) => (
                                            <span key={`${index}`} className="flex flex-row gap-1 items-center text-gray-400 mr-2 text-xs"><span className="inline-block rounded-sm" style={{ height: "15px", width: "15px", backgroundColor: `${COLORS[index % COLORS.length]}` }} /> {`${entry.name}(${millify(entry.value, { precision: 2 })})`}</span>
                                        ))}
                                    </div>
                                    <p className="text-center">Primas acumuladas {numberFormat(views.primas)}</p>
                                </div>
                            </div>

                            <div className=" bg-dark-400 p-4 rounded-md">
                                <div className="grid grid-cols-1 bg-gray-100 rounded-md" style={{ height: "700px" }} >
                                    <DataGrid
                                        className="bg-dark-300 text-gray-400 border-dark-600"
                                        rows={data.items}
                                        columns={columns}
                                        getRowId={e => e.id}
                                        pageSize={10}
                                        //onPageChange={(newPage, details) => setState({ ...state, page: newPage })}
                                        page={data.currentPage}
                                        rowCount={data.totalItems}
                                        paginationMode="server"
                                        //rowsPerPageOptions={[20]}
                                        onFilterModelChange={(model, details) => {
                                            console.log("model", model)
                                            console.log("details", details)
                                        }}
                                        checkboxSelection
                                        disableSelectionOnClick
                                        localeText={DateGridlocaleTextConstantsES}
                                        components={{
                                            Toolbar: GridToolbar,
                                            Row: GridRow, // Default value
                                            Footer: (params) => (<div className="p-2"><Pagination totalItems={data.totalItems} pages={data.totalPages} page={data.currentPage} onPageChange={newPage => setState({ ...state, page: newPage })} /></div>)
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </AdminTemplate>
        )
    }

    return render()

}

export default ReportePolizas
import AdminTemplate from '../template/AdminTemplate'
import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@material-ui/core'
import React, { useState, useEffect, useContext } from 'react'
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import Alert from '../components/Alert'
import Toolbar from '../components/Toolbar'
import { maxFieldLength } from '../utils/FormUtils'
import APIInvoke from '../utils/APIInvoke'
import { useHistory, useParams } from 'react-router-dom'
import { EditorState, convertToRaw, ContentState } from 'draft-js';

import { Autocomplete } from '@mui/material';
import UserContext from '../state/UserContext';


const Correo = (props) => {

    const [loading, setLoading] = useState(false)
    const params = useParams()
    const objectId = props.objectId || params.objectId
    const [message, setMessage] = useState(null)
    const userContext = useContext(UserContext)

    const [modelos, setModelos] = useState([])

    const [state, setState] = useState({
        nombre: "",
        evento: "",
        cuentaFrom: "",
        cuentaTo: "",
        cuentaCC: "",
        cuentaCCB: "",
        asunto: "",
        cuerpo: EditorState.createWithContent(ContentState.createFromText("\n\n\n\n")),
        modeloCorreo: ""
    })

    useEffect(async () => {
        try {
            setLoading(true)
            const response = await (await APIInvoke.invokeGET(`/correo-modelo`)).json()
            const modelos = response.body.filter(x => x.evento.entidad === props.entidad)
            setModelos(modelos)
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }, [])

    useEffect(async () => {

        try {
            setLoading(true)
            const modelo = modelos.find(x => x.id === state.modeloCorreo)
            console.log("modelo", modelo)
            if (modelo) {

                const translateRequest = {
                    "correoModeloId": modelo.id,
                    "objectId": props.entidadId
                }
                const response = await (await APIInvoke.invokePOST(`/correo-modelo/translate`, translateRequest)).json()
                const blocksFromHtml = htmlToDraft(response.body.cuerpoHTML);
                const { contentBlocks, entityMap } = blocksFromHtml;
                const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
                const editorState = EditorState.createWithContent(contentState);
                setState({
                    ...state,
                    nombre: modelo.nombre,
                    cuentaFrom: modelo.cuentaFrom,
                    cuentaTo: props.correoPara,
                    cuentaCC: modelo.cuentaCC,
                    cuentaCCB: modelo.cuentaCCB,
                    asunto: modelo.asunto,
                    cuerpo: editorState,
                })
            }
        } catch (e) {
            console.log("error", e)
        } finally {
            setLoading(false)
        }

    }, [state.modeloCorreo])


    useEffect(async () => {
        const esCuentaPersonal = state.cuentaFrom === userContext.user.correo
        setState({
            ...state,
            esCuentaPersonal
        })
    }, [state.cuentaFrom])

    const onChange = (e) => {

        const name = e.target.name
        const value = e.target.value

        setState({
            ...state,
            [name]: value
        })
        console.log("state onchange", state)
    }

    const validateEmail = (emails) => {
        let regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

        const emailsSplit = emails.replaceAll(" ", "").split(",")
        if (emailsSplit.length === 0) return true

        console.log("emailsSplit", emailsSplit)
        for (let e of emailsSplit) {
            if (e === "") continue
            const test = regex.test(e)
            console.log("test", e, test)
            if (!test) {
                setMessage({ type: "error", message: `El correo ${e} no es valido` })
                return false
            }
        }
        setMessage(null)
        return true
    }

    const onSubmit = async (e) => {
        e.preventDefault()

        if (state.esCuentaPersonal) {
            window.location = `mailto:${state.cuentaTo}?subject=${state.asunto}&body=${encodeURIComponent(state.cuerpo.getCurrentContent().getPlainText())}&cc=${state.cuentaCC}&bcc=${state.cuentaCCB}`;
            return false;
        }

        if (!validate(e)) return false

        try {
            setLoading(true)
            if (!validateEmail(state.cuentaFrom)) return
            if (!validateEmail(state.cuentaTo)) return
            if (!validateEmail(state.cuentaCC)) return
            if (!validateEmail(state.cuentaCCB)) return

            let sendEmailRequest = null
            sendEmailRequest = {
                "from": state.cuentaFrom,
                "to": state.cuentaTo.replace(" ", "").split(","),
                "cc": state.cuentaCC.replace(" ", "").split(","),
                "bcc": state.cuentaCCB.replace(" ", "").split(","),
                "subject": state.asunto,
                "body": draftToHtml(convertToRaw(state.cuerpo.getCurrentContent())),
                "tipo": "correo",
                "clienteId": undefined,
                "polizaId": props.entidad === "poliza" ? props.entidadId : undefined,
                "reciboId": props.entidad === "recibo" ? props.entidadId : undefined
            }


            const sendEmailResponse = await (await APIInvoke.invokePOST(`/email/crm`, sendEmailRequest)).json()
            console.log("sendEmailResponse", sendEmailResponse)

            setMessage({ type: sendEmailResponse.ok ? "success" : "error", message: sendEmailResponse.message })
            if (sendEmailResponse.ok) {
                if (props.onCompleted) {
                    props.onCompleted(sendEmailResponse.body)
                } else {
                    console.log("error de post")
                }
            }

        } catch (e) {
            console.log(e)
        } finally {
            setLoading(false)
        }
    }

    const validate = (e) => {
        setMessage(null)

        const { asunto } = state
        if (asunto.length < 10 || asunto.length > 100) {
            setMessage({ type: "error", message: `El asunto debe ser entre 10 y 100 caracteres` })
            return false
        }

        return true
    }

    const render = () => {
        console.log("state", state)
        console.log("state.cuentaFrom", state.cuentaFrom)
        return (
            <AdminTemplate loading={loading} modal={props.modal} >
                <Toolbar title="Correo Electrónico" modal={props.modal} />
                <form onSubmit={onSubmit} val>
                    <div className="main center">
                        <div className="layout-compact">
                            <div className="grid grid-cols-1 gap-4">
                                <div className="grid grid-cols-2 gap-4 mb-4">
                                    <div className="col-span-2 grid grid-cols-1 gap-4">
                                        <FormControl variant="outlined" required>
                                            <InputLabel id="modeloCorreo">Modelo de Correo</InputLabel>
                                            <Select
                                                required
                                                labelId="Modelo de Correo"
                                                label="Modelo de Correo"
                                                size="medium"
                                                name="modeloCorreo"
                                                defaultValue=""
                                                value={state.modeloCorreo}
                                                onChange={onChange}>
                                                <MenuItem disabled value="" ><em>Seleccione</em></MenuItem>
                                                {modelos.map(modelo => (<MenuItem key={modelo.id} value={modelo.id}>{modelo.nombre}</MenuItem>))}
                                            </Select>
                                        </FormControl>

                                        {state.modeloCorreo && (
                                            <>
                                                {
                                                    /*
                                                    <Autocomplete
                                                    id="de"
                                                    name="de"
                                                    required
                                                    freeSolo
                                                    clearText="clearText"
                                                    closeText="closeText"
                                                    noOptionsText="Sin resultados"
                                                    loadingText="Cargando..."
                                                    openText="openText"
                                                    //open={open}
                                                    value={state.cuentaFrom}
                                                    //onOpen={() => setOpen(true)}
                                                    //onClose={() => setOpen(false)}
                                                    isOptionEqualToValue={(option, value) => option.cuentaFrom === value.cuentaFrom}
                                                    getOptionLabel={(option) => state.cuentaFrom}
                                                    options={[state]}
                                                    //onInput={fetchDescDebounce}
                                                    //loading={descLoading}
                                                    onChange={onChange}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="De"
                                                            name="de"
                                                            type="email"
                                                            required
                                                            //onChange={onChangeManual}
                                                            InputProps={{
                                                                ...params.InputProps,
                                                                endAdornment: (
                                                                    <React.Fragment>
                                                                        {params.InputProps.endAdornment}
                                                                    </React.Fragment>
                                                                ),
                                                            }}
                                                        />
                                                    )}
                                                />
                                                    */
                                                }






                                                {/* 
                                                <TextField
                                                    label="De"
                                                    type="text"
                                                    variant="outlined"
                                                    name="de"
                                                    enabled={false}
                                                    value={state.cuentaFrom}
                                                    disabled={true}
                                                />
                                                */}

                                                {console.log("userContext.user",)}
                                                <FormControl variant="outlined" required>
                                                    <InputLabel id="de">De</InputLabel>
                                                    <Select
                                                        required
                                                        labelId="Modelo de Correo"
                                                        label="Modelo de Correo"
                                                        size="medium"
                                                        name="cuentaFrom"
                                                        defaultValue=""
                                                        value={state.cuentaFrom}
                                                        onChange={onChange}>
                                                        <MenuItem disabled value="" ><em>Seleccione</em></MenuItem>
                                                        <MenuItem value={userContext.user.correo} ><em>{userContext.user.correo}</em></MenuItem>
                                                        <MenuItem value="seguimiento@tuspolizas.info" ><em>seguimiento@tuspolizas.info</em></MenuItem>
                                                    </Select>
                                                </FormControl>



                                                {
                                                    /*
                                                    <FormControl variant="outlined" required>
                                                    <InputLabel id="de">De</InputLabel>
                                                    <Select
                                                        required
                                                        labelId="De"
                                                        label="De"
                                                        size="medium"
                                                        name="cuentaFrom"
                                                        defaultValue=""
                                                        value={state.cuentaFrom}
                                                        onChange={onChange}>
                                                        <MenuItem disabled value="" ><em>Seleccione</em></MenuItem>
                                                        <MenuItem value={state.cuentaFrom}>{state.cuentaFrom}</MenuItem>
                                                    </Select>
                                                </FormControl>
                                                    */
                                                }


                                                <TextField
                                                    label="Para"
                                                    type="email"
                                                    variant="outlined"
                                                    name="cuentaTo"
                                                    value={state.cuentaTo}
                                                    onChange={onChange}
                                                    required
                                                    helperText="Usa comas para envíar a varias direcciones de correo"
                                                    onInput={(e) => maxFieldLength(e, 50)}
                                                />

                                                <TextField
                                                    label="Con Copia"
                                                    type="text"
                                                    variant="outlined"
                                                    name="cuentaCC"
                                                    value={state.cuentaCC}
                                                    onChange={onChange}
                                                    helperText="Usa comas para envíar a varias direcciones de correo"
                                                    onInput={(e) => maxFieldLength(e, 50)}
                                                />
                                                <TextField
                                                    label="Con Copia Oculta"
                                                    type="text"
                                                    variant="outlined"
                                                    name="cuentaCCB"
                                                    value={state.cuentaCCB}
                                                    onChange={onChange}
                                                    helperText="Usa comas para envíar a varias direcciones de correo"
                                                    onInput={(e) => maxFieldLength(e, 50)}
                                                />

                                                <TextField
                                                    required
                                                    label="Asunto"
                                                    type="text"
                                                    variant="outlined"
                                                    name="asunto"
                                                    value={state.asunto}
                                                    onChange={onChange}
                                                    onInput={(e) => maxFieldLength(e, 50)}
                                                />

                                                <Editor
                                                    wrapperClassName="demo-wrapper bg-dark-300 "
                                                    editorClassName="text-sm demo-editor px-3"
                                                    toolbarHidden={true}
                                                    toolbarClassName="bg-white"
                                                    editorState={state.cuerpo}
                                                    onEditorStateChange={(editorState) => { setState({ ...state, cuerpo: editorState }) }}
                                                />
                                            </>
                                        )}
                                    </div>
                                </div>
                                <Button type="submit" disabled={(!state.nombre) || (!state.cuentaFrom) || (state.cuentaTo === '') || (state.cuerpo === '')} className="btn btn-yellow font-bold ">ENVIAR CORREO</Button>
                                {message && <Alert {...message} />}
                            </div>
                        </div>
                    </div>
                </form>
            </AdminTemplate >
        )
    }

    return render()

}
export default Correo
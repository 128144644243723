import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'


const TerminosCondiciones = (props) => {

    const render = () => {
        return (
            <div>
                <Navbar showBackButton={false} />
                <div className="mt-8 container m-auto grid grid-cols-1 lg:grid-cols-3 lg:mt-32">
                    <div className="lg:col-start-2 px-8 lg:px-0">
                        <p className='text-yellow font-bold pb-5'>Términos y condiciones</p>
                        <p className='text-gray-100 pb-5 text-justify'>Proveedor de Servicio</p>
                        <p className='text-gray-100 pb-5 text-justify'>Nuestra empresa se llama Dalala somos una sociedad anónima de capital variable lo que sea que eso signifique pero si puedes estar seguro de que respaldamos legalmente nuestro trabajo,</p>
                        <p className='text-gray-100 pb-5 text-justify'>Nuestra dirección es Belisario Domínguez 2020, Piso 3, Col. Obispado en Monterrey, N.L.</p>
                        <p className='text-gray-100 pb-5 text-justify'>Puedes comunicarte con nosotros por correo electrónico al correo hola@dalala.mx</p>
                        <p className='text-gray-100 pb-5 text-justify'>Tu relación con Dalala</p>
                        <p className='text-gray-100 pb-5 text-justify'>Básicamente te damos permiso para que utilices nuestros servicios si aceptas los términos de los mismos que definen cómo trabajamos y cómo obtenemos el dinero para mantener los servidores y las luces encendidas.</p>
                        <p className='text-gray-100 pb-5 text-justify'>Qué hacemos</p>
                        <p className='text-gray-100 pb-5 text-justify'>Tecnología para llevar al medio de seguros a la inevitable transformación digital que tanto necesita el país para competir en una clara globalización de mercados.</p>
                        <p className='text-gray-100 pb-5 text-justify'>Cómo lo hacemos</p>
                        <p className='text-gray-100 pb-5 text-justify'>Por medio del desarrollo de plataformas SaaS (Software as a Service o Software como Servicio) que permiten acceder a la más alta tecnología a una mínima fracción del costo de su desarrollo, mantenimiento y crecimiento.</p>
                        <p className='text-gray-100 pb-5 text-justify'>Siempre Mejoramos</p>
                        <p className='text-gray-100 pb-5 text-justify'>Aunque somos gente que dominamos los unos y los ceros, conocemos los procesos de seguros de la A a la Z, dalala.app nace esperando automatizar los procesos administrativos de las carteras individuales de seguros con el principal objetivo de que el agente de seguros sea asesor y no administrador.</p>
                        <p className='text-gray-100 pb-5 text-justify'>Automatización</p>
                        <p className='text-gray-100 pb-5 text-justify'>En dalala buscamos la manera de mantener la información de tu cartera actualizada por medio de la interacción con los componentes disponibles de parte de las aseguradoras, el compromiso de nuestra parte depende completamente de la estabilidad y disponibilidad de los servicios de las aseguradoras por esta razón no nos es posible ofrecer una garantía absoluta en este sentido.</p>
                        <p className='text-gray-100 pb-5 text-justify'>Garantía</p>
                        <p className='text-gray-100 pb-5 text-justify'>La disponibilidad de los servicios ofrecidos por dalala se fija en un porcentaje de 99.5 lo que puede darte la confianza de que cuando necesites tu información ahí estará para ti, en cuanto a respaldos de base de datos, en el caso de un desastre en la infraestructura de google tu información estará protegida y el periodo máximo de recuperación será de 24 horas si las condiciones que originaron el desastre lo permiten.</p>
                        <p className='text-gray-100 pb-5 text-justify'>Qué esperamos de Tí</p>
                        <p className='text-gray-100 pb-5 text-justify'>Como sabes todas las empresas funcionan a partir de ingresos regulares y crecen en la misma relación de los mismos, por esto el acceso a la plataforma dependerá de estar al día con la renta después del periodo de prueba.</p>
                        <p className='text-gray-100 pb-5 text-justify'>Formas de Pago</p>
                        <p className='text-gray-100 pb-5 text-justify'>La contratación de los servicios se basan en una renta mensual administrada por la plataforma © Stripe, Inc la cual cuenta con las más altas certificaciones de seguridad a nivel mundial, el único medio aceptado para la forma de pago mensual es la tarjeta de crédito con cargo automático al día del mes en el que hagas la contratación, en caso de buscar un pago anual existe la opción de transferencia bancaria la cual debe ser gestionada por medio del chat que se encuentra en la plataforma.</p>
                        <p className='text-gray-100 pb-5 text-justify'>Facturación</p>
                        <p className='text-gray-100 pb-5 text-justify'>En caso de requerir factura es necesario solicitarla al correo tools@dalala.app enviando los datos fiscales correspondientes, esta factura será emitida en base al periodo de la forma de pago que se haya contratado el servicio.</p>
                        <p className='text-gray-100 pb-5 text-justify'>Cancelación</p>
                        <p className='text-gray-100 pb-5 text-justify'>En dalala estamos conscientes de la importancia de la confianza que debe existir entre las partes para una relación cliente-proveedor sana y duradera, por esta razón, la cancelación se puede hacer de manera inmediata por medio de un correo electrónico enviado por el dueño de la cuenta de correo principal registrada por el agente de seguros a la cuenta tools@dalala.app con la solicitud de cancelación, una vez recibido el correo tu información será eliminada por completo de nuestras bases de datos en los próximos 3 días hábiles.</p>
                        <p className='text-gray-100 pb-5 text-justify'>En caso de que decidas dejarnos :( nos encantaría conocer la razón del porqué y así aprovechar la valiosa oportunidad de haberte tenido como cliente, por esto agradecemos mucho nos des tu punto de vista del servicio y los beneficios que intentamos entregar.</p>
                        <p className='text-gray-100 pb-5 text-justify'>Confidencialidad</p>
                        <p className='text-gray-100 pb-5 text-justify'>Este punto es muy simple, la información es tuya al 100%, nuestra plataforma te ayuda a organizarla así como a buscar automatizarla fuera de eso no tenemos más asuntos que ver con ella, excepto uno muy importante, mantenerla segura e inaccesible a personas no autorizadas, para lograr esto trabajamos arduamente implementando metodologías así como herramientas de última generación respaldadas por empresas de reputación internacional.</p>
                        <p className='text-gray-100 pb-5 text-justify'>Integraciones</p>
                        <p className='text-gray-100 pb-5 text-justify'>Como parte de una plataforma “abierta” la conexión con otras herramientas es indispensable, por esta razón la estructura de desarrollo de dalala permite la comunicación con aplicaciones de terceros, en la primer etapa de este tipo de servicios hemos integrado Todoist de © Doist Inc. que genera listas de pendientes a partir de tu cartera y MiPoliza de © Copsis que busca entregar las pólizas a través de una aplicación móvil segura y amigable para el usuario.</p>
                        <p className='text-gray-100 pb-5 text-justify'>En este sentido es importante señalar que se cumplen los  de dichas plataformas así como las garantías las cuales tienen que ser aceptadas ante sus propietarios.</p>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }

    return render()

}

export default TerminosCondiciones
// Utils to onInput event


function onlyNumbers(event, maxLength) {
    if (maxLength) {
        event.target.value = event.target.value.replace(/[^0-9-]*/g, '').slice(0, maxLength)
    } else {
        event.target.value = event.target.value.replace(/[^0-9-]*/g, '')
    }
}

function onlyLetter(event, maxLength) {
    if (maxLength) {
        event.target.value = event.target.value.replace(/[^a-zA-Z\u00f1\u00d1[\u0300-\u036f] ]/g, '').slice(0, maxLength)
    } else {
        event.target.value = event.target.value.replace(/[^a-zA-Z\u00f1\u00d1[\u0300-\u036f] ]/g, '')
    }
}

function onlySimpleCharacters(event, maxLength) {
    if (maxLength) {
        event.target.value = event.target.value.replace(/[^a-zA-Z^0-9\u00f1\u00d1[\u0300-\u036f] ]/g, '').slice(0, maxLength)
    } else {
        event.target.value = event.target.value.replace(/[^a-zA-Z^0-9\u00f1\u00d1[\u0300-\u036f] ]/g, '')
    }
}

function maxFieldLength(event, maxLength) {
    event.target.value = event.target.value.slice(0, maxLength)
}


function dueDate(event) {
    event.target.value = event.target.value.replace(/[^/0-9]/g,'').slice(0, 5)
}



module.exports = {
    onlyNumbers,
    onlyLetter,
    maxFieldLength,
    dueDate,
    onlySimpleCharacters
}
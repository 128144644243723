import React from 'react'

const Footer = (props) => {

    return (
        <footer className="p-1 bg-dark-300">
            <p className="text-sm text-center">© Derechos Reservados <span className="text-yellow">dalala</span> 2022</p>
        </footer>
    )    

}
export default Footer
import React, { useState, useContext } from 'react'
import Navbar from '../components/Navbar'
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import { useForm } from 'react-hook-form';
import APIInvoke from '../utils/APIInvoke';
import Button from '@material-ui/core/Button';
import UserContext from '../state/UserContext'
import { Link, useHistory, useLocation } from "react-router-dom";
import Loading from '../components/Loading';

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const Login = (props) => {

    const query = useQuery();
    const context = useContext(UserContext)
    const history = useHistory()
    const [state, setState] = useState({
        loading: false,
        usuario: "",
        password: ""
    })
    const [errorMessage, setErrorMessage] = useState('');


    const onChange = (e) => {
        const name = e.target.name
        const value = e.target.value
        setState({
            ...state,
            [name]: value
        })
    }

    const onSubmit = async (e) => {
        e.preventDefault()

        try {
            setState({
                ...state,
                loading: true
            })

            const request = {
                usuario: state.usuario,
                password: state.password
            }

            const response = await (await APIInvoke.invokePOST(`/usuarios/login`, request)).json();
            if (response.ok === true) {
                window.localStorage.setItem('token', response.body.token);
                context.setUser({
                    ...response.body.usuario,
                    suscripcion: response.body.suscripcion,
                    rol: response.body.role
                });
                goLogin()
            } else {
                setErrorMessage(response.message);
            }
        } catch (e) {
            console.log(e)
        } finally {
            setState({
                ...state,
                loading: false
            })
        }
    }

    const goLogin = () => {
        const pushUrl = query.get("url") ? decodeURIComponent(query.get("url")) : "/resumen"
        console.log("pushUrl", pushUrl)
        history.push(pushUrl);
        window.location = pushUrl
    }

    const render = () => {

        if (context.user) {
            goLogin()
        }

        return (
            <div>
                <Loading show={state.loading} />
                <Navbar title="Iniciar Sesión" showBackButton={false} />

                <div className="mt-8 container m-auto grid grid-cols-1 lg:grid-cols-3 lg:mt-32">
                    <div className="lg:col-start-2 px-8 lg:px-0">
                        <p className="text-gray-400 mb-8">Para iniciar tu registro recibirás un sms con tu código de acceso</p>
                        <form className="grid gap-4" onSubmit={onSubmit}>
                            <TextField
                                label="Correo"
                                type="email"
                                variant="outlined"
                                name="usuario"
                                required
                                onChange={onChange}
                            />
                            <TextField
                                label="Contraseña"
                                type="password"
                                variant="outlined"
                                name="password"
                                required
                                onChange={onChange}
                            />
                            <p className="text-center text-red">{errorMessage}</p>
                            <Button type="submit" className="btn btn-yellow" color="primary">Iniciar Sesión</Button>
                        </form>

                        {/* 
                        <div className="grid grid-cols-6 mt-8 items-center">
                            <Divider />
                            <p className="col-span-4 text-center text-gray-400">O también puedes registrarte con...</p>
                            <Divider />
                        </div>
                        <div className="grid justify-items-center grid-cols-5 lg:grid-cols-7 mt-4">
                            <div style={border} className="col-start-2 lg:col-start-3 border">
                                <img src="/companies/facebook-logo.svg" alt="facebook" />
                            </div>
                            <div style={border}>
                                <img src="/companies/google-logo.svg" alt="google" />
                            </div>
                            <div style={border}>
                                <img src="/companies/apple-logo.svg" alt="apple" />
                            </div>
                        </div>
                        */}
                        <div className="text-center mt-8">
                            <p className="text-gray-400">¿No tienes cuenta? <Link to={"/registro"} className="no-underline ml-4">Regístrate</Link></p>
                        </div>
                        <div className="text-center mt-8">
                            <span className="text-gray-400 mr-4"><Link to={{ pathname: `/planes`, state: { backUrl: `/login` } }} className="no-underline ml-4 text-sm">Ver planes</Link></span>
                            <span className="text-gray-400"><Link to={{ pathname: `/restaurar-pass`, state: { backUrl: `/login` } }} className="no-underline ml-4 text-sm">Recuperar contraseña</Link></span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const border = {
        borderStyle: 'solid',
        borderWidth: '2px',
        padding: '10px',
        borderRadius: '5px'
    };

    return render()
}

export default Login